import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import Constants from '@constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  loginForm: FormGroup;
  constructor(
    public authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private toasterService: ToastrService
  ) {
    this.checkIfLoggedIn();
  }

  ngOnInit(): void {
    this.createForm();
  }

  checkIfLoggedIn(): void {
    if (this.authService.loggedIn) {
      this.router.navigate(['/authors']);
    }
  }

  createForm(): void {
    this.loginForm = this.formBuilder.group({
      email: [''],
      password: ['']
    });
  }

  login(): void {
    if (this.loginForm.valid) {
      const email = this.loginForm.value.email.toString().toLowerCase().replace(/ /g, '');
      this.authService.signIn(
        email,
        this.loginForm.value.password
      )
        .then(() =>
          this.router.navigate(['/authors']))
        .catch(error =>
          this.toastr.error(error.message || 'Error in login. Please check and try again.', 'Error : '));
    } else {
      this.toasterService.error(Constants.error.INVALID_FORM);
    }
  }

}
