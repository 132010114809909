import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IAuthor } from '@author/authors.interface';
import { AuthorService } from '@author/authors.service';
import Constants from '@constants';
import { IDialogResult } from '@sharedInterfaces';
import { UtilService } from '@util/util.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-edit-author',
  templateUrl: './add-edit-author.component.html',
  styleUrls: ['./add-edit-author.component.scss']
})
export class AddEditAuthorComponent implements OnInit {

  authorForm: FormGroup;
  submitButtonText = 'Add Author';
  isCreation = true;
  authorName = '';
  imgUrl: any = '';
  file: any;
  showOnQuiz = false;
  quizIndex: number;
  description = '';
  authorId = '';
  showDeleteIcon = false;
  fileFormats: string[];

  constructor(
    private formBuilder: FormBuilder,
    public addEditAuthorDialog: MatDialogRef<any>,
    private authorService: AuthorService,
    private utilService: UtilService,
    private toasterService: ToastrService,
    @Inject(MAT_DIALOG_DATA) data: { author: IAuthor }
  ) {
    if (data && data.author && Object.keys(data.author).length) {
      this.authorName = data.author.name;
      this.imgUrl = this.utilService.getLatestImage(data.author.img_url);
      this.showOnQuiz = data.author.show_on_quiz;
      this.quizIndex = data.author.quiz_index;
      this.description = data.author.info;
      this.authorId = data.author.id;
      this.submitButtonText = 'Update Author';
      this.isCreation = false;
    }
  }

  ngOnInit(): void {
    this.fileFormats = this.utilService.universalImageFormats();
    const actualImageURL = this.utilService.getBaseURL(this.imgUrl);
    if (actualImageURL && actualImageURL !== Constants.S3.default.author()) {
      this.showDeleteIcon = true;
    }
    this.createForm();
    this.setQuizIndexValidator();
  }

  createForm(): void {
    this.authorForm = this.formBuilder.group({
      name: [this.authorName],
      info: [this.description],
      show_on_quiz: [this.showOnQuiz],
      quiz_index: [this.quizIndex, Validators.min(1)],
    });
  }

  setQuizIndexValidator(): void {
    this.authorForm.get('show_on_quiz').valueChanges.subscribe(showOnQuiz => {
      const quizIndexInput = this.authorForm.get('quiz_index');
      if (showOnQuiz) {
        quizIndexInput.setValidators([Validators.required, Validators.min(1)]);
      } else {
        quizIndexInput.clearValidators();
      }
      quizIndexInput.updateValueAndValidity();
    });
  }

  createUpdateAuthor(): void {
    if (this.authorForm.valid) {
      this.utilService.cleanForm(this.authorForm);
      Object.entries(this.authorForm.value).forEach(([component, value]) => {
        if ([null, '', undefined].indexOf(this.authorForm.value[component]) >= 0) {
          if (this.isCreation) {
            delete this.authorForm.value[component];
          } else {
            this.authorForm.controls[component].setValue('');
          }
        }
        if (!this.authorForm.value.show_on_quiz && this.authorForm.value.hasOwnProperty('quiz_index')) {
          delete this.authorForm.value.quiz_index;
        }
        if (this.isCreation) {
          if (this.imgUrl) {
            this.authorForm.value.img_url = this.imgUrl.split('?')[0];
          }
        } else {
          this.authorForm.value.img_url = this.imgUrl.split('?')[0];
        }
      });
      if (this.file) {
        this.authorForm.value.file = this.file;
        delete this.authorForm.value.img_url;
      }
      const authorFormData: FormData = this.utilService.objectToFormData(this.authorForm.value);

      const authorObserver = this.isCreation
        ? this.authorService.postAuthor(authorFormData)
        : this.authorService.updateAuthor(authorFormData, this.authorId);

      authorObserver.subscribe(
        () => {
          // refresh page if edit, if create then redirect to first page
          const result: IDialogResult = {isCreation: this.isCreation};
          this.addEditAuthorDialog.close(result);
        },
        apiError => {
          console.error('author Add / Edit API error => ', JSON.stringify(apiError));
        });
    } else {
      this.toasterService.error(Constants.error.INVALID_FORM);
    }
  }

  onFileChange(uploadedFile: File): void {
    if (uploadedFile) {
      const file = uploadedFile;
      const fileSize = file.size;
      if (fileSize > Constants.maxImageSize) {
        this.toasterService.error(`Image size should not be more than ${Constants.maxImageSize / 1000} KB.`);
      } else {
        const reader = new FileReader();
        reader.onload = e => this.imgUrl = reader.result;
        reader.readAsDataURL(file);
        this.file = file;
        this.showDeleteIcon = true;
      }
    }
  }

  clearImage(): void {
    this.imgUrl = '';
    this.file = '';
    this.showDeleteIcon = false;
  }

}
