import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IDialogData } from '@sharedInterfaces';

@Component({
  selector: 'app-delete',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})

export class ConfirmationComponent implements OnInit {

  confirmationForm: FormGroup;
  attributes: IDialogData = {
    approvalButtonText: 'Yes',
    disApprovalButtonText: 'No',
    titleText: 'Are you sure?'
  };

  constructor(
    private formBuilder: FormBuilder,
    public confirmationDialog: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) data: IDialogData
  ) {
    if (data && Object.keys(data).length) {
      this.attributes = Object.assign(this.attributes, data);
    }
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.confirmationForm = this.formBuilder.group({
      no_button: [''],
      yes_button: ['']
    });
  }

}
