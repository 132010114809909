import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { IAuthor } from '@author/authors.interface';
import { AuthorService } from '@author/authors.service';
import { ICategory } from '@category/categories.interface';
import { CategoryService } from '@category/categories.service';
import Constants from '@constants';
import { AddEditChapterComponent } from '@content/add-edit-chapter/add-edit-chapter.component';
import { ContentType } from '@content/content.enum';
import { IChapter, IContent } from '@content/content.interface';
import { ContentService } from '@content/content.service';
import { KlevuService } from '@klevu/klevu.service';
import { IDialogData, IDialogResult, IRunTime, ITableDisplayedColumns } from '@sharedInterfaces';
import { TEntityType } from '@sharedTypes';
import { ITopic } from '@topic/topics.interface';
import { TopicService } from '@topic/topics.service';
import { ConfirmationComponent } from '@util/confirmation/confirmation.component';
import { UtilService } from '@util/util.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-add-edit-content',
  templateUrl: './add-edit-content.component.html',
  styleUrls: ['./add-edit-content.component.scss']
})
export class AddEditContentComponent implements OnInit, AfterViewInit {

  authorsList = [];
  cachedAuthorList;
  contentForm: FormGroup;
  isCreation = true;
  displayedColumns: ITableDisplayedColumns[] = [
    {key: 'chapter_title', field: 'Title'},
    {key: 'chapter_url', field: 'URL'},
    {key: 'chapter_index', field: 'Index'},
    {key: 'chapter_run_time_string', field: 'Runtime'}];
  approvalButtonText;
  selectedAuthorsArray = [];
  selectedCategoryArray = [];
  selectedTopicArray = [];
  contentId = '';
  existingContent: IContent;
  validChapterKeys = ['chapter_title', 'chapter_url', 'chapter_index', 'chapter_run_time', 'listen_free_start', 'listen_free_end'];
  searchApplied = 0;
  defaultDateFormat = Constants.dateDefaultFormat;
  runTimeMinutesMandatory = true;
  fileFormats: string[];
  imgUrl: any = '';
  file: any;
  showDeleteIcon = false;
  isPodcast: boolean;
  isCuratedCollection: boolean;
  entity = 'Audio Book';
  categories: ICategory[];
  mandatoryPodcastCategoryName = Constants.mandatoryPodcastCategoryName;
  mandatoryCuratedCollectionCategoryName = Constants.mandatoryCuratedCollectionCategoryName;
  topics: ITopic[] = [];
  publishedOn = '';
  validFrom = '';
  validTill = '';
  listenFreeStart = '';
  listenFreeEnd = '';
  price: any;
  magentoProductId: any;
  inAppPurchaseId: number;
  discountPrice: any;
  discountStart = '';
  discountEnd = '';
  inAppPurchaseIsEnabled: boolean;

  constructor(
    private authorService: AuthorService,
    public topicService: TopicService,
    public categoryService: CategoryService,
    private formBuilder: FormBuilder,
    public addEditContentDialog: MatDialogRef<any>,
    private dialog: MatDialog,
    private contentService: ContentService,
    private utilService: UtilService,
    private klevuService: KlevuService,
    public toasterService: ToastrService,
    @Inject(MAT_DIALOG_DATA) data: {content: IContent, contentType: ContentType}
  ) {
    this.approvalButtonText = `Add ${this.entity}`;
    this.isPodcast = data.contentType === ContentType.Podcast;
    if (this.isPodcast) {
      this.approvalButtonText = `Save and Add Episodes`;
      this.entity = 'Podcast';
    }
    this.isCuratedCollection = data.contentType === ContentType['Curated Collection'];
    if (this.isCuratedCollection) {
      this.approvalButtonText = `Save and Add Tracks`;
      this.entity = 'Curated Collection';
    }
    if (data && data.content) {
      this.selectedTopicArray = this.selectedTopicArray.concat(data.content.topic_info || []);
      this.selectedCategoryArray = this.selectedCategoryArray.concat(data.content.category_info || []);
      this.selectedAuthorsArray = this.selectedAuthorsArray.concat(data.content.author_info || []);
      this.imgUrl = this.utilService.getLatestImage(data.content.img_url);
      const runTime: IRunTime = this.utilService.milliSecondsToMinutesSeconds(data.content.run_time);
      data.content.run_time_minutes = runTime.minutes;
      data.content.run_time_seconds = runTime.seconds;
      const contentSeconds = parseInt(String(data.content.run_time_seconds || 0), 10);
      if (contentSeconds) {
        this.runTimeMinutesMandatory = false;
      }
      this.existingContent = data.content;
      if (this.existingContent.chapter_info && this.existingContent.chapter_info.length) {
        this.existingContent.chapter_info.forEach((chapter: IChapter) => {
          chapter.chapter_run_time_string = this.utilService.getRunTimeString(chapter.chapter_run_time);
          const chapterRunTime: IRunTime = this.utilService.milliSecondsToMinutesSeconds(chapter.chapter_run_time);
          chapter.chapter_run_time_minutes = chapterRunTime.minutes;
          chapter.chapter_run_time_seconds = chapterRunTime.seconds;
        });
        this.existingContent.chapter_info = this.utilService.sortArrayOfObjects(this.existingContent.chapter_info, 'chapter_index');
      }
      this.validFrom = this.utilService.formatDateRequest(this.existingContent.valid_from);
      this.validTill = this.utilService.formatDateRequest(this.existingContent.valid_till);
      this.listenFreeStart = this.utilService.formatDatetimeRequest(this.existingContent.listen_free_start);
      this.listenFreeEnd = this.utilService.formatDatetimeRequest(this.existingContent.listen_free_end);
      this.discountStart = this.utilService.formatDatetimeRequest(this.existingContent.discount_start);
      this.discountEnd = this.utilService.formatDatetimeRequest(this.existingContent.discount_end);
      if (this.existingContent.published_on) {
        this.publishedOn = this.utilService.formatDateRequest(this.existingContent.published_on);
      }
      this.contentId = data.content.id;
      this.magentoProductId = data.content.magento_product_id;
      this.approvalButtonText = `Update ${this.entity}`;
      this.price = this.utilService.formatPrice(this.existingContent.price, true);
      this.discountPrice = this.utilService.formatPrice(this.existingContent.discount_price, true);
      this.inAppPurchaseId = data.content.in_app_purchase_id;
      this.inAppPurchaseIsEnabled =
        (typeof data.content.in_app_purchase_is_enabled === 'undefined')
          ? false
          : data.content.in_app_purchase_is_enabled;
      this.isCreation = false;
    }
    this.createForm();
    this.contentForm
      .controls
      .searchAuthor
      .valueChanges
      .pipe(debounceTime(Constants.klevuDeBounceTime))
      .subscribe(term => {
        if (term) {
          this.klevuService
            .searchKlevu(term, 'author')
            .subscribe(klevuResult => {
              this.searchApplied = 1;
              this.authorsList = klevuResult.result;
            });
        } else if (this.searchApplied) {
          this.authorsList = this.cachedAuthorList;
          this.searchApplied = 0;
        }
      });
  }

  ngOnInit(): void {
    this.fileFormats = this.utilService.universalImageFormats();
    const actualURL = this.utilService.getBaseURL(this.imgUrl);
    if (actualURL && actualURL !== Constants.S3.default.content()) {
      this.showDeleteIcon = true;
    }
    this.getAuthors();
    this.categoryService.getCategories().subscribe((categoryList: ICategory[]) => {
      if (this.isPodcast) {
        this.categories = categoryList.filter(category => Constants.validPodcastCategoryNames
          .indexOf(category.name.toString().toLowerCase().trim()) >= 0);
        const podcastCategory = this.categories
          .find(category => category.name.toString().toLowerCase().trim() === Constants.mandatoryPodcastCategoryName);
        const alreadyAddedCategory = this.selectedCategoryArray
          .some(category => category.name.toString().toLowerCase().trim() === Constants.mandatoryPodcastCategoryName);
        if (podcastCategory && Object.keys(podcastCategory) && !alreadyAddedCategory) {
          this.selectedCategoryArray.push(podcastCategory);
        }
      } else if (this.isCuratedCollection) {
        this.categories = categoryList.filter(category => Constants.validCuratedCollectionCategoryNames
          .indexOf(category.name.toString().toLowerCase().trim()) >= 0);
        const curatedCollectionCategory = this.categories
          .find(category => category.name.toString().toLowerCase().trim() === Constants.mandatoryCuratedCollectionCategoryName);
        const alreadyAddedCategory = this.selectedCategoryArray
          .some(category => category.name.toString().toLowerCase().trim() === Constants.mandatoryCuratedCollectionCategoryName);
        if (curatedCollectionCategory && Object.keys(curatedCollectionCategory) && !alreadyAddedCategory) {
          this.selectedCategoryArray.push(curatedCollectionCategory);
        }
      } else {
        this.categories = categoryList;
      }
      this.getTopicsFromCategoryObjectArray().subscribe();
    });
    this.setValidTill();
  }

  ngAfterViewInit(): void {
  }

  getAuthors(): void {
    this.authorService.getAuthors(null, true).subscribe((authorsList: {
      results: IAuthor[], next_page_key: string
    }) => {
      this.authorsList = JSON.parse(JSON.stringify(authorsList.results));
      this.cachedAuthorList = JSON.parse(JSON.stringify(authorsList.results));
    });
  }

  createForm(): void {
    this.contentForm = this.formBuilder.group({
      authorNameList: [''],
      searchAuthor: [''],
      url: [{
        value: this.existingContent && this.existingContent.url || '',
        disabled: this.existingContent && this.existingContent.chapter_info && this.existingContent.chapter_info.length
      }],
      preview_url: [this.existingContent && this.existingContent.preview_url || ''],
      pdf_url: [this.existingContent && this.existingContent.pdf_url || ''],
      product_sku: [this.existingContent && this.existingContent.product_sku || ''],
      price: [this.existingContent && this.existingContent.price ? this.utilService.formatPrice(this.existingContent.price, true) : null],
      // tslint:disable-next-line:max-line-length
      discount_price: [this.existingContent && this.existingContent.discount_price ? this.utilService.formatPrice(this.existingContent.discount_price, true) : null],
      title: [this.existingContent && this.existingContent.title || ''],
      sub_title: [this.existingContent && this.existingContent.sub_title || ''],
      narrated_by: [this.existingContent && this.existingContent.narrated_by || ''],
      categoryNameList: [''],
      topicNameList: [''],
      run_time_minutes: [
        {
          value: this.existingContent && this.existingContent.run_time_minutes || 0,
          disabled: (this.existingContent && this.existingContent.chapter_info && this.existingContent.chapter_info.length)
            || this.isPodcast
            || this.isCuratedCollection
        },
        Validators.min(this.runTimeMinutesMandatory ? 1 : 0)
      ],
      run_time_seconds: [
        {
          value: this.existingContent && this.existingContent.run_time_seconds || 0,
          disabled: (this.existingContent && this.existingContent.chapter_info && this.existingContent.chapter_info.length)
            || this.isPodcast
            || this.isCuratedCollection
        },
        [Validators.min(0), Validators.max(59)]
      ],
      // tslint:disable-next-line:max-line-length
      published_on: [this.existingContent && this.existingContent.published_on ? this.utilService.formatDateRequest(this.existingContent.published_on) : ''],
      // tslint:disable-next-line:max-line-length
      valid_from: [this.existingContent && this.existingContent.valid_from ? this.utilService.formatDateRequest(this.existingContent.valid_from) : ''],
      // tslint:disable-next-line:max-line-length
      valid_till: [this.existingContent && this.existingContent.valid_till ? this.utilService.formatDateRequest(this.existingContent.valid_till) : ''],
      // tslint:disable-next-line:max-line-length
      listen_free_start: [this.existingContent && this.existingContent.listen_free_start ? this.utilService.formatDatetimeRequest(this.existingContent.listen_free_start) : ''],
      // tslint:disable-next-line:max-line-length
      listen_free_end: [this.existingContent && this.existingContent.listen_free_end ? this.utilService.formatDatetimeRequest(this.existingContent.listen_free_end) : ''],
      // tslint:disable-next-line:max-line-length
      discount_end: [this.existingContent && this.existingContent.discount_end ? this.utilService.formatDatetimeRequest(this.existingContent.discount_end) : ''],
      // tslint:disable-next-line:max-line-length
      discount_start: [this.existingContent && this.existingContent.discount_start ? this.utilService.formatDatetimeRequest(this.existingContent.discount_start) : ''],
      description: [this.existingContent && this.existingContent.description || ''],
      quiz: [this.existingContent && this.existingContent.quiz || false],
      exclusive: [this.existingContent && this.existingContent.exclusive || false],
      trending: [this.existingContent && this.existingContent.trending || false],
      trending_index: [this.existingContent && this.existingContent.trending_index || '', Validators.min(1)],
      top_podcast: [this.existingContent && this.existingContent.top_podcast || false],
      top_podcast_index: [this.existingContent && this.existingContent.top_podcast_index || '', Validators.min(1)],
      featured_curated_collection: [this.existingContent && this.existingContent.featured_curated_collection || false],
      featured_curated_collection_index: [
        this.existingContent && this.existingContent.featured_curated_collection_index || '',
        Validators.min(1)
      ],
      new_release: [this.existingContent && this.existingContent.new_release || false],
      new_release_index: [this.existingContent && this.existingContent.new_release_index || '', Validators.min(1)],
      chapter_info: [this.existingContent && this.existingContent.chapter_info || ''],
      magento_product_id: [this.existingContent && this.existingContent.magento_product_id || ''],
      in_app_purchase_id: [this.existingContent && this.existingContent.in_app_purchase_id || ''],
      in_app_purchase_is_enabled: [this.existingContent && this.existingContent.in_app_purchase_is_enabled || false],
    });
  }

  setValidTill(event?: any): void {
    const validTillDatePicker = this.contentForm.get('valid_till');
    validTillDatePicker.enable();
    if (event && new Date(this.validFrom) > new Date(this.validTill)) {
      this.validTill = '';
    }
    if (!event && !this.existingContent) {
      validTillDatePicker.reset();
      validTillDatePicker.disable();
    }
  }

  selectEntity(selectedValue: {id: string}, type: TEntityType): void {
    switch (type) {
      case 'author':
        if (this.selectedAuthorsArray.findIndex(author => author.id === selectedValue.id) < 0) {
          this.selectedAuthorsArray.push(selectedValue);
          this.contentForm.controls.searchAuthor.reset();
          this.contentForm.controls.authorNameList.reset();
          this.authorsList = this.cachedAuthorList;
        }
        break;
      case 'category':
        if (this.selectedCategoryArray.findIndex(category => category.id === selectedValue.id) < 0) {
          this.selectedCategoryArray.push(selectedValue);
          this.contentForm.controls.categoryNameList.reset();
          this.getTopicsFromCategoryObjectArray().subscribe();
        }
        break;
      case 'topic':
        if (this.selectedTopicArray.findIndex(topic => topic.id === selectedValue.id) < 0) {
          this.selectedTopicArray.push(selectedValue);
          this.contentForm.controls.topicNameList.reset();
        }
        break;
    }
  }

  isSelectedValue(id: string, type: TEntityType): boolean {
    switch (type) {
      case 'author':
        if (this.selectedAuthorsArray.findIndex(entity => entity.id === id) >= 0) {
          return true;
        }
        break;
      case 'category':
        if (this.selectedCategoryArray.findIndex(entity => entity.id === id) >= 0) {
          return true;
        }
        break;
      case 'topic':
        if (this.selectedTopicArray.findIndex(entity => entity.id === id) >= 0) {
          return true;
        }
        break;
    }
  }

  removeSelectedEntity(id: string, type: TEntityType): void {
    switch (type) {
      case 'author':
        this.selectedAuthorsArray = this.selectedAuthorsArray.filter(author => author.id !== id);
        break;
      case 'category':
        this.selectedCategoryArray = this.selectedCategoryArray.filter(category => category.id !== id);
        this.getTopicsFromCategoryObjectArray().subscribe();
        break;
      case 'topic':
        this.selectedTopicArray = this.selectedTopicArray.filter(topic => topic.id !== id);
        break;
    }
  }

  openChapterDialog(existingChapterInfo?: IChapter): void {
    let chapterIndexes = (this.contentForm.value.chapter_info || []).map(chapter => chapter.chapter_index).filter(el => el);
    chapterIndexes = this.utilService.sortIntegerArray(chapterIndexes);
    const chapterDialog = this.dialog.open(AddEditChapterComponent, {
      width: '440px',
      height: 'auto',
      data: {
        existingChapterInfo,
        chapter_info : this.contentForm.value.chapter_info || [],
        chapter_index: (chapterIndexes && chapterIndexes.length)
          ? (chapterIndexes[chapterIndexes.length - 1] + 1)
          : 1
      }
    });
    chapterDialog.afterClosed().subscribe((chapterInfo: any[]) => {
      if (chapterInfo) {
        chapterInfo = this.utilService.sortArrayOfObjects(chapterInfo, 'chapter_index');
        chapterInfo.forEach(chapter => {
          chapter.chapter_run_time_string = this.utilService.getRunTimeString(chapter.chapter_run_time);
        });
        this.updateContentRunTimeFromChapters(chapterInfo);
        this.contentForm.get('chapter_info').setValue([...chapterInfo]);
        if (
          this.contentForm.value.chapter_info
          && this.contentForm.value.chapter_info.length
        ) {
          if (!this.contentForm.controls.url.disabled) {
            this.contentForm.controls.url.disable();
            this.contentForm.controls.url.clearValidators();
          }
          if (!this.contentForm.controls.run_time_minutes.disabled) {
            this.contentForm.controls.run_time_minutes.disable();
            this.contentForm.controls.run_time_minutes.clearValidators();
          }
          if (!this.contentForm.controls.run_time_seconds.disabled) {
            this.contentForm.controls.run_time_seconds.disable();
            this.contentForm.controls.run_time_seconds.clearValidators();
          }
        }
      }
    });
  }

  openDeleteDialog(chapterInfo: IChapter): void {
    const data: IDialogData = {
      titleText: 'This will rearrange the chapter indexes. Are you sure?',
      approvalButtonText: 'Delete',
      disApprovalButtonText: 'Cancel'
    };
    const deleteDialog = this.dialog.open(ConfirmationComponent, {
      ...Constants.confirmationDialogConfig,
      data
    });
    deleteDialog.afterClosed().subscribe(approval => {
      if (approval) {
        this.contentForm.value.chapter_info = this.contentForm.value.chapter_info
          .filter(chapter => chapter.chapter_index !== chapterInfo.chapter_index);
        this.contentForm.get('chapter_info').setValue([...this.updateChapterIndexes(this.contentForm.value.chapter_info)]);
        this.updateContentRunTimeFromChapters(this.contentForm.value.chapter_info);
        if (
          (!this.contentForm.value.chapter_info || !this.contentForm.value.chapter_info.length)
          && !this.isPodcast
          && !this.isCuratedCollection
        ) {
          if (this.contentForm.controls.url.disabled) {
            this.contentForm.controls.url.enable();
            this.contentForm.controls.url.setValidators(Validators.required);
          }
          if (this.contentForm.controls.run_time_minutes.disabled) {
            this.contentForm.controls.run_time_minutes.enable();
            this.contentForm.controls.run_time_minutes.setValidators(
              [Validators.required, Validators.min(this.runTimeMinutesMandatory ? 1 : 0)]
            );
          }
          if (this.contentForm.controls.run_time_seconds.disabled) {
            this.contentForm.controls.run_time_seconds.enable();
            this.contentForm.controls.run_time_seconds.setValidators([Validators.min(0), Validators.max(59)]);
          }
        }
      }
    });
  }

  createUpdateContent(): void {
    if (this.contentForm.valid) {
      const audioRequestBody = {
        authors: [],
        narrated_by: '',
        title: '',
        sub_title: '',
        url: '',
        run_time: '',
        chapter_info: '',
        description: '',
        categories: [],
        topics: [],
        new_release: '',
        new_release_index: '',
        trending: '',
        trending_index: '',
        exclusive: '',
        quiz: '',
        preview_url: '',
        pdf_url: '',
        published_on: '',
        product_sku: '',
        valid_from: '',
        valid_till: '',
        listen_free_start: '',
        listen_free_end: '',
        magento_product_id: '',
        price: 0,
        in_app_purchase_id: '',
        discount_price: 0,
        discount_start: '',
        discount_end: '',
        in_app_purchase_is_enabled: ''
      };
      const podcastRequestBody = {
        authors: [],
        title: '',
        sub_title: '',
        description: '',
        categories: [],
        topics: [],
        new_release: '',
        new_release_index: '',
        top_podcast: '',
        top_podcast_index: '',
        exclusive: '',
        quiz: '',
        published_on: '',
        product_sku: '',
        valid_from: '',
        valid_till: '',
        magento_product_id: '',
        price: 0,
        discount_price: 0,
        discount_start: '',
        discount_end: ''
      };
      const curatedCollectionRequestBody = {
        authors: [],
        title: '',
        sub_title: '',
        description: '',
        categories: [],
        topics: [],
        new_release: '',
        new_release_index: '',
        featured_curated_collection: '',
        featured_curated_collection_index: '',
        exclusive: '',
        quiz: '',
        published_on: '',
        product_sku: '',
        valid_from: '',
        valid_till: '',
        listen_free_start: '',
        listen_free_end: '',
        magento_product_id: '',
        price: 0,
        in_app_purchase_id: '',
        discount_price: 0,
        discount_start: '',
        discount_end: '',
        in_app_purchase_is_enabled: ''
      };
      this.utilService.cleanForm(this.contentForm);
      this.createRequestBody(
        (this.isPodcast) ? podcastRequestBody : (this.isCuratedCollection) ? curatedCollectionRequestBody : audioRequestBody
      );
      const contentFormData: FormData = this.utilService.objectToFormData(
        (this.isPodcast) ? podcastRequestBody : (this.isCuratedCollection) ? curatedCollectionRequestBody : audioRequestBody
      );
      const contentObserver = this.isCreation
        ? this.contentService.postContent(contentFormData)
        : this.contentService.updateContents(contentFormData, this.contentId);

      contentObserver
        .subscribe(
          (content: IContent) => {
            const result: IDialogResult = {isCreation: this.isCreation, result: content};
            this.addEditContentDialog.close(result); // refresh page if edit, if create then redirect to first page
          },
          apiError => {
            console.error('Content Add / Edit API error => ', JSON.stringify(apiError));
          });
    } else {
      this.toasterService.error(Constants.error.INVALID_FORM);
    }
  }

  createRequestBody(requestBody: any): void {
    requestBody.authors = this.selectedAuthorsArray.map(author => author.id);
    requestBody.categories = this.selectedCategoryArray.map(category => category.id);
    requestBody.topics = this.selectedTopicArray.map(topic => topic.id);
    if (!this.isPodcast && !this.isCuratedCollection) {
      requestBody.run_time = this.utilService
        .runTimeToMilliSeconds(this.contentForm.getRawValue().run_time_minutes, this.contentForm.getRawValue().run_time_seconds);
    }
    Object.entries(this.contentForm.getRawValue()).forEach(([key, value]) => {
      if (requestBody.hasOwnProperty(key)) {
        // @ts-ignore
        if ([null, '', undefined].indexOf(value) >= 0) {
          if (this.isCreation) {
            delete requestBody[key];
          } else {
            requestBody[key] = '';
          }
        } else {
          requestBody[key] = key === 'valid_from' ? this.validFrom
            : requestBody[key] = key === 'valid_till' ? this.validTill
              : requestBody[key] = key === 'published_on' ? this.publishedOn
                : requestBody[key] = key === 'listen_free_start' ? this.listenFreeStart
                  : requestBody[key] = key === 'listen_free_end' ? this.listenFreeEnd
                    : requestBody[key] = key === 'discount_start' ? this.discountStart
                      : requestBody[key] = key === 'discount_end' ? this.discountEnd
                        : requestBody[key] = key === 'price' ? this.utilService.formatPriceRequest(this.price)
                          : requestBody[key] = key === 'discount_price' ? this.utilService.formatPriceRequest(this.discountPrice)
                            : JSON.parse(JSON.stringify(value));
        }
      }
    });

    requestBody.podcast = this.isPodcast;
    requestBody.curated_collection = this.isCuratedCollection;
    if (this.inAppPurchaseIsEnabled) {
      requestBody.in_app_purchase_is_enabled = this.inAppPurchaseIsEnabled;
    }
    requestBody.product_sku = requestBody.product_sku.toUpperCase();
    // handle indexes
    if (this.isPodcast) {
      if (!requestBody.top_podcast) {
        delete requestBody.top_podcast_index;
      } else if (!this.isCreation && !requestBody.top_podcast_index) {
        requestBody.top_podcast_index = 0;
      }
    } else if (this.isCuratedCollection) {
      if (!requestBody.featured_curated_collection) {
        delete requestBody.featured_curated_collection_index;
      } else if (!this.isCreation && !requestBody.featured_curated_collection_index) {
        requestBody.featured_curated_collection_index = 0;
      }
    } else {
      if (!requestBody.trending) {
        delete requestBody.trending_index;
      } else if (!this.isCreation && !requestBody.trending_index) {
        requestBody.trending_index = 0;
      }
    }
    if (!requestBody.new_release) {
      delete requestBody.new_release_index;
    } else if (!this.isCreation && !requestBody.new_release_index) {
      requestBody.new_release_index = 0;
    }
    if (this.contentForm.controls.url.disabled) {
      if (this.isCreation || this.isPodcast || this.isCuratedCollection) {
        delete requestBody.url;
      } else {
        requestBody.url = '';
      }
    }
    if (requestBody.chapter_info && requestBody.chapter_info.length) {
      requestBody.chapter_info = this.createChapterInfoBody(requestBody.chapter_info);
    } else {
      if (this.isCreation || this.isPodcast || this.isCuratedCollection) {
        delete requestBody.chapter_info;
      } else {
        requestBody.chapter_info = [];
      }
    }
    if (this.isCreation) {
      if (this.imgUrl) {
        requestBody.img_url = this.imgUrl.split('?')[0];
      }
    } else {
      requestBody.img_url = this.imgUrl.split('?')[0];
    }
    if (this.file) {
      requestBody.file = this.file;
      delete requestBody.img_url;
    }
  }

  createChapterInfoBody(chapterInfo: IChapter[]): any[] {
    let chapterRequestBody = chapterInfo.map(chapter => {
      const tempChapter: IChapter = {
        chapter_index: 0,
        chapter_run_time: 0,
        chapter_title: '',
        chapter_url: '',
        listen_free_end: '',
        listen_free_start: '',
        chapter_listen_free_end: '',
        chapter_listen_free_start: ''
      };
      chapter.chapter_run_time = this.utilService.runTimeToMilliSeconds(chapter.chapter_run_time_minutes, chapter.chapter_run_time_seconds);
      this.validChapterKeys.forEach(validKey => {
        if (chapter[validKey]) {
          tempChapter[validKey] = chapter[validKey];
        }
      });

      return tempChapter;
    });
    chapterRequestBody = [...this.updateChapterIndexes(chapterRequestBody)];

    return chapterRequestBody;
  }

  handleRunTime(value: any): void {
    const seconds = parseInt(value.value || 0, 10);
    if (seconds) {
      this.contentForm.controls.run_time_minutes.clearValidators();
      this.contentForm.controls.run_time_minutes.setValidators([Validators.min(0)]);
      this.runTimeMinutesMandatory = false;
    } else {
      this.contentForm.controls.run_time_minutes.clearValidators();
      this.contentForm.controls.run_time_minutes.setValidators([Validators.min(1)]);
      this.runTimeMinutesMandatory = true;
    }
    this.contentForm.controls.run_time_minutes.updateValueAndValidity();
  }

  updateChapterIndexes(chapterInfo: IChapter[] = []): IChapter[] {
    chapterInfo.forEach((chapter, arrayIndex) => {
      chapter.chapter_index = arrayIndex + 1;
    });

    return chapterInfo;
  }

  updateContentRunTimeFromChapters(chapterInfo: IChapter[] = []): void {
    let totalRunTimeMinutes = 0;
    let totalRunTimeSeconds = 0;
    chapterInfo.forEach(chapter => {
      totalRunTimeMinutes += Number(chapter.chapter_run_time_minutes) || 0;
      totalRunTimeSeconds += Number(chapter.chapter_run_time_seconds) || 0;
    });
    totalRunTimeMinutes += parseInt(String(totalRunTimeSeconds / 60), 10);
    totalRunTimeSeconds = totalRunTimeSeconds % 60;
    this.contentForm.get('run_time_minutes').setValue(totalRunTimeMinutes);
    this.contentForm.get('run_time_seconds').setValue(totalRunTimeSeconds);
  }

  openCloseAuthorNameList(opened: boolean): void {
    if (!opened) {
      this.contentForm.controls.searchAuthor.reset();
      this.contentForm.controls.authorNameList.reset();
      this.authorsList = this.cachedAuthorList;
    }
  }
  onFileChange(uploadedFile: File): void {
    if (uploadedFile) {
      const file = uploadedFile;
      const fileSize = file.size;
      if (fileSize > Constants.maxImageSize) {
        this.toasterService.error(`Image size should not be more than ${Constants.maxImageSize / 1000} KB.`);
      } else {
        const reader = new FileReader();
        reader.onload = e => this.imgUrl = reader.result;
        reader.readAsDataURL(file);
        this.file = file;
        this.showDeleteIcon = true;
      }
    }
  }

  transformPrice(event: any, attribute: string): void {
    const value = event.target.value.toString().replace(/[^\.0-9]/g, '');
    this[attribute] = this.utilService.formatPrice(value, true);
  }


  clearImage(): void {
    this.imgUrl = '';
    this.file = '';
    this.showDeleteIcon = false;
  }

  getTopicsFromCategoryObjectArray(): Observable<any> {
    return new Observable<any>(observer => {
      if (!this.selectedCategoryArray || !this.selectedCategoryArray.length) {
        this.topics = [];
        this.selectedTopicArray = [];

        return observer.next();
      }
      const categoryIds = this.selectedCategoryArray.map(category => category.id);
      this.topicService.getTopics(true, null, categoryIds).subscribe((topics: {results: ITopic[], next_page_key: string}) => {
        this.topics = topics.results;
        const topicsIdsForSelectedCategories = this.topics.map(topic => topic.id);
       // this.selectedTopicArray = this.selectedTopicArray.filter(topic => topicsIdsForSelectedCategories.indexOf(topic.id) >= 0);

        return observer.next();
      });
    });
  }
}
