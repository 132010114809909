import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Constants from '@constants';
import { IAPIQueryParameters } from '@sharedInterfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ContentService {

  constructor(
    private http: HttpClient
  ) {}

  postBulkContent(contentFile: FormData): Observable<any> {
    return this.http.put(`${Constants.getAPIURL.content()}`, contentFile);
  }

  postContent(contentData: FormData): Observable<any> {
    return this.http.post(`${Constants.getAPIURL.content()}`, contentData);
  }

  deleteContent(contentId: string): Observable<any> {
    return this.http.delete(`${Constants.getAPIURL.content(null, contentId)}`);
  }

  getContents(nextPageKey?: string): Observable<any> {
    const queryParameters: IAPIQueryParameters[] = [{key: 'all', value: true}, {key: 'limit', value: Constants.tableOnePgRecords}];
    if (nextPageKey) {
      queryParameters.push({key: 'next_page', value: nextPageKey});
    }
    let apiURL: string = Constants.getAPIURL.content(queryParameters);
    const contentFilter = JSON.parse(localStorage.getItem(Constants.localStorageKeys.contentFilters) || JSON.stringify({}));
    const selectedFilterKey = Object.keys(contentFilter).length ? Object.keys(contentFilter)[0] : '';
    const selectedFilterValue = selectedFilterKey ? contentFilter[selectedFilterKey] : '';
    switch (selectedFilterKey) {
      case 'new_release':
        apiURL = Constants.getAPIURL.newRelease(queryParameters);
        break;
      case 'quick_listens':
        apiURL = Constants.getAPIURL.quickListen(queryParameters);
        break;
      case 'quiz':
        queryParameters.push({key: selectedFilterKey, value: selectedFilterValue});
        apiURL = Constants.getAPIURL.content(queryParameters);
        break;
      case 'podcast':
        queryParameters.push({key: selectedFilterKey, value: selectedFilterValue});
        apiURL = Constants.getAPIURL.content(queryParameters);
        break;
      case 'curated_collection':
        queryParameters.push({key: selectedFilterKey, value: selectedFilterValue});
        apiURL = Constants.getAPIURL.content(queryParameters);
        break;
      case 'trending':
        apiURL = Constants.getAPIURL.trending(queryParameters);
        break;
      case 'top_podcast':
        apiURL = Constants.getAPIURL.topPodcast(queryParameters);
        break;
      case 'featured_curated_collection':
        apiURL = Constants.getAPIURL.featuredCuratedCollection(queryParameters);
        break;
      case 'author':
        queryParameters.push({key: 'aid', value: selectedFilterValue.id});
        apiURL = Constants.getAPIURL.content(queryParameters);
        break;
    }

    return this.http.get(`${apiURL}`);
  }

  updateContents(contentData: FormData, contentId: string): Observable<any> {
    return this.http.put(`${Constants.getAPIURL.content(null, contentId)}`, contentData);
  }

  getContentById(contentId: string): Observable<any> {
    const queryParameters: IAPIQueryParameters[] = [
      {key: 'all', value: true}
    ];

    return this.http.get(`${Constants.getAPIURL.content(queryParameters, contentId)}`);
  }

  bulkDelete(bulkContent: string[]): Observable<any> {
    return this.http.request('delete', `${Constants.getAPIURL.content()}`, {body: {ids: bulkContent}});
  }

}
