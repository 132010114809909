import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatBottomSheet, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { ICategory } from '@category/categories.interface';
import { CategoryService } from '@category/categories.service';
import Constants from '@constants';
import { IDialogResult } from '@sharedInterfaces';
import { ITopic } from '@topic/topics.interface';
import { TopicService } from '@topic/topics.service';
import { DownloadErrorComponent } from '@util/download-error/download-error.component';
import { IDownloadErrorDialogData } from '@util/download-error/download-error.interface';
import { UtilService } from '@util/util.service';
import * as httpStatus from 'http-status';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-edit-topic',
  templateUrl: './add-edit-topic.component.html',
  styleUrls: ['./add-edit-topic.component.scss']
})
export class AddEditTopicComponent implements OnInit {

  topicForm: FormGroup;
  submitButtonText = 'Add Topic';
  isCreation = true;
  isCustom = true;
  imgUrl: any = '';
  file: any;
  existingTopic: ITopic;
  showDeleteIcon = false;
  fileFormats: string[];
  selectedCategoryArray: { id: string, name: string }[] = [];
  categories: ICategory[];
  topicCJTags = Constants.topicCJTags;
  selectedCJTag: {view: string; value: string} = {view: '', value: ''};

  constructor(
    private formBuilder: FormBuilder,
    public addEditTopicDialog: MatDialogRef<any>,
    private topicService: TopicService,
    private utilService: UtilService,
    private toasterService: ToastrService,
    private categoryService: CategoryService,
    private bottomSheet: MatBottomSheet,
    @Inject(MAT_DIALOG_DATA) data: { topic: ITopic }
  ) {
    if (data && data.topic && Object.keys(data.topic).length) {
      this.selectedCategoryArray = this.selectedCategoryArray.concat(data.topic.category_info || []);
      this.existingTopic = data.topic;
      if (this.existingTopic.cj_tag) {
        const index = this.topicCJTags.findIndex(cjTag => cjTag.value === this.existingTopic.cj_tag);
        if (index >= 0) {
          this.selectedCJTag = this.topicCJTags[index];
        }
      }
      this.imgUrl = this.utilService.getLatestImage(this.existingTopic.img_url);
      this.existingTopic.quiz_img_url = this.utilService.getLatestImage(this.existingTopic.quiz_img_url);
      this.isCustom = this.existingTopic.is_custom || false;
      this.submitButtonText = 'Update Topic';
      this.isCreation = false;
    }
  }

  ngOnInit(): void {
    this.fileFormats = this.utilService.universalImageFormats();
    const actualImageURL = this.utilService.getBaseURL(this.imgUrl);
    if (this.isCustom && actualImageURL && actualImageURL !== Constants.S3.default.topic()) {
      this.showDeleteIcon = true;
    }
    this.createForm();
    this.categoryService.getCategories().subscribe((categoryList: ICategory[]) => {
      this.categories = categoryList;
    });
  }

  createForm(): void {
    const formControlConfig = {
      name: [{value: this.existingTopic && this.existingTopic.name || '', disabled: !this.isCustom}],
      index: [this.existingTopic && this.existingTopic.index || ''],
      categoryNameList: [''],
      cjTagList: [{value: '', disabled: !this.isCustom}],
      ...(!this.isCustom && {quiz_title: [{value: this.existingTopic && this.existingTopic.quiz_title, disabled: true}]}),
      ...(!this.isCustom && {quiz_index: [{value: this.existingTopic && this.existingTopic.quiz_index, disabled: true}]}),
    };
    this.topicForm = this.formBuilder.group(formControlConfig);
  }

  createUpdateTopic(): void {
    if (this.topicForm.valid) {
      this.utilService.cleanForm(this.topicForm);
      Object.entries(this.topicForm.getRawValue()).forEach(([component, value]) => {
        // @ts-ignore
        if ([null, '', undefined].indexOf(value) >= 0) {
          if (this.isCreation) {
            delete this.topicForm.value[component];
          } else {
            this.topicForm.value[component] = '';
          }
        } else {
          this.topicForm.value[component] = value;
        }
      });
      if (this.isCreation) {
        if (this.imgUrl) {
          this.topicForm.value.img_url = this.imgUrl.split('?')[0];
        }
      } else {
        this.topicForm.value.img_url = this.imgUrl.split('?')[0];
      }
      if (this.selectedCJTag && this.selectedCJTag.value) {
        this.topicForm.value.cj_tag = this.selectedCJTag.value;
      } else {
        if (!this.isCreation) {
          this.topicForm.value.cj_tag = '';
        }
      }
      if (this.file) {
        this.topicForm.value.file = this.file;
        delete this.topicForm.value.img_url;
      }
      this.topicForm.value.categories = this.selectedCategoryArray.map(category => category.id);
      delete this.topicForm.value.categoryNameList;
      delete this.topicForm.value.cjTagList;
      const topicFormData: FormData = this.utilService.objectToFormData(this.topicForm.value);

      const topicObserver = this.isCreation
        ? this.topicService.postTopic(topicFormData)
        : this.topicService.updateTopic(topicFormData, this.existingTopic.id);

      topicObserver.subscribe(
        () => {
          // refresh page if edit, if create then redirect to first page
          const result: IDialogResult = {isCreation: this.isCreation};
          this.addEditTopicDialog.close(result);
        },
        apiError => {
          console.error('topic Add / Edit API error => ', JSON.stringify(apiError));
          if (apiError && apiError.value && apiError.value.error && apiError.value.status === httpStatus.EXPECTATION_FAILED) {
            const downloadError: IDownloadErrorDialogData = {
              error: apiError.value.error || [],
              fileName: `Topic`,
              columnHeader: `Topic,Category,Product SKU`,
              bottomSheetMessage: 'There is content(s) associated with these Topic Category relationships.'
            };
            this.bottomSheet.open(DownloadErrorComponent, {disableClose: true, data: downloadError});
          }
        });
    } else {
      this.toasterService.error(Constants.error.INVALID_FORM);
    }
  }

  onFileChange(uploadedFile: File): void {
    if (uploadedFile) {
      const file = uploadedFile;
      const fileSize = file.size;
      if (fileSize > Constants.maxImageSize) {
        this.toasterService.error(`Image size should not be more than ${Constants.maxImageSize / 1000} KB.`);
      } else {
        const reader = new FileReader();
        reader.onload = e => this.imgUrl = reader.result;
        reader.readAsDataURL(file);
        this.file = file;
        this.showDeleteIcon = true;
      }
    }
  }

  clearImage(): void {
    this.imgUrl = '';
    this.file = '';
    this.showDeleteIcon = false;
  }

  selectCategory(selectedValue: { id: string, name: string }): void {
    if (this.selectedCategoryArray.findIndex(category => category.id === selectedValue.id) < 0) {
      this.selectedCategoryArray.push(selectedValue);
      this.topicForm.controls.categoryNameList.reset();
    }
  }

  selectCJTag(selectedValue: { view: string, value: string }): void {
    this.selectedCJTag = selectedValue;
    this.topicForm.controls.cjTagList.reset();
  }

  isSelectedCategory(id: string): boolean {
    if (this.selectedCategoryArray.findIndex(entity => entity.id === id) >= 0) {
      return true;
    }
  }

  isSelectedCJTag(cjTag: { view: string, value: string }): boolean {
    if (this.selectedCJTag.value === cjTag.value) {
      return true;
    }
  }

  removeSelectedCategory(id: string): void {
    this.selectedCategoryArray = this.selectedCategoryArray.filter(category => category.id !== id);
  }

  removeSelectedCJTag(): void {
    this.selectedCJTag = {view: '', value: ''};
  }

}
