import { Component, Injectable, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatBottomSheet, MatDialog } from '@angular/material';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import Constants from '@constants';
import { ContentType } from '@content/content.enum';
import { IContent } from '@content/content.interface';
import { ContentService } from '@content/content.service';
import { AddEditEpisodeComponent } from '@content/podcast-episode/add-edit-episode/add-edit-episode.component';
import { IEpisode, IEpisodeDialogData } from '@content/podcast-episode/podcast-episode.interface';
import { PodcastEpisodeService } from '@content/podcast-episode/podcast-episode.service';
import { IKlevuResult } from '@klevu/klevu.interface';
import { KlevuService } from '@klevu/klevu.service';
import {
  IDialogData,
  IDialogResult,
  IMultiSelectContent,
  IPagination,
  IRouteQueryParameters,
  ITableDisplayedColumns
} from '@sharedInterfaces';
import { ConfirmationComponent } from '@util/confirmation/confirmation.component';
import { DownloadErrorComponent } from '@util/download-error/download-error.component';
import { IDownloadErrorDialogData } from '@util/download-error/download-error.interface';
import { UploadDialogComponent } from '@util/upload-dialog/upload-dialog.component';
import { UtilService } from '@util/util.service';
import * as httpStatus from 'http-status';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-episodes-list',
  templateUrl: './episodes-list.component.html',
  styleUrls: ['./episodes-list.component.scss']
})
export class EpisodesListComponent implements OnInit {

  displayedColumns: ITableDisplayedColumns[] = [
    {key: 'title', field: 'Title'},
    {key: 'url', field: 'URL'},
    {key: 'index', field: 'Index'},
    {key: 'run_time_string', field: 'Runtime'},
    {key: 'product_sku', field: 'SKU'}
  ];
  podcastInfoFetch: Promise<boolean>;
  currentPageNo: number;
  currentPageKey: string;
  nextPageKey: string;
  podcastId: string;
  episodes: IEpisode[] = [];
  podcastInfo: IContent;
  selectedEpisodes: IMultiSelectContent[];
  showBulkDeleteButton: Subject<boolean> = new Subject<boolean>();
  searchBoxField = new FormControl();
  klevuResult: IKlevuResult = {moreResults: false, result: [], totalResults: 0};
  noKlevuResult: Subject<boolean> = new Subject<boolean>();
  searchTerm = '';
  nameCheck = false; // this is for bulk upload feature to upload csv which contain names instead of ids

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private podcastEpisodeService: PodcastEpisodeService,
    private contentService: ContentService,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private dialog: MatDialog,
    private klevuService: KlevuService,
    private bottomSheet: MatBottomSheet,
  ) {
    this.podcastInfo = this.router.getCurrentNavigation().extras.state as IContent;
    this.searchBoxField
      .valueChanges
      .pipe(debounceTime(Constants.klevuDeBounceTime))
      .subscribe(term => {
        this.noKlevuResult.next(false);
        this.searchTerm = term;
        this.fillSearchBox(term);
      });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.currentPageNo = Number(queryParams.pageNo || 1);
      this.currentPageKey = queryParams.pageKey || '';
      this.podcastId = queryParams.id;
      this.nameCheck = queryParams.nameCheck || false;
      if (!this.podcastId) {
        this.navigateToViewContentScreen();
      } else {
        this.getPodcastEpisodes();
      }
      if (!this.podcastInfo) {
        this.getPodcastDetails().subscribe(result => this.podcastInfoFetch = Promise.resolve(result));
      } else {
        this.podcastInfoFetch = Promise.resolve(true);
      }
      this.router.events.subscribe((event: NavigationEnd) => {
        window.scroll(0, 0);
      });
    });
  }

  fillSearchBox(term: string): void {
    if (term) {
      this.klevuService
        .searchKlevu(term, 'episode', 0, this.podcastId)
        .subscribe(
          klevuResult => {
            this.klevuResult = klevuResult;
            if (!this.klevuResult || !this.klevuResult.result || !this.klevuResult.result.length) {
              this.noKlevuResult.next(true);
            }
          },
          error => console.error('Klevu episode error => ', error),
          () => this.noKlevuResult.next(true)
        );
    } else {
      this.resetSearchBox();
    }
  }

  navigateToViewEpisode(episode: MatAutocompleteSelectedEvent): void {
    if (episode.option.value && episode.option.value.id) {
      const episodeId = episode.option.value.id;
      this.showBulkDeleteButton.next(false);
      this.router.navigate([`/viewContent`], {
        queryParams: {
          id: episodeId,
          returnQueryParameters: JSON.stringify({pageNo: this.currentPageNo, pageKey: this.currentPageKey}),
          episode: true
        }
      });
    }
    this.resetSearchBox();
  }

  openKlevuSearchList(): void {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        search: this.searchTerm,
        pageNo: 1,
        entity: 'episode',
        contentId: this.podcastId
      }
    };
    this.router.navigate(['/searchList'], navigationExtras);
  }

  getPodcastEpisodes(): void {
    this.podcastEpisodeService
      .getEpisodes(this.podcastId, this.currentPageKey)
      .subscribe(
        (episodes: { results: IEpisode[], next_page_key: string }) => {
          this.episodes = episodes.results.map(
            episode =>
              ({
                ...episode,
                run_time_string: this.utilService.getRunTimeString(episode.run_time)
              }));
          this.nextPageKey = episodes.next_page_key;
        },
        error => {
          // if there is any error then redirect to previous screen
          if (error) {
            this.navigateToViewContentScreen();
          }
        }
      );
  }

  getPodcastDetails(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.contentService
        .getContentById(this.podcastId)
        .subscribe(
          (podcast: IContent) => {
            if (podcast.content_type !== ContentType.Podcast) {
              this.navigateToViewContentScreen();
            } else {
              this.podcastInfo = podcast;

              return observer.next(true);
            }
          },
          error => {
            // if there no podcast id then redirect to previous screen
            if (error) {
              this.navigateToViewContentScreen();
            }
          });
    });
  }

  openEpisodeDialog(existingEpisode?: IEpisode): void {
    const data: IEpisodeDialogData = {
      approvalButtonText: 'Add Episode',
      disApprovalButtonText: 'Cancel',
      podcastId: this.podcastId
    };
    if (existingEpisode) {
      data.episode = existingEpisode;
    }
    const episodeDialog = this.dialog.open(AddEditEpisodeComponent, {
      ...Constants.AddEditEpisodeDialogConfig,
      data
    });
    episodeDialog.afterClosed().subscribe((result: IDialogResult) => {
      if (result) {
        if (this.currentPageNo === 1 || !result.isCreation) {
          this.getPodcastEpisodes();
          this.getPodcastDetails().subscribe();
        } else {
          this.openPage({}, 1);
        }
      }
    });
  }

  openUploadDialog(): void {
    const data: IDialogData = {titleText: `Upload Bulk Episodes`};
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      ...Constants.UploadDialogConfig,
      data
    });
    dialogRef.afterClosed().subscribe(file => {
      if (file) {
        const request = {
          file,
          nameCheck: this.nameCheck,
          content_id: this.podcastId
        };
        const formData = this.utilService.objectToFormData(request);
        this.podcastEpisodeService
          .postBulkEpisodes(formData)
          .subscribe(
            () => {
              this.refreshPage();
            },
            (error) => {
              if (error && error.value && error.value.error && error.value.status === httpStatus.EXPECTATION_FAILED) {
                const downloadError: IDownloadErrorDialogData = {
                  error: error.value.error || [],
                  fileName: file.name.split('.csv')[0]
                };
                this.bottomSheet.open(DownloadErrorComponent, {disableClose: true, data: downloadError});
              }
            });
      }
    });
  }

  openPage(pageData?: IPagination, forcePageNumber?: number): void {
    const queryParams: IRouteQueryParameters = {
      pageNo: pageData.isPrevious ? this.currentPageNo - 1 : Number(this.currentPageNo) + 1,
      pageKey: pageData.pageKey || ''
    };
    if (forcePageNumber) {
      queryParams.pageNo = forcePageNumber;
    }
    this.router
      .navigate(
        [], {
          relativeTo: this.activatedRoute,
          queryParams,
          queryParamsHandling: 'merge'
        }
      );
  }

  showBulkOption(data: IMultiSelectContent[]): void {
    if (data && data.length) {
      this.selectedEpisodes = data;
      this.showBulkDeleteButton.next(true);
    } else {
      this.showBulkDeleteButton.next(false);
    }
  }

  openDeleteDialog(episodeInfo: { id: string }): void {
    const config = {
      ...Constants.confirmationDialogConfig,
      data: Constants.EpisodeSingleDeleteDialogData
    };
    const deleteDialog = this.dialog.open(ConfirmationComponent, config);
    deleteDialog.afterClosed().subscribe((approval: boolean) => {
      if (approval) {
        this.podcastEpisodeService.deleteEpisode(episodeInfo.id).subscribe(result => {
          this.refreshPage();
        });
      }
    });
  }

  deleteAllEpisodes(): void {
    const data: IDialogData = {
      titleText: 'Are you sure you want to delete all episodes for this podcast?',
      approvalButtonText: 'Delete',
      disApprovalButtonText: 'Cancel'
    };
    const config = {
      ...Constants.confirmationDialogConfig,
      data
    };
    const deleteDialog = this.dialog.open(ConfirmationComponent, config);
    deleteDialog.afterClosed().subscribe((approval: boolean) => {
      if (approval) {
        this.podcastEpisodeService.bulkDelete(null, this.podcastId).subscribe(result => {
          this.refreshPage();
        });
      }
    });
  }

  bulkDelete(): void {
    if (this.selectedEpisodes && this.selectedEpisodes.length) {
      const data: IDialogData = {
        titleText: 'Are you sure you want to delete multiple episodes?',
        approvalButtonText: 'Delete',
        disApprovalButtonText: 'Cancel'
      };
      const bulkDeleteConfirmation = this.dialog.open(ConfirmationComponent, {
        ...Constants.confirmationDialogConfig,
        data
      });
      bulkDeleteConfirmation.afterClosed().subscribe(approval => {
        if (approval) {
          this.podcastEpisodeService
            .bulkDelete(this.selectedEpisodes.map(episode => episode.id))
            .subscribe(() => {
              this.refreshPage();
            });
        }
      });
    }
  }

  navigateToViewContentScreen(): void {
    this.toastrService.error('Invalid Podcast to list episodes');
    this.router.navigate(['/viewContent'], {
      queryParams: {
        id: this.podcastId
      }
    });
  }

  refreshPage(): void {
    if (this.currentPageNo === 1) {
      this.getPodcastEpisodes();
      this.getPodcastDetails().subscribe();
    } else {
      this.openPage({}, 1);
    }
    this.showBulkDeleteButton.next(false);
  }

  resetSearchBox(): void {
    this.klevuResult = {moreResults: false, result: [], totalResults: 0};
  }

}
