import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from '@auth/auth.component';
import { AuthGuard } from '@auth/auth.guard';
import { AuthorsComponent } from '@author/authors.component';
import { ViewAuthorComponent } from '@author/view-author/view-author.component';
import { CategoriesComponent } from '@category/categories.component';
import { ContentComponent } from '@content/content.component';
import { TracksListComponent } from '@content/curated-collection-track/tracks-list/tracks-list.component';
import { EpisodesListComponent } from '@content/podcast-episode/episodes-list/episodes-list.component';
import { ViewContentComponent } from '@content/view-content/view-content.component';
import { KlevuComponent } from '@klevu/klevu.component';
import { TopicsComponent } from '@topic/topics.component';
import { ViewTopicComponent } from '@topic/view-topic/view-topic.component';

const routes: Routes = [
  {path: '', redirectTo: 'authors', pathMatch: 'full'}, // HOME redirect
  {path: 'login', component: AuthComponent},
  {path: 'content', canActivate: [AuthGuard], component: ContentComponent},
  {path: 'authors', canActivate: [AuthGuard], component: AuthorsComponent},
  {path: 'topics', component: TopicsComponent, canActivate: [AuthGuard]},
  {path: 'categories', component: CategoriesComponent, canActivate: [AuthGuard]},
  {path: 'viewContent', canActivate: [AuthGuard], component: ViewContentComponent},
  {path: 'viewAuthor', canActivate: [AuthGuard], component: ViewAuthorComponent},
  {path: 'viewTopic', canActivate: [AuthGuard], component: ViewTopicComponent},
  {path: 'episodeList', canActivate: [AuthGuard], component: EpisodesListComponent},
  {path: 'trackList', canActivate: [AuthGuard], component: TracksListComponent},
  {path: 'searchList', canActivate: [AuthGuard], component: KlevuComponent},
  {path: '**', redirectTo: 'authors'} // default redirect
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
