import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Constants from '@constants';
import { ITrackCreate, ITrackUpdate } from '@content/curated-collection-track/curated-collection-track.interface';
import { IAPIQueryParameters } from '@sharedInterfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CuratedCollectionTrackService {

  constructor(
    private http: HttpClient
  ) {
  }

  postBulkTracks(trackData: FormData): Observable<any> {
    return this.http.put(`${Constants.getAPIURL.track()}`, trackData);
  }

  postTrack(trackData: ITrackCreate): Observable<any> {
    return this.http.post(`${Constants.getAPIURL.track()}`, trackData);
  }

  getTrackById(trackId: string): Observable<any> {
    return this.http.get(`${Constants.getAPIURL.track(null, trackId)}`);
  }

  getTracks(curatedCollectionId: string, nextPageKey?: string): Observable<any> {
    const queryParameters: IAPIQueryParameters[] = [
      {key: 'all', value: true},
      {key: 'cid', value: curatedCollectionId},
      {key: 'limit', value: Constants.tableOnePgRecords}
    ];
    if (nextPageKey) {
      queryParameters.push({key: 'next_page', value: nextPageKey});
    }

    return this.http.get(`${Constants.getAPIURL.track(queryParameters)}`);
  }

  updateTrack(trackData: ITrackUpdate, trackId: string): Observable<any> {
    return this.http.put(`${Constants.getAPIURL.track(null, trackId)}`, trackData);
  }

  deleteTrack(trackId: string): Observable<any> {
    return this.http.delete(`${Constants.getAPIURL.track(null, trackId)}`);
  }

  bulkDelete(ids?: string[], curatedCollectionId?: string): Observable<any> {
    const requestBody: { ids?: string[], cid?: string } = {};
    if (ids) {
      requestBody.ids = ids;
    }
    if (curatedCollectionId) {
      requestBody.cid = curatedCollectionId;
    }

    return this.http.request('delete', `${Constants.getAPIURL.track()}`, {body: requestBody});
  }
}
