import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import Constants from '@constants';
import { AddEditContentComponent } from '@content/add-edit-content/add-edit-content.component';
import { ContentType } from '@content/content.enum';
import { ContentService } from '@content/content.service';
import { AddEditTrackComponent } from '@content/curated-collection-track/add-edit-track/add-edit-track.component';
import { ITrackDialogData } from '@content/curated-collection-track/curated-collection-track.interface';
import { CuratedCollectionTrackService } from '@content/curated-collection-track/curated-collection-track.service';
import { TracksListComponent } from '@content/curated-collection-track/tracks-list/tracks-list.component';
import { AddEditEpisodeComponent } from '@content/podcast-episode/add-edit-episode/add-edit-episode.component';
import { EpisodesListComponent } from '@content/podcast-episode/episodes-list/episodes-list.component';
import { IEpisodeDialogData } from '@content/podcast-episode/podcast-episode.interface';
import { PodcastEpisodeService } from '@content/podcast-episode/podcast-episode.service';
import { ITableDisplayedColumns } from '@sharedInterfaces';
import { TDialogType } from '@sharedTypes';
import { ConfirmationComponent } from '@util/confirmation/confirmation.component';
import { UtilService } from '@util/util.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-view-content',
  templateUrl: './view-content.component.html',
  styleUrls: ['./view-content.component.scss']
})
export class ViewContentComponent implements OnInit {

  contentInfo;
  contentInfoFetch: Promise<boolean>;
  showDescriptionButton = false;
  descriptionButtonText = '';
  description = '';
  fullDescription;
  noDescription = false;
  chaptersLength = false;
  returnQueryParameters: {};
  contentType: ContentType;
  entity = 'Audio Book';
  id: string;
  displayedColumns: ITableDisplayedColumns[] = [
    {key: 'chapter_title', field: 'Title'},
    {key: 'chapter_url', field: 'URL'},
    {key: 'chapter_index', field: 'Index'},
    {key: 'chapter_run_time_string', field: 'Runtime'}
  ];
  localContentType = ContentType;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private contentService: ContentService,
    private utilService: UtilService,
    private episodeService: PodcastEpisodeService,
    private episodeListComponent: EpisodesListComponent,
    private trackService: CuratedCollectionTrackService,
    private trackListComponent: TracksListComponent,
    private dialog: MatDialog,
  ) {
    this.contentInfo = this.router.getCurrentNavigation().extras.state;
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.id = queryParams.id;
      this.returnQueryParameters = JSON.parse(queryParams.returnQueryParameters || JSON.stringify({}));
      if (this.contentInfo && Object.keys(this.contentInfo).length) {
          this.formatData();
          this.contentInfoFetch = Promise.resolve(true);
      } else {
        if (!this.id) {
          this.router.navigate(['/content'], {queryParams: {...this.returnQueryParameters}});
        } else if (queryParams.episode) {
          this.getEpisodeById()
            .subscribe(
              result => this.contentInfoFetch = Promise.resolve(result),
              error => {
                // if there no content then redirect view content screen to previous list screen
                if (error) {
                  this.router.navigate(['/content'], {queryParams: {...this.returnQueryParameters}});
                }
              });
        } else if (queryParams.track) {
          this.getTrackById()
            .subscribe(
              result => this.contentInfoFetch = Promise.resolve(result),
              error => {
                // if there no content then redirect view content screen to previous list screen
                if (error) {
                  this.router.navigate(['/content'], {queryParams: {...this.returnQueryParameters}});
                }
              });
        } else {
          this.getContentById().subscribe(result => this.contentInfoFetch = Promise.resolve(result));
        }
      }
      this.router.events.subscribe((event: NavigationEnd) => {
        window.scroll(0, 0);
      });
    });
  }

  getContentById(): Observable<any> {
    return new Observable<any>(observer => {
      this.contentService.getContentById(this.id).subscribe(contentInfo => {
          this.contentInfo = contentInfo;
          this.formatData();

          return observer.next(true);
      },                                                    error => {
        // if there no content then redirect view content screen to previous list screen
        if (error) {
          this.router.navigate(['/content'], {queryParams: {...this.returnQueryParameters}});
        }
      });
    });
  }

  getEpisodeById(): Observable<any> {
    return new Observable<any>(observer => {
      this.episodeService.getEpisodeById(this.id).subscribe(contentInfo => {
        this.contentInfo = contentInfo;
        this.formatData();

        return observer.next(true);
      },                                                    error => {
        // if there no content then redirect view content screen to previous list screen
        if (error) {
          this.router.navigate(['/episodeList'], {queryParams: {...this.returnQueryParameters}});
        }
      });
    });
  }

  getTrackById(): Observable<any> {
    return new Observable<any>(observer => {
      this.trackService.getTrackById(this.id).subscribe(contentInfo => {
        this.contentInfo = contentInfo;
        this.formatData();

        return observer.next(true);
      },                                                error => {
        // if there no content then redirect view content screen to previous list screen
        if (error) {
          this.router.navigate(['/trackList'], {queryParams: {...this.returnQueryParameters}});
        }
      });
    });
  }

  getAuthors(guests: boolean = false): string {
    let authors = this.contentInfo.author_info;
    if (guests && (this.contentType === ContentType.Episode || this.contentType === ContentType.Track)) {
      authors = this.contentInfo.guest_info;
    }

    return (authors || [{name: '-'}]).filter(el => el).map(author => author.name).filter(author => author).join(', ');
  }

  formatData(): void {
    // CONTENT TYPE
    this.contentType = this.contentInfo.content_type;
    if (this.contentType === ContentType.Podcast) {
      this.entity = 'Podcast';
    }
    if (this.contentType === ContentType.Episode) {
      this.entity = 'Episode';
    }
    if (this.contentType === ContentType['Curated Collection']) {
      this.entity = 'Curated Collection';
    }
    if (this.contentType === ContentType.Track) {
      this.entity = 'Track';
    }

    // DATE
    this.contentInfo.valid_from_formatted = this.utilService.formatDate(this.contentInfo.valid_from);
    this.contentInfo.valid_till_formatted = this.utilService.formatDate(this.contentInfo.valid_till);
    this.contentInfo.listen_free_start_formatted = this.utilService.formatDatetime(this.contentInfo.listen_free_start);
    this.contentInfo.listen_free_end_formatted = this.utilService.formatDatetime(this.contentInfo.listen_free_end);
    this.contentInfo.discount_start_formatted = this.utilService.formatDatetime(this.contentInfo.discount_start);
    this.contentInfo.discount_end_formatted = this.utilService.formatDatetime(this.contentInfo.discount_end);
    this.contentInfo.published_on_formatted = this.utilService.formatDate(this.contentInfo.published_on);

    // RUN TIME
    this.contentInfo.run_time_string = this.utilService.getRunTimeString(this.contentInfo.run_time);
    (this.contentInfo.chapter_info || [])
      .forEach(chapter => chapter.chapter_run_time_string = this.utilService.getRunTimeString(chapter.chapter_run_time));

    // URL VALIDATION
    this.contentInfo.url_valid = this.utilService.isValidURL(this.contentInfo.url);
    this.contentInfo.img_url_valid = this.utilService.isValidURL(this.contentInfo.img_url);
    this.contentInfo.preview_url_valid = this.utilService.isValidURL(this.contentInfo.preview_url);
    this.contentInfo.pdf_url_valid = this.utilService.isValidURL(this.contentInfo.pdf_url);

    // URL
    this.contentInfo.img_url_view = this.contentInfo.img_url;
    this.contentInfo.img_url = this.utilService.getLatestImage(this.contentInfo.img_url);

    // DESCRIPTION
    this.noDescription = !this.contentInfo.description;
    if (this.contentInfo.description) {
      this.contentInfo.description = this.contentInfo.description.replace(/[\r\n]/g, '<br>');
    }
    this.description = (this.contentInfo.description || '*** No Description ***')
      .substr(0, Constants.lessDescriptionCharacterLimit);
    if (this.description !== this.contentInfo.description && this.contentInfo.description) {
      this.description += '<b>...</b>';
      this.showDescriptionButton = true;
      this.descriptionButtonText = Constants.descriptionMoreButtonText;
      this.fullDescription = false;
    } else {
      this.showDescriptionButton = false;
    }

    // CHAPTERS
    this.contentInfo.chapter_info = this.contentInfo.chapter_info || [];
    this.chaptersLength = this.contentInfo.chapter_info.length;

    // PRICE
    this.contentInfo.price = this.utilService.formatPrice(this.contentInfo.price);
    this.contentInfo.discount_price = this.utilService.formatPrice(this.contentInfo.discount_price);
  }

  toggleDescription(): void {
    if (this.fullDescription) {
      this.description = this.contentInfo.description.substr(0, Constants.lessDescriptionCharacterLimit);
      this.description += '<b>...</b>';
      this.descriptionButtonText = Constants.descriptionMoreButtonText;
      this.fullDescription = false;
    } else {
      this.description = this.contentInfo.description;
      this.descriptionButtonText = Constants.descriptionLessButtonText;
      this.fullDescription = true;
    }
  }

  /** Emit an event to open dialog to edit / delete item. Item to be passed as prop */
  openDialog(type: TDialogType): void {
    if (type === 'edit') {
      if (this.contentType === ContentType.Episode) {
        const data: IEpisodeDialogData = {
          approvalButtonText: 'Update Episode',
          disApprovalButtonText: 'Cancel',
          podcastId: this.contentInfo.content_id,
          episode: this.contentInfo
        };
        const contentDialogRef = this.dialog.open(AddEditEpisodeComponent, {
          ...Constants.AddEditEpisodeDialogConfig,
          data
        });
        contentDialogRef.afterClosed().subscribe(isRefresh => {
          if (isRefresh) {
            this.getEpisodeById().subscribe(() => {
            });
          }
        });
      } else if (this.contentType === ContentType.Track) {
        const data: ITrackDialogData = {
          approvalButtonText: 'Update Track',
          disApprovalButtonText: 'Cancel',
          curatedCollectionId: this.contentInfo.content_id,
          track: this.contentInfo
        };
        const contentDialogRef = this.dialog.open(AddEditTrackComponent, {
          ...Constants.AddEditTrackDialogConfig,
          data
        });
        contentDialogRef.afterClosed().subscribe(isRefresh => {
          if (isRefresh) {
            this.getTrackById().subscribe(() => {
            });
          }
        });
      } else {
        const contentDialogRef = this.dialog.open(AddEditContentComponent, {
          ...Constants.AddEditContentDialogConfig,
          data: {
            content: JSON.parse(JSON.stringify(this.contentInfo)),
            contentType: this.contentType
          }
        });
        contentDialogRef.afterClosed().subscribe(isRefresh => {
          if (isRefresh) {
            this.getContentById().subscribe(() => {
            });
          }
        });
      }
    } else if (type === 'delete') {
      const config = Constants.confirmationDialogConfig;
      if (this.contentType === ContentType.Episode) {
        const deleteDialogRef = this.dialog.open(ConfirmationComponent, {
          ...config,
          data: Constants.EpisodeSingleDeleteDialogData
        });
        deleteDialogRef.afterClosed().subscribe(approval => {
          if (approval) {
            this.episodeService.deleteEpisode(this.contentInfo.id).subscribe(
              () => this.router.navigate([`/episodeList`], {queryParams: {id: this.contentInfo.content_id}}));
          }
        });
      } else if (this.contentType === ContentType.Track) {
        const deleteDialogRef = this.dialog.open(ConfirmationComponent, {
          ...config,
          data: Constants.TrackSingleDeleteDialogData
        });
        deleteDialogRef.afterClosed().subscribe(approval => {
          if (approval) {
            this.trackService.deleteTrack(this.contentInfo.id).subscribe(
              () => this.router.navigate([`/trackList`], {queryParams: {id: this.contentInfo.content_id}}));
          }
        });
      } else {
        const deleteDialogRef = this.dialog.open(ConfirmationComponent, {
          ...config,
          data: Constants.ContentSingleDeleteDialogData(
            this.contentInfo.content_type === ContentType.Podcast,
            this.contentInfo.content_type === ContentType['Curated Collection']
          )
        });
        deleteDialogRef.afterClosed().subscribe(approval => {
          if (approval) {
            this.contentService.deleteContent(this.contentInfo.id).subscribe(() => this.router.navigate([`/content`]));
          }
        });
      }
    }
  }

  navigateToEpisodeList(): void {
    const navigationExtras: NavigationExtras = {
      queryParams: {id: this.id},
      state: JSON.parse(JSON.stringify(this.contentInfo))
    };
    this.router.navigate(['/episodeList'], navigationExtras);
  }

  navigateToTrackList(): void {
    const navigationExtras: NavigationExtras = {
      queryParams: {id: this.id},
      state: JSON.parse(JSON.stringify(this.contentInfo))
    };
    this.router.navigate(['/trackList'], navigationExtras);
  }

}
