import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICategory } from '@category/categories.interface';
import Constants from '@constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CategoryService {

  categories: ICategory[] = [];

  constructor(
    private http: HttpClient
  ) {
  }

  getCategories(): Observable<any> {
    return this.http.get(`${Constants.getAPIURL.category()}`);
  }

}
