import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IAuthor } from '@author/authors.interface';
import { AuthorService } from '@author/authors.service';
import Constants from '@constants';
import { KlevuService } from '@klevu/klevu.service';
import { IFilterDialogData, IFilterHTML } from '@sharedInterfaces';
import { TEntityType } from '@sharedTypes';
import { UtilService } from '@util/util.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-filter-content',
  templateUrl: './filter-content.component.html',
  styleUrls: ['./filter-content.component.scss']
})
export class FilterContentComponent implements OnInit {

  contentFilters: IFilterHTML[] = [
    {view: 'New Release', value: 'new_release'},
    {view: 'Quiz', value: 'quiz'},
    {view: 'Trending Audio Book', value: 'trending'},
    {view: 'Top Podcast', value: 'top_podcast'},
    {view: 'Quick Listen', value: 'quick_listens'},
    {view: 'Podcast', value: 'podcast'},
    {view: 'Curated Collection', value: 'curated_collection'},
    {view: 'Featured Curated Collection', value: 'featured_curated_collection'},
  ];
  isFilterApplied: Subject<boolean> = new Subject<boolean>();
  selectedFilter: string;
  authorFilterName = 'author';
  cachedAuthorList;
  searchApplied = 0;
  filterForm: FormGroup;
  authorsList = [];
  selectedAuthor: any = {};

  constructor(
    public filtersDialog: MatDialogRef<any>,
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    private klevuService: KlevuService,
    private authorService: AuthorService,
    private toasterService: ToastrService,
    @Inject(MAT_DIALOG_DATA) data: IFilterDialogData
  ) {
    if (data) {
      this.isFilterApplied = data.isFilterApplied;
    }
  }

  ngOnInit(): void {
    this.createForm();
    this.getAuthors();
    this.filterForm
      .controls
      .searchAuthor
      .valueChanges
      .pipe(debounceTime(Constants.klevuDeBounceTime))
      .subscribe(term => {
        if (term) {
          this.klevuService
            .searchKlevu(term, 'author')
            .subscribe(klevuResult => {
              this.searchApplied = 1;
              this.authorsList = klevuResult.result;
            });
        } else if (this.searchApplied) {
          this.authorsList = this.cachedAuthorList;
          this.searchApplied = 0;
        }
      });
  }

  createForm(): void {
    const contentFilter = JSON.parse(localStorage.getItem(Constants.localStorageKeys.contentFilters) || JSON.stringify({}));
    this.selectedFilter = Object.keys(contentFilter).length ? Object.keys(contentFilter)[0] : '';
    if (this.selectedFilter === this.authorFilterName) {
      this.selectedAuthor = contentFilter[this.authorFilterName];
    }

    this.filterForm = this.formBuilder.group({authorNameList: [''], searchAuthor: ['']});
  }

  selectFilter(filter: string): void {
    this.selectedFilter = filter;
    if (filter !== this.authorFilterName && this.selectedAuthor && this.selectedAuthor.id) {
      this.selectedAuthor = {};
    }
  }

  resetFilters(): void {
    this.selectedFilter = null;
    this.selectedAuthor = {};
  }

  setFilters(): void {
    if (!this.selectedFilter && (!this.selectedAuthor || !this.selectedAuthor.id)) {
      this.isFilterApplied.next(false);
      localStorage.removeItem(Constants.localStorageKeys.contentFilters);
      this.filtersDialog.close(true);
    } else if (this.selectedFilter === this.authorFilterName && this.selectedAuthor && this.selectedAuthor.id) {
      localStorage.setItem(Constants.localStorageKeys.contentFilters, JSON.stringify({ [this.selectedFilter]: this.selectedAuthor }));
      this.filtersDialog.close(true);
    } else if (this.selectedFilter !== this.authorFilterName) {
      localStorage.setItem(Constants.localStorageKeys.contentFilters, JSON.stringify({ [this.selectedFilter]: true }));
      this.filtersDialog.close(true);
    } else if (this.selectedFilter === this.authorFilterName && (!this.selectedAuthor || !this.selectedAuthor.id)) {
      this.toasterService.error(Constants.error.NO_AUTHOR_SELECT_FOR_FILTER);
    }
  }

  openCloseAuthorNameList(opened: boolean): void {
    if (!opened) {
      this.filterForm.controls.searchAuthor.reset();
      this.filterForm.controls.authorNameList.reset();
      this.authorsList = this.cachedAuthorList;
      if (!this.selectedAuthor || !this.selectedAuthor.id) {
        this.resetFilters();
      }
    }
  }

  getAuthors(): void {
    this.authorService.getAuthors(null, true).subscribe((authorsList: {
      results: IAuthor[], next_page_key: string
    }) => {
      this.authorsList = JSON.parse(JSON.stringify(authorsList.results));
      this.cachedAuthorList = JSON.parse(JSON.stringify(authorsList.results));
    });
  }

  selectEntity(selectedValue: {id: string}, type: TEntityType): void {
    switch (type) {
      case 'author':
        if (this.selectedAuthor.id !== selectedValue.id) {
          this.selectedAuthor = selectedValue;
          this.filterForm.controls.searchAuthor.reset();
          this.filterForm.controls.authorNameList.reset();
          this.authorsList = this.cachedAuthorList;
        }
        break;
    }
  }

  isSelectedValue(id: string, type: TEntityType): boolean {
    switch (type) {
      case 'author':
        if (this.selectedAuthor.id === id) {
          return true;
        }
        break;
    }
  }

  removeSelectedEntity(id: string, type: TEntityType): void {
    switch (type) {
      case 'author':
        this.resetFilters();
        break;
    }
  }

}
