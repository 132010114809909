import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IAuthor } from '@author/authors.interface';
import { AuthorService } from '@author/authors.service';
import Constants from '@constants';
import { IEpisode, IEpisodeCreate, IEpisodeDialogData, IEpisodeUpdate } from '@content/podcast-episode/podcast-episode.interface';
import { PodcastEpisodeService } from '@content/podcast-episode/podcast-episode.service';
import { IKlevuObject } from '@klevu/klevu.interface';
import { KlevuService } from '@klevu/klevu.service';
import { IDialogResult, IRunTime } from '@sharedInterfaces';
import { UtilService } from '@util/util.service';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-add-edit-episode',
  templateUrl: './add-edit-episode.component.html',
  styleUrls: ['./add-edit-episode.component.scss']
})
export class AddEditEpisodeComponent implements OnInit {

  isCreation = true;
  podcastId = '';
  episodeId = '';
  attributes: IEpisodeDialogData = {disApprovalButtonText: 'Close', approvalButtonText: 'Save', podcastId: ''};
  existingEpisode: IEpisode;
  episodeForm: FormGroup;
  episodeIndex;
  searchApplied = false;
  authorsList: IKlevuObject[] | IAuthor[] = [];
  cachedAuthorList: IAuthor[];
  defaultDateFormat = Constants.dateDefaultFormat;
  selectedAuthorsArray: { id: string, name: string }[] = [];
  runTimeMinutesMandatory = true;
  runTimeMinutes = 0;
  runTimeSeconds = 0;
  publishedOn = '';

  constructor(
    private authorService: AuthorService,
    private formBuilder: FormBuilder,
    private klevuService: KlevuService,
    private toasterService: ToastrService,
    private utilService: UtilService,
    private podcastEpisodeService: PodcastEpisodeService,
    public addEditEpisodeDialog: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) data: IEpisodeDialogData
  ) {

    if (data && Object.keys(data).length) {
      this.attributes.approvalButtonText = data.approvalButtonText;
      this.attributes.disApprovalButtonText = data.disApprovalButtonText;

      if (data.podcastId) {
        this.podcastId = data.podcastId;
      } else {
        console.error('No podcast id provided');
      }

      if (data.episode && Object.keys(data.episode)) {
        this.existingEpisode = data.episode;
        this.episodeIndex = Number(data.episode.index);
        this.isCreation = false;
        this.episodeId = data.episode.id;
        this.selectedAuthorsArray = data.episode.guest_info || [];
        this.attributes.approvalButtonText = 'Update Episode';
        const runTime: IRunTime = this.utilService.milliSecondsToMinutesSeconds(data.episode.run_time);
        this.runTimeMinutes = runTime.minutes;
        this.runTimeSeconds = runTime.seconds;
        if (this.runTimeSeconds) {
          this.runTimeMinutesMandatory = false;
        }
        this.publishedOn = this.utilService.formatDateRequest(this.existingEpisode.published_on);
      } else if (data.episodeIndex) {
        this.episodeIndex = data.episodeIndex;
      }
    }

    this.createForm();
    this.episodeForm
      .controls
      .searchAuthor
      .valueChanges
      .pipe(debounceTime(Constants.klevuDeBounceTime))
      .subscribe(term => {
        if (term) {
          this.klevuService
            .searchKlevu(term, 'author')
            .subscribe(klevuResult => {
              this.searchApplied = true;
              this.authorsList = klevuResult.result;
            });
        } else if (this.searchApplied) {
          this.authorsList = this.cachedAuthorList;
          this.searchApplied = false;
        }
      });
  }

  ngOnInit(): void {
    this.getAuthors();
  }

  createForm(): void {
    this.episodeForm = this.formBuilder.group({
      title: [this.existingEpisode ? this.existingEpisode.title : ''],
      url: [this.existingEpisode ? this.existingEpisode.url : ''],
      product_sku: [this.existingEpisode ? this.existingEpisode.product_sku : ''],
      index: [this.episodeIndex || ''],
      published_on: [this.existingEpisode && this.existingEpisode.published_on ? this.existingEpisode.published_on : ''],
      run_time_minutes: [this.runTimeMinutes || 0, Validators.min(this.runTimeMinutesMandatory ? 1 : 0)],
      run_time_seconds: [
        this.runTimeSeconds || 0,
        [Validators.min(0), Validators.max(59)]
      ],
      authorNameList: [''],
      searchAuthor: [''],
      description: [this.existingEpisode && this.existingEpisode.description || ''],
    });
  }

  getAuthors(): void {
    this.authorService.getAuthors(null, true).subscribe((authorsList: {
      results: IAuthor[], next_page_key: string
    }) => {
      this.authorsList = JSON.parse(JSON.stringify(authorsList.results));
      this.cachedAuthorList = JSON.parse(JSON.stringify(authorsList.results));
    });
  }

  openCloseAuthorNameList(opened: boolean): void {
    if (!opened) {
      this.episodeForm.controls.searchAuthor.reset();
      this.episodeForm.controls.authorNameList.reset();
      this.authorsList = this.cachedAuthorList;
    }
  }

  selectAuthor(selectedValue: { id: string, name: string }): void {
    if (this.selectedAuthorsArray.findIndex(author => author.id === selectedValue.id) < 0) {
      this.selectedAuthorsArray.push(selectedValue);
      this.episodeForm.controls.searchAuthor.reset();
      this.episodeForm.controls.authorNameList.reset();
      this.authorsList = this.cachedAuthorList;
    }
  }

  isSelectedAuthor(id: string): boolean {
    if (this.selectedAuthorsArray.findIndex(entity => entity.id === id) >= 0) {
      return true;
    }
  }

  removeSelectedAuthor(id: string): void {
    this.selectedAuthorsArray = this.selectedAuthorsArray.filter(author => author.id !== id);
  }

  handleRunTime(value: any): void {
    const seconds = parseInt(value.value || 0, 10);
    if (seconds) {
      this.episodeForm.controls.run_time_minutes.clearValidators();
      this.episodeForm.controls.run_time_minutes.setValidators([Validators.min(0)]);
      this.runTimeMinutesMandatory = false;
    } else {
      this.episodeForm.controls.run_time_minutes.clearValidators();
      this.episodeForm.controls.run_time_minutes.setValidators([Validators.min(1)]);
      this.runTimeMinutesMandatory = true;
    }
    this.episodeForm.controls.run_time_minutes.updateValueAndValidity();
  }

  createUpdateEpisode(): void {
    if (this.episodeForm.valid) {
      const requestBody: IEpisodeCreate | IEpisodeUpdate = {
        product_sku: '',
        content_id: '',
        title: '',
        url: '',
        index: 0,
        published_on: '',
        run_time: 0,
        authors: [],
        description: ''
      };
      this.utilService.cleanForm(this.episodeForm);
      this.createRequestBody(requestBody);
      const episodeObserver = this.isCreation
        ? this.podcastEpisodeService.postEpisode(requestBody)
        : this.podcastEpisodeService.updateEpisode(requestBody, this.episodeId);

      episodeObserver
        .subscribe(
          () => {
            const result: IDialogResult = {isCreation: this.isCreation};
            this.addEditEpisodeDialog.close(result);
          },
          apiError => {
            console.error('Episode Add / Edit API error => ', JSON.stringify(apiError));
          });
    } else {
      this.toasterService.error(Constants.error.INVALID_FORM);
    }
  }

  createRequestBody(requestBody: any): void {
    requestBody.authors = this.selectedAuthorsArray.map(author => author.id);
    requestBody.run_time = this.utilService
      .runTimeToMilliSeconds(this.episodeForm.getRawValue().run_time_minutes, this.episodeForm.getRawValue().run_time_seconds);
    Object.entries(this.episodeForm.getRawValue()).forEach(([key, value]) => {
      if (requestBody.hasOwnProperty(key)) {
        // @ts-ignore
        if ([null, '', undefined].indexOf(value) >= 0) {
          if (this.isCreation) {
            delete requestBody[key];
          } else {
            requestBody[key] = '';
          }
        } else {
          requestBody[key] = key === 'published_on'
            ? this.publishedOn
            : JSON.parse(JSON.stringify(value));
        }
      }
    });
    if (this.isCreation) {
      requestBody.content_id = this.podcastId;
    } else {
      delete requestBody.content_id;
    }
  }

}
