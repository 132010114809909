import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Constants from '@constants';
import { IAPIQueryParameters } from '@sharedInterfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthorService {
  constructor(private http: HttpClient) {}

  postBulkAuthors(authorFile: FormData): Observable<any> {
    return this.http.put(`${Constants.getAPIURL.author()}`, authorFile);
  }

  postAuthor(authorData: FormData): Observable<any> {
    return this.http.post(`${Constants.getAPIURL.author()}`, authorData);
  }

  deleteAuthor(authorId: string): Observable<any> {
    return this.http.delete(`${Constants.getAPIURL.author(null, authorId)}`);
  }

  getAuthors(nextPageKey?: string, noFilters: boolean = false): Observable<any> {
    const queryParameters: IAPIQueryParameters[] = [{key: 'limit', value: Constants.tableOnePgRecords}];
    if (nextPageKey) {
      queryParameters.push({key: 'next_page', value: nextPageKey});
    }
    if (!noFilters) {
      const filterData = JSON.parse(localStorage.getItem(Constants.localStorageKeys.authorsFilters) || JSON.stringify({}));
      if (filterData && Object.keys(filterData).length) {
        if (filterData.show_on_quiz) {
          queryParameters.push({key: 'show_on_quiz', value: true});
        }
        if (filterData.with_contents && filterData.without_contents) {
          queryParameters.push({key: 'all', value: true});
        } else if (filterData.with_contents) {
          queryParameters.push({key: 'content', value: true});
        } else if (filterData.without_contents) {
          queryParameters.push({key: 'content', value: false});
        } else {
          queryParameters.push({key: 'all', value: true});
        }
      } else {
        queryParameters.push({key: 'all', value: true});
      }
    } else {
      queryParameters.push({key: 'all', value: true});
    }

    return this.http.get(`${Constants.getAPIURL.author(queryParameters)}`);
  }

  getAuthorById(authorId: string): Observable<any> {
    return this.http.get(`${Constants.getAPIURL.author(null, authorId)}`);
  }

  updateAuthor(authorData: FormData, authorId: string): Observable<any> {
    return this.http.put(`${Constants.getAPIURL.author(null, authorId)}`, authorData);
  }

  bulkDelete(authorIds: string[]): Observable<any> {
    return this.http.request('delete', `${Constants.getAPIURL.author()}`, {body: {ids: authorIds}});
  }

}
