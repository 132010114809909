import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Constants from '@constants';
import { IEpisodeCreate, IEpisodeUpdate } from '@content/podcast-episode/podcast-episode.interface';
import { IAPIQueryParameters } from '@sharedInterfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PodcastEpisodeService {

  constructor(
    private http: HttpClient
  ) {
  }

  postBulkEpisodes(episodeFile: FormData): Observable<any> {
    return this.http.put(`${Constants.getAPIURL.episode()}`, episodeFile);
  }

  postEpisode(episodeData: IEpisodeCreate): Observable<any> {
    return this.http.post(`${Constants.getAPIURL.episode()}`, episodeData);
  }

  getEpisodeById(episodeId: string): Observable<any> {
    return this.http.get(`${Constants.getAPIURL.episode(null, episodeId)}`);
  }

  getEpisodes(podcastId: string, nextPageKey?: string): Observable<any> {
    const queryParameters: IAPIQueryParameters[] = [
      {key: 'all', value: true},
      {key: 'pid', value: podcastId},
      {key: 'limit', value: Constants.tableOnePgRecords}
    ];
    if (nextPageKey) {
      queryParameters.push({key: 'next_page', value: nextPageKey});
    }

    return this.http.get(`${Constants.getAPIURL.episode(queryParameters)}`);
  }

  updateEpisode(episodeData: IEpisodeUpdate, episodeId: string): Observable<any> {
    return this.http.put(`${Constants.getAPIURL.episode(null, episodeId)}`, episodeData);
  }

  deleteEpisode(episodeId: string): Observable<any> {
    return this.http.delete(`${Constants.getAPIURL.episode(null, episodeId)}`);
  }

  bulkDelete(ids?: string[], podcastId?: string): Observable<any> {
    const requestBody: { ids?: string[], pid?: string } = {};
    if (ids) {
      requestBody.ids = ids;
    }
    if (podcastId) {
      requestBody.pid = podcastId;
    }

    return this.http.request('delete', `${Constants.getAPIURL.episode()}`, {body: requestBody});
  }
}
