import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IAuthor } from '@author/authors.interface';
import { AuthorService } from '@author/authors.service';
import Constants from '@constants';
import {
  ITrack,
  ITrackCreate,
  ITrackDialogData, ITrackUpdate
} from '@content/curated-collection-track/curated-collection-track.interface';
import { CuratedCollectionTrackService } from '@content/curated-collection-track/curated-collection-track.service';
import { IKlevuObject } from '@klevu/klevu.interface';
import { KlevuService } from '@klevu/klevu.service';
import { IDialogResult, IRunTime } from '@sharedInterfaces';
import { UtilService } from '@util/util.service';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-add-edit-track',
  templateUrl: './add-edit-track.component.html',
  styleUrls: ['./add-edit-track.component.scss']
})
export class AddEditTrackComponent implements OnInit {

  isCreation = true;
  curatedCollectionId = '';
  trackId = '';
  attributes: ITrackDialogData = {disApprovalButtonText: 'Close', approvalButtonText: 'Save', curatedCollectionId: ''};
  existingTrack: ITrack;
  trackForm: FormGroup;
  trackIndex;
  searchApplied = false;
  authorsList: IKlevuObject[] | IAuthor[] = [];
  cachedAuthorList: IAuthor[];
  defaultDateFormat = Constants.dateDefaultFormat;
  selectedAuthorsArray: { id: string, name: string }[] = [];
  runTimeMinutesMandatory = true;
  runTimeMinutes = 0;
  runTimeSeconds = 0;
  dayNumber = 0;
  validFrom = '';
  validTill = '';
  listenFreeStart = '';
  listenFreeEnd = '';

  constructor(
    private authorService: AuthorService,
    private formBuilder: FormBuilder,
    private klevuService: KlevuService,
    private toasterService: ToastrService,
    private utilService: UtilService,
    private curatedCollectionTrackService: CuratedCollectionTrackService,
    public addEditTrackDialog: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) data: ITrackDialogData
  ) {

    if (data && Object.keys(data).length) {
      this.attributes.approvalButtonText = data.approvalButtonText;
      this.attributes.disApprovalButtonText = data.disApprovalButtonText;

      if (data.curatedCollectionId) {
        this.curatedCollectionId = data.curatedCollectionId;
      } else {
        console.error('No curated collection id provided');
      }

      if (data.track && Object.keys(data.track)) {
        this.existingTrack = data.track;
        this.trackIndex = Number(data.track.index);
        this.dayNumber = Number(data.track.day_number);
        this.isCreation = false;
        this.trackId = data.track.id;
        this.selectedAuthorsArray = data.track.guest_info || [];
        this.attributes.approvalButtonText = 'Update Track';
        const runTime: IRunTime = this.utilService.milliSecondsToMinutesSeconds(data.track.run_time);
        this.runTimeMinutes = runTime.minutes;
        this.runTimeSeconds = runTime.seconds;
        this.validFrom = this.utilService.formatDateRequest(data.track.valid_from);
        this.validTill = this.utilService.formatDateRequest(data.track.valid_till);
        this.listenFreeStart = this.utilService.formatDatetimeRequest(data.track.track_listen_free_start);
        this.listenFreeEnd = this.utilService.formatDatetimeRequest(data.track.track_listen_free_end);
        if (this.runTimeSeconds) {
          this.runTimeMinutesMandatory = false;
        }
      } else if (data.trackIndex) {
        this.trackIndex = data.trackIndex;
      }
    }

    this.createForm();
    this.trackForm
      .controls
      .searchAuthor
      .valueChanges
      .pipe(debounceTime(Constants.klevuDeBounceTime))
      .subscribe(term => {
        if (term) {
          this.klevuService
            .searchKlevu(term, 'author')
            .subscribe(klevuResult => {
              this.searchApplied = true;
              this.authorsList = klevuResult.result;
            });
        } else if (this.searchApplied) {
          this.authorsList = this.cachedAuthorList;
          this.searchApplied = false;
        }
      });
  }

  ngOnInit(): void {
    this.getAuthors();
  }

  createForm(): void {
    this.trackForm = this.formBuilder.group({
      title: [this.existingTrack ? this.existingTrack.title : ''],
      url: [this.existingTrack ? this.existingTrack.url : ''],
      product_sku: [this.existingTrack ? this.existingTrack.product_sku : ''],
      index: [this.trackIndex || ''],
      day_number: [this.existingTrack && this.existingTrack.day_number ? this.existingTrack.day_number : ''],
      run_time_minutes: [this.runTimeMinutes || 0, Validators.min(this.runTimeMinutesMandatory ? 1 : 0)],
      run_time_seconds: [
        this.runTimeSeconds || 0,
        [Validators.min(0), Validators.max(59)]
      ],
      // tslint:disable-next-line:max-line-length
      valid_from: [this.existingTrack && this.existingTrack.valid_from ? this.utilService.formatDateRequest(this.existingTrack.valid_from) : ''],
      // tslint:disable-next-line:max-line-length
      valid_till: [this.existingTrack && this.existingTrack.valid_till ? this.utilService.formatDateRequest(this.existingTrack.valid_till) : ''],
      // tslint:disable-next-line:max-line-length
      listen_free_start: [this.existingTrack && this.existingTrack.track_listen_free_start ? this.utilService.formatDatetimeRequest(this.existingTrack.track_listen_free_start) : ''],
      // tslint:disable-next-line:max-line-length
      listen_free_end: [this.existingTrack && this.existingTrack.track_listen_free_end ? this.utilService.formatDatetimeRequest(this.existingTrack.track_listen_free_end) : ''],
      authorNameList: [''],
      searchAuthor: [''],
      description: [this.existingTrack && this.existingTrack.description || ''],
    });
  }

  setValidTill(event?: any): void {
    const validTillDatePicker = this.trackForm.get('valid_till');
    validTillDatePicker.enable();
    if (event && new Date(this.validFrom) > new Date(this.validTill)) {
      this.validTill = '';
    }
    if (!event && !this.existingTrack) {
      validTillDatePicker.reset();
      validTillDatePicker.disable();
    }
  }

  getAuthors(): void {
    this.authorService.getAuthors(null, true).subscribe((authorsList: {
      results: IAuthor[], next_page_key: string
    }) => {
      this.authorsList = JSON.parse(JSON.stringify(authorsList.results));
      this.cachedAuthorList = JSON.parse(JSON.stringify(authorsList.results));
    });
  }

  openCloseAuthorNameList(opened: boolean): void {
    if (!opened) {
      this.trackForm.controls.searchAuthor.reset();
      this.trackForm.controls.authorNameList.reset();
      this.authorsList = this.cachedAuthorList;
    }
  }

  selectAuthor(selectedValue: { id: string, name: string }): void {
    if (this.selectedAuthorsArray.findIndex(author => author.id === selectedValue.id) < 0) {
      this.selectedAuthorsArray.push(selectedValue);
      this.trackForm.controls.searchAuthor.reset();
      this.trackForm.controls.authorNameList.reset();
      this.authorsList = this.cachedAuthorList;
    }
  }

  isSelectedAuthor(id: string): boolean {
    if (this.selectedAuthorsArray.findIndex(entity => entity.id === id) >= 0) {
      return true;
    }
  }

  removeSelectedAuthor(id: string): void {
    this.selectedAuthorsArray = this.selectedAuthorsArray.filter(author => author.id !== id);
  }

  handleRunTime(value: any): void {
    const seconds = parseInt(value.value || 0, 10);
    if (seconds) {
      this.trackForm.controls.run_time_minutes.clearValidators();
      this.trackForm.controls.run_time_minutes.setValidators([Validators.min(0)]);
      this.runTimeMinutesMandatory = false;
    } else {
      this.trackForm.controls.run_time_minutes.clearValidators();
      this.trackForm.controls.run_time_minutes.setValidators([Validators.min(1)]);
      this.runTimeMinutesMandatory = true;
    }
    this.trackForm.controls.run_time_minutes.updateValueAndValidity();
  }

  createUpdateTrack(): void {
    if (this.trackForm.valid) {
      const requestBody: ITrackCreate | ITrackUpdate = {
        product_sku: '',
        content_id: '',
        title: '',
        url: '',
        index: 0,
        day_number: 0,
        run_time: 0,
        authors: [],
        description: '',
        valid_from: '',
        valid_till: '',
        listen_free_start: '',
        listen_free_end: ''
      };
      this.utilService.cleanForm(this.trackForm);
      this.createRequestBody(requestBody);
      const trackObserver = this.isCreation
        ? this.curatedCollectionTrackService.postTrack(requestBody)
        : this.curatedCollectionTrackService.updateTrack(requestBody, this.trackId);

      trackObserver
        .subscribe(
          () => {
            const result: IDialogResult = {isCreation: this.isCreation};
            this.addEditTrackDialog.close(result);
          },
          apiError => {
            console.error('Track Add / Edit API error => ', JSON.stringify(apiError));
          });
    } else {
      this.toasterService.error(Constants.error.INVALID_FORM);
    }
  }

  createRequestBody(requestBody: any): void {
    requestBody.authors = this.selectedAuthorsArray.map(author => author.id);
    requestBody.run_time = this.utilService
      .runTimeToMilliSeconds(this.trackForm.getRawValue().run_time_minutes, this.trackForm.getRawValue().run_time_seconds);
    Object.entries(this.trackForm.getRawValue()).forEach(([key, value]) => {
      if (requestBody.hasOwnProperty(key)) {
        // @ts-ignore
        if ([null, '', undefined].indexOf(value) >= 0) {
          if (this.isCreation) {
            delete requestBody[key];
          } else {
            requestBody[key] = '';
          }
        } else {
          requestBody[key] = key === 'valid_from' ? this.validFrom
            : requestBody[key] = key === 'valid_till' ? this.validTill
              : requestBody[key] = key === 'listen_free_start' ? this.listenFreeStart
                : requestBody[key] = key === 'listen_free_end' ? this.listenFreeEnd
                  : JSON.parse(JSON.stringify(value));
        }
      }
    });
    if (this.isCreation) {
      requestBody.content_id = this.curatedCollectionId;
    } else {
      delete requestBody.content_id;
    }
  }

}
