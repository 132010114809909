import { IEnvironment } from '@env/interfaces/environment.interface';

export const environment: IEnvironment = {
  production: false,
  awsRegion: 'us-west-2',
  awsUserPoolId: 'us-west-2_Awwu6BqRo',
  awsUserPoolWebClientId: '7lpiuke2ljkhsvqjp4vfpsukn6',
  awsUserPoolAdminGroupName: 'Admin',
  hayhouseBaseUrl: 'https://audio-app-api.staging.hayhouse.com/api/v2',
  backendEnvironment: 'staging',
  klevuTicketId: 'klevu-156806971732210639',
  klevuBaseUrl: 'https://eucs15.ksearchnet.com/cloud-search/n-search/search'
};
