import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { NavigationExtras, Router } from '@angular/router';
import Constants from '@constants';
import { ContentType } from '@content/content.enum';
import { IPagination, ITableDisplayedColumns } from '@sharedInterfaces';
import { TDialogType, TEntityType } from '@sharedTypes';
import { UtilService } from '@util/util.service';

@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss']
})

export class TableViewComponent implements OnInit, OnChanges {
  @Input() tableData;
  @Input() columnsToDisplay: ITableDisplayedColumns[];
  @Input() nextPageKey;
  @Input() showSelect: boolean;
  @Input() showMoreOptions: boolean;
  @Input() deleteOptionConditionEnable: boolean;
  @Input() showDeleteOptionConditionKey: string;
  @Input() showDeleteColumn: boolean;
  @Input() showEditColumn: boolean;
  @Input() showPagination: boolean;
  @Input() clickViewEnable: boolean;
  @Input() showNoData: boolean;
  @Input() currentPage: number;
  @Input() entity: TEntityType;
  @Input() showFilters: boolean;
  @Input() isFilterApplied: boolean;
  @Input() totalCount: number;
  @Input() isDynamoPagination: boolean;
  @Input() isKlevuData: boolean;

  @Output() openEditDialog: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() filterDialog: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() openConfirmationDialog: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() openPage: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() checkBoxClicked: EventEmitter<{}> = new EventEmitter<{}>();

  displayedColumns: any[];
  selection = new SelectionModel(true, []);
  startCount: number;
  endCount: number;
  pagination = '';

  constructor(
    private router: Router,
    public utilService: UtilService
  ) {
  }

  ngOnInit(): void {
    this.displayedColumns = this.columnsToDisplay.map(x => x.key);

    if (this.showSelect) {
      this.displayedColumns = ['select', ...this.displayedColumns];
    }
    if (this.showMoreOptions) {
      this.displayedColumns = [...this.displayedColumns, 'moreOptions'];
    }
    if (this.showDeleteColumn) {
      this.displayedColumns = [...this.displayedColumns, 'delete'];
    }
    if (this.showEditColumn) {
      this.displayedColumns = [...this.displayedColumns, 'edit'];
    }
    this.pagination = this.entity + ' - pagination';
    if (localStorage.getItem(this.pagination) === null && this.showPagination) {
      localStorage.setItem(this.pagination, JSON.stringify([]));
    }
    switch (this.entity) {
      case 'author':
        this.isFilterApplied = localStorage.getItem(Constants.localStorageKeys.authorsFilters) !== null;
        break;
      case 'content':
        this.isFilterApplied = localStorage.getItem(Constants.localStorageKeys.contentFilters) !== null;
        break;
      case 'topic':
        this.isFilterApplied = localStorage.getItem(Constants.localStorageKeys.topicFilters) !== null;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.nextPageKey) {
      this.nextPageKey = changes.nextPageKey.currentValue;
    }
    if (changes && changes.tableData) {
      this.selection.clear();
      if (this.tableData.length) {
        this.startCount = (Constants.tableOnePgRecords * this.currentPage) - (Constants.tableOnePgRecords - 1);
        this.endCount = this.startCount + this.tableData.length - 1;
      } else {
        this.startCount = 0;
        this.endCount = 0;
      }
    }
    if (changes && changes.currentPage) {
      if (this.currentPage === 1) {
        localStorage.removeItem(this.pagination);
      }
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected: number = this.selection.selected.length;
    const numRows: number = this.tableData.length;

    return numSelected === numRows;
  }

  /** Selects all rows if none of all rows selected; otherwise clear selection. */
  masterToggle(masterCheckBox: any): void {
    if (this.selection.hasValue()) {
      this.selection.clear();
      masterCheckBox.checked = false;
    } else {
      this.tableData.forEach(row => this.selection.select(row));
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }

  /** String to be passed for multiple items in an array */
  stringifyArray(input: any): string {
    if (!input) {
      return input;
    }
    let value: string | any[] = '';
    try {
      value = JSON.parse(JSON.stringify(input));
    } catch (exception) {
      console.error('exception in stringifyArray => ', exception);
    }
    if (Array.isArray(value)) {
      return value.filter(item => item).join(', ');
    }

    return typeof value === 'string' ? value.replace(/(<([^>]+)>)/ig, '') : value;
  }

  /** Emit an event to open dialog to edit / delete item. Item to be passed as prop */
  openDialog(row: any, type: TDialogType): void {
    const data: {} = { ...row};
    if (data) {
      if (type === 'edit') {
        this.openEditDialog.emit(data);
      } else if (type === 'delete') {
        this.openConfirmationDialog.emit(data);
      }
    }
  }

  openNextPage(): void {
    if (this.isDynamoPagination) {
      if (this.nextPageKey) {
        const pageIds: any[] = JSON.parse(localStorage.getItem(this.pagination) || JSON.stringify([]));
        const index: number = pageIds.findIndex(pageId => pageId.pageId === (this.currentPage + 1));
        if (index < 0) {
          pageIds.push({pageId: this.currentPage + 1, pageKey: this.nextPageKey});
          localStorage.setItem(this.pagination, JSON.stringify(pageIds));
        }
        const pagination: IPagination = {pageKey: this.nextPageKey};
        this.openPage.emit(pagination);
      }
    } else {
      const pagination: IPagination = {isPrevious: false};
      this.openPage.emit(pagination);
    }
  }

  openPreviousPage(): void {
    if (this.isDynamoPagination) {
      const pageIds: any[] = JSON.parse(localStorage.getItem(this.pagination) || JSON.stringify([]));
      const index: number = pageIds.findIndex(pageId => pageId.pageId === (this.currentPage - 1));
      let previousPageKey = '';
      if (index >= 0) {
        previousPageKey = pageIds[index].pageKey;
      }
      const pagination: IPagination = {pageKey: previousPageKey || '', isPrevious: true};
      this.openPage.emit(pagination);
    } else {
      const pagination: IPagination = {isPrevious: true};
      this.openPage.emit(pagination);
    }
  }

  navigateTo(row: any): void {
    const navigationExtras: NavigationExtras = {
      ...(!this.isKlevuData) && {state: {...row}},
      queryParams: {
        id: row.id,
        ...(row.content_type === ContentType.Episode || row.type === ContentType.Episode) && {episode: true}
      }
    };
    switch (this.entity) {
      case 'content':
      case 'episode':
        this.router.navigate([`/viewContent`], navigationExtras);
        break;
      case 'author':
        this.router.navigate([`/viewAuthor`], navigationExtras);
        break;
      case 'topic':
        this.router.navigate([`/viewTopic`], navigationExtras);
        break;
    }
  }

  sortData(sort: Sort): void {
    const isAsc = sort.direction === 'asc';
    this.tableData = [...this.tableData.sort((a, b) =>
      this.utilService.compare(a[sort.active], b[sort.active], isAsc))
    ];
  }

  checkBoxEvent(): void {
    this.checkBoxClicked.emit((this.selection.selected || []).map(entity =>
      ({
        id: entity.id,
        ...entity.content_type && { content_type: entity.content_type}
      })));
  }

  openFilterDialog(): void {
    this.filterDialog.emit();
  }

}
