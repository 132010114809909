import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import Constants from '@constants';
import { IKlevuResult } from '@klevu/klevu.interface';
import { KlevuService } from '@klevu/klevu.service';
import { IPagination, IRouteQueryParameters, ITableDisplayedColumns } from '@sharedInterfaces';
import { TEntityType } from '@sharedTypes';

@Component({
  selector: 'app-klevu',
  templateUrl: './klevu.component.html',
  styleUrls: ['./klevu.component.scss']
})
export class KlevuComponent implements OnInit {

  searchTerm = '';
  entity: TEntityType;
  contentId: string;
  klevuResult: IKlevuResult = {moreResults: false, result: [], totalResults: 0};
  displayedColumns: ITableDisplayedColumns[] = [
    {key: 'img_url', field: 'Image', showImage: true},
    {key: 'name', field: 'Name'},
    {key: 'id', field: 'ID'},
  ];
  currentPageNo: number;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private klevuService: KlevuService,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.searchTerm = queryParams.search;
      this.entity = queryParams.entity;
      this.contentId = queryParams.contentId || '';
      this.currentPageNo = Number(queryParams.pageNo || 1);
      if (!this.searchTerm) {
        this.router.navigate(['']);
      }
      this.authService.getCurrentUserAccessToken().subscribe(() => {
        // this is to show side nav on refresh search screen
        this.getKlevuData();
      });
    });
  }

  getKlevuData(): void {
    const paginationStartsFrom = (this.currentPageNo - 1) * Constants.tableOnePgRecords;
    this.klevuService
      .searchKlevu(this.searchTerm, this.entity, paginationStartsFrom, this.contentId)
      .subscribe(
        klevuResult => {
          this.klevuResult = klevuResult;
        },
        error => console.error('Klevu list error => ', error),
      );
  }

  openPage(pageData?: IPagination): void {
    const queryParams: IRouteQueryParameters = {
      pageNo: pageData.isPrevious ? this.currentPageNo - 1 : Number(this.currentPageNo) + 1,
      search: this.searchTerm
    };

    this.router
      .navigate(
        [], {
          relativeTo: this.activatedRoute,
          queryParams,
          queryParamsHandling: 'merge'
        }
      );
  }

}
