import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';

import { environment } from '@env/environment';
import { AppModule } from './app/app.module';

import Amplify from 'aws-amplify';

Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: environment.awsRegion,
    userPoolId: environment.awsUserPoolId,
    userPoolWebClientId: environment.awsUserPoolWebClientId
  }
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
