import { Component, Injectable, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatBottomSheet, MatDialog } from '@angular/material';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import Constants from '@constants';
import { ContentType } from '@content/content.enum';
import { IContent } from '@content/content.interface';
import { ContentService } from '@content/content.service';
import { AddEditTrackComponent } from '@content/curated-collection-track/add-edit-track/add-edit-track.component';
import { ITrack, ITrackDialogData } from '@content/curated-collection-track/curated-collection-track.interface';
import { CuratedCollectionTrackService } from '@content/curated-collection-track/curated-collection-track.service';
import { IKlevuResult } from '@klevu/klevu.interface';
import { KlevuService } from '@klevu/klevu.service';
import {
  IDialogData,
  IDialogResult,
  IMultiSelectContent,
  IPagination,
  IRouteQueryParameters,
  ITableDisplayedColumns
} from '@sharedInterfaces';
import { ConfirmationComponent } from '@util/confirmation/confirmation.component';
import { DownloadErrorComponent } from '@util/download-error/download-error.component';
import { IDownloadErrorDialogData } from '@util/download-error/download-error.interface';
import { UploadDialogComponent } from '@util/upload-dialog/upload-dialog.component';
import { UtilService } from '@util/util.service';
import * as httpStatus from 'http-status';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-tracks-list',
  templateUrl: './tracks-list.component.html',
  styleUrls: ['./tracks-list.component.scss']
})
export class TracksListComponent implements OnInit {

  displayedColumns: ITableDisplayedColumns[] = [
    {key: 'title', field: 'Title'},
    {key: 'url', field: 'URL'},
    {key: 'day_number', field: 'Day Number'},
    {key: 'index', field: 'Index'},
    {key: 'run_time_string', field: 'Runtime'},
    {key: 'product_sku', field: 'SKU'}
  ];
  curatedCollectionInfoFetch: Promise<boolean>;
  currentPageNo: number;
  currentPageKey: string;
  nextPageKey: string;
  curatedCollectionId: string;
  tracks: ITrack[] = [];
  curatedCollectionInfo: IContent;
  selectedTracks: IMultiSelectContent[];
  showBulkDeleteButton: Subject<boolean> = new Subject<boolean>();
  searchBoxField = new FormControl();
  klevuResult: IKlevuResult = {moreResults: false, result: [], totalResults: 0};
  noKlevuResult: Subject<boolean> = new Subject<boolean>();
  searchTerm = '';
  nameCheck = false; // this is for bulk upload feature to upload csv which contain names instead of ids

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private curatedCollectionTrackService: CuratedCollectionTrackService,
    private contentService: ContentService,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private dialog: MatDialog,
    private klevuService: KlevuService,
    private bottomSheet: MatBottomSheet,
  ) {
    this.curatedCollectionInfo = this.router.getCurrentNavigation().extras.state as IContent;
    this.searchBoxField
      .valueChanges
      .pipe(debounceTime(Constants.klevuDeBounceTime))
      .subscribe(term => {
        this.noKlevuResult.next(false);
        this.searchTerm = term;
        this.fillSearchBox(term);
      });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.currentPageNo = Number(queryParams.pageNo || 1);
      this.currentPageKey = queryParams.pageKey || '';
      this.curatedCollectionId = queryParams.id;
      this.nameCheck = queryParams.nameCheck || false;
      if (!this.curatedCollectionId) {
        this.navigateToViewContentScreen();
      } else {
        this.getCuratedCollectionTracks();
      }
      if (!this.curatedCollectionInfo) {
        this.getCuratedCollectionDetails().subscribe(result => this.curatedCollectionInfoFetch = Promise.resolve(result));
      } else {
        this.curatedCollectionInfoFetch = Promise.resolve(true);
      }
      this.router.events.subscribe((event: NavigationEnd) => {
        window.scroll(0, 0);
      });
    });
  }

  fillSearchBox(term: string): void {
    if (term) {
      this.klevuService
        .searchKlevu(term, 'track', 0, this.curatedCollectionId)
        .subscribe(
          klevuResult => {
            this.klevuResult = klevuResult;
            if (!this.klevuResult || !this.klevuResult.result || !this.klevuResult.result.length) {
              this.noKlevuResult.next(true);
            }
          },
          error => console.error('Klevu track error => ', error),
          () => this.noKlevuResult.next(true)
        );
    } else {
      this.resetSearchBox();
    }
  }

  navigateToViewTrack(track: MatAutocompleteSelectedEvent): void {
    if (track.option.value && track.option.value.id) {
      const trackId = track.option.value.id;
      this.showBulkDeleteButton.next(false);
      this.router.navigate([`/viewContent`], {
        queryParams: {
          id: trackId,
          returnQueryParameters: JSON.stringify({pageNo: this.currentPageNo, pageKey: this.currentPageKey}),
          track: true
        }
      });
    }
    this.resetSearchBox();
  }

  openKlevuSearchList(): void {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        search: this.searchTerm,
        pageNo: 1,
        entity: 'track',
        contentId: this.curatedCollectionId
      }
    };
    this.router.navigate(['/searchList'], navigationExtras);
  }

  getCuratedCollectionTracks(): void {
    this.curatedCollectionTrackService
      .getTracks(this.curatedCollectionId, this.currentPageKey)
      .subscribe(
        (tracks: { results: ITrack[], next_page_key: string }) => {
          this.tracks = tracks.results.map(
            track =>
              ({
                ...track,
                run_time_string: this.utilService.getRunTimeString(track.run_time)
              }));
          this.nextPageKey = tracks.next_page_key;
        },
        error => {
          // if there is any error then redirect to previous screen
          if (error) {
            this.navigateToViewContentScreen();
          }
        }
      );
  }

  getCuratedCollectionDetails(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.contentService
        .getContentById(this.curatedCollectionId)
        .subscribe(
          (curatedCollection: IContent) => {
            if (curatedCollection.content_type !== ContentType['Curated Collection']) {
              this.navigateToViewContentScreen();
            } else {
              this.curatedCollectionInfo = curatedCollection;

              return observer.next(true);
            }
          },
          error => {
            // if there no curated collection id then redirect to previous screen
            if (error) {
              this.navigateToViewContentScreen();
            }
          });
    });
  }

  openTrackDialog(existingTrack?: ITrack): void {
    const data: ITrackDialogData = {
      approvalButtonText: 'Add Track',
      disApprovalButtonText: 'Cancel',
      curatedCollectionId: this.curatedCollectionId
    };
    if (existingTrack) {
      data.track = existingTrack;
    }
    const trackDialog = this.dialog.open(AddEditTrackComponent, {
      ...Constants.AddEditTrackDialogConfig,
      data
    });
    trackDialog.afterClosed().subscribe((result: IDialogResult) => {
      if (result) {
        if (this.currentPageNo === 1 || !result.isCreation) {
          this.getCuratedCollectionTracks();
          this.getCuratedCollectionDetails().subscribe();
        } else {
          this.openPage({}, 1);
        }
      }
    });
  }

  openUploadDialog(): void {
    const data: IDialogData = {titleText: `Upload Bulk Tracks`};
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      ...Constants.UploadDialogConfig,
      data
    });
    dialogRef.afterClosed().subscribe(file => {
      if (file) {
        const request = {
          file,
          nameCheck: this.nameCheck,
          content_id: this.curatedCollectionId
        };
        const formData = this.utilService.objectToFormData(request);
        this.curatedCollectionTrackService
          .postBulkTracks(formData)
          .subscribe(
            () => {
              this.refreshPage();
            },
            (error) => {
              if (error && error.value && error.value.error && error.value.status === httpStatus.EXPECTATION_FAILED) {
                const downloadError: IDownloadErrorDialogData = {
                  error: error.value.error || [],
                  fileName: file.name.split('.csv')[0]
                };
                this.bottomSheet.open(DownloadErrorComponent, {disableClose: true, data: downloadError});
              }
            });
      }
    });
  }

  openPage(pageData?: IPagination, forcePageNumber?: number): void {
    const queryParams: IRouteQueryParameters = {
      pageNo: pageData.isPrevious ? this.currentPageNo - 1 : Number(this.currentPageNo) + 1,
      pageKey: pageData.pageKey || ''
    };
    if (forcePageNumber) {
      queryParams.pageNo = forcePageNumber;
    }
    this.router
      .navigate(
        [], {
          relativeTo: this.activatedRoute,
          queryParams,
          queryParamsHandling: 'merge'
        }
      );
  }

  showBulkOption(data: IMultiSelectContent[]): void {
    if (data && data.length) {
      this.selectedTracks = data;
      this.showBulkDeleteButton.next(true);
    } else {
      this.showBulkDeleteButton.next(false);
    }
  }

  openDeleteDialog(trackInfo: { id: string }): void {
    const config = {
      ...Constants.confirmationDialogConfig,
      data: Constants.TrackSingleDeleteDialogData
    };
    const deleteDialog = this.dialog.open(ConfirmationComponent, config);
    deleteDialog.afterClosed().subscribe((approval: boolean) => {
      if (approval) {
        this.curatedCollectionTrackService.deleteTrack(trackInfo.id).subscribe(result => {
          this.refreshPage();
        });
      }
    });
  }

  deleteAllTracks(): void {
    const data: IDialogData = {
      titleText: 'Are you sure you want to delete all tracks for this curated collection?',
      approvalButtonText: 'Delete',
      disApprovalButtonText: 'Cancel'
    };
    const config = {
      ...Constants.confirmationDialogConfig,
      data
    };
    const deleteDialog = this.dialog.open(ConfirmationComponent, config);
    deleteDialog.afterClosed().subscribe((approval: boolean) => {
      if (approval) {
        this.curatedCollectionTrackService.bulkDelete(null, this.curatedCollectionId).subscribe(result => {
          this.refreshPage();
        });
      }
    });
  }

  bulkDelete(): void {
    if (this.selectedTracks && this.selectedTracks.length) {
      const data: IDialogData = {
        titleText: 'Are you sure you want to delete multiple tracks?',
        approvalButtonText: 'Delete',
        disApprovalButtonText: 'Cancel'
      };
      const bulkDeleteConfirmation = this.dialog.open(ConfirmationComponent, {
        ...Constants.confirmationDialogConfig,
        data
      });
      bulkDeleteConfirmation.afterClosed().subscribe(approval => {
        if (approval) {
          this.curatedCollectionTrackService
            .bulkDelete(this.selectedTracks.map(track => track.id))
            .subscribe(() => {
              this.refreshPage();
            });
        }
      });
    }
  }

  navigateToViewContentScreen(): void {
    this.toastrService.error('Invalid Curated Collection to list tracks');
    this.router.navigate(['/viewContent'], {
      queryParams: {
        id: this.curatedCollectionId
      }
    });
  }

  refreshPage(): void {
    if (this.currentPageNo === 1) {
      this.getCuratedCollectionTracks();
      this.getCuratedCollectionDetails().subscribe();
    } else {
      this.openPage({}, 1);
    }
    this.showBulkDeleteButton.next(false);
  }

  resetSearchBox(): void {
    this.klevuResult = {moreResults: false, result: [], totalResults: 0};
  }

}
