import { Component, OnInit } from '@angular/core';
import { ICategory } from '@category/categories.interface';
import { CategoryService } from '@category/categories.service';
import { ITableDisplayedColumns } from '@sharedInterfaces';
import { UtilService } from '@util/util.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  displayedColumns: ITableDisplayedColumns[] = [
    { key: 'name', field: 'Category Name' },
    { key: 'id', field: 'Category ID' },
    { key: 'index', field: 'Index' },
    { key: 'img_url', field: 'Image URL' }];

  categories: ICategory[] = [];

  constructor(
    public categoryService: CategoryService,
    private utilService: UtilService
  ) { }

  ngOnInit(): void {
    this.categoryService.getCategories().subscribe((categoryList: ICategory[]) => {
      this.categories = this.utilService.sortArrayOfObjects(categoryList, 'index');
    });
  }

}
