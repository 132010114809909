import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss']
})
export class UploadButtonComponent implements OnInit {
  @Output() fileUpload: EventEmitter<{}> = new EventEmitter<{}>();
  @Input() uploadButtonText: string;
  @Input() acceptedFormats: string[];
  @Input() hideImageName: boolean;
  validFormats = '';
  currentFileName = '';
  constructor() { }

  ngOnInit(): void {
    if (!this.uploadButtonText) {
      this.uploadButtonText = 'Upload';
    }
    if (!this.acceptedFormats || !this.acceptedFormats.length) {
      this.acceptedFormats = [];
    }
    this.acceptedFormats = this.acceptedFormats.map(format => `.${format}`);
    this.validFormats = this.acceptedFormats.join(',');
  }

  onChange(event: any): void {
    if (event
      && event.target
      && event.target.files
      && event.target.files[0]) {
      this.currentFileName = event.target.files[0].name;
      this.fileUpload.emit(event.target.files[0]);
      event.target.value = '';
    }
  }

}
