import { HttpClient } from '@angular/common/http';
import { Component, Injectable, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import Constants from '@constants';
import { IDialogData } from '@sharedInterfaces';
import { ConfirmationComponent } from '@util/confirmation/confirmation.component';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  isAuthenticated: Subject<boolean>;

  constructor(
    public authService: AuthService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private router: Router,
    private http: HttpClient
  ) {
    this.isAuthenticated = this.authService.loggedIn;
  }

  ngOnInit(): void {
  }

  openConfirmationDialog(): void {
    const data: IDialogData = {
      titleText: 'Are you sure you want to logout?',
      approvalButtonText: 'Logout',
      disApprovalButtonText: 'Cancel'
    };
    const logoutConfirmation: MatDialogRef<any> = this.dialog.open(ConfirmationComponent, {
      ...Constants.confirmationDialogConfig,
      data
    });
    logoutConfirmation.afterClosed().subscribe(async approval => {
      try {
        if (approval) {
          await this.authService.signOut();
        }
      } catch (error) {
        console.error('error in logout', error);
        this.router.navigate(['/login']);
        this.toastr.error(error.message || 'Error in logout. Please try again.', 'Error : ');
      }
    });
  }

  openClearCacheConfirmationDialog(): void {
    const data: IDialogData = {
      titleText: 'Are you sure you want to clear the cache?',
      approvalButtonText: 'Confirm',
      disApprovalButtonText: 'Cancel'
    };
    const clearCacheConfirmation: MatDialogRef<any> = this.dialog.open(ConfirmationComponent, {
      ...Constants.confirmationDialogConfig,
      data
    });
    clearCacheConfirmation.afterClosed().subscribe(async approval => {
      try {
        if (approval) {
          this.http.get(`${Constants.getAPIURL.clearCache()}`).subscribe();
        }
      } catch (error) {
        console.error('error in clearing cache', error);
        this.toastr.error(error.message || 'Error when clearing cache. Please try again.', 'Error : ');
      }
    });
  }
}
