import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { CognitoUser } from '@aws-amplify/auth';
import { environment } from '@env/environment';
import { LoaderService } from '@util/loader/loader.service';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  public loggedIn = new Subject<boolean>();
  public user: CognitoUser;

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private matDialogRef: MatDialog
  ) {
  }

  signIn(email: string, password: string): Promise<CognitoUser | any> {
    this.loaderService.show();

    return new Promise((resolve, reject) => {
      Auth.signIn(email, password)
        .then((user: CognitoUser | any) => {
          const isAdmin: boolean = this.checkAdmin(user);
          if (isAdmin) {
            this.user = user;
            this.loggedIn.next(true);

            return resolve(user);
          }
          this.signOut();

          return reject(new Error('Not a valid admin'));
        })
        .catch((error: any) => reject(error))
        .finally(() => this.loaderService.hide());
    });
  }

  async signOut(): Promise<void> {
    this.handleLogout();
    await Auth.signOut({global: true});
  }

  getCurrentUserAccessToken(): Observable<string> {
    return new Observable<string>(observer => {
      this.loaderService.show();
      Auth
        .currentAuthenticatedUser({bypassCache: true})
        .then((userData: CognitoUser) => {
          if (userData
            && userData.getSignInUserSession()
            && userData.getSignInUserSession().getAccessToken()
            && userData.getSignInUserSession().getAccessToken().getJwtToken()
            && this.checkAdmin(userData)) {
            // USER SESSION EXIST
            this.loggedIn.next(true);

            return observer.next(userData.getSignInUserSession().getAccessToken().getJwtToken());
          }
          // USER SESSION NOT EXIST
          this.signOut();

        })
        .catch(error => {
          console.log('currentUser error => ', error);
          this.router.navigate(['/login']);
        });
    });
  }

  handleLogout(): void {
    this.loggedIn.next(false);
    this.user = null;
    localStorage.clear();
    this.matDialogRef.closeAll();
    this.loaderService.hide();
    this.router.navigate(['/login']);
  }

  checkAdmin(user: CognitoUser | CognitoUserSession): boolean {
    let userPayload = {};
    if (user instanceof CognitoUser) {
      userPayload = user.getSignInUserSession().getAccessToken().payload;
    } else if (user instanceof CognitoUserSession) {
      userPayload = user.getAccessToken().payload;
    }

    // tslint:disable-next-line:max-line-length
    return userPayload && userPayload['cognito:groups'] && userPayload['cognito:groups'].length && userPayload['cognito:groups'].includes(environment.awsUserPoolAdminGroupName);
  }

}
