import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ICategory } from '@category/categories.interface';
import { CategoryService } from '@category/categories.service';
import Constants from '@constants';
import { IFilterDialogData, IFilterHTML } from '@sharedInterfaces';
import { UtilService } from '@util/util.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-filter-topics',
  templateUrl: './filter-topics.component.html',
  styleUrls: ['./filter-topics.component.scss']
})
export class FilterTopicsComponent implements OnInit {

  filterForm: FormGroup;
  topicFilters: IFilterHTML[] = [
    {view: 'Custom Topic', value: 'custom_topic'},
    {view: 'Quiz Topic', value: 'quiz_topic'}
  ];
  isFilterApplied: Subject<boolean> = new Subject<boolean>();
  selectedCategoryArray: { id: string, name: string }[] = [];
  categories: ICategory[];
  filterData: any;

  constructor(
    private formBuilder: FormBuilder,
    public filtersDialog: MatDialogRef<any>,
    private utilService: UtilService,
    private toasterService: ToastrService,
    private categoryService: CategoryService,
    @Inject(MAT_DIALOG_DATA) data: IFilterDialogData
  ) {
    if (data) {
      this.isFilterApplied = data.isFilterApplied;
    }
  }

  ngOnInit(): void {
    this.filterData = JSON.parse(localStorage.getItem(Constants.localStorageKeys.topicFilters) || JSON.stringify({}));
    this.createForm();
    this.selectedCategoryArray = (this.filterData.categories || []);
    this.categoryService.getCategories().subscribe((categoryList: ICategory[]) => {
      this.categories = categoryList;
    });
  }

  createForm(): void {
    const filterGroup = {};
    this.topicFilters.forEach(filter => {
      filterGroup[filter.value] = [this.filterData[filter.value] || false];
    });
    filterGroup['categories'] = [''];
    this.filterForm = this.formBuilder.group(filterGroup);
  }

  resetFilters(): void {
    this.filterForm.reset();
    this.selectedCategoryArray = [];
  }

  setFilters(): void {
    if (this.filterForm.valid) {
      const filterData: any = {};
      if (this.selectedCategoryArray && this.selectedCategoryArray.length) {
        filterData.categories = JSON.parse(JSON.stringify(this.selectedCategoryArray));
      }
      Object.entries(this.filterForm.value).forEach(([key, value]) => {
        if (value) {
          filterData[key] = value;
        }
      });
      if (Object.keys(filterData).length) {
        localStorage.setItem(Constants.localStorageKeys.topicFilters, JSON.stringify(filterData));
      } else {
        this.isFilterApplied.next(false);
        localStorage.removeItem(Constants.localStorageKeys.topicFilters);
      }
      this.filtersDialog.close(true);
    } else {
      this.toasterService.error(Constants.error.INVALID_FORM);
    }
  }

  selectCategory(selectedValue: { id: string, name: string }): void {
    if (this.selectedCategoryArray.findIndex(category => category.id === selectedValue.id) < 0) {
      this.selectedCategoryArray.push(selectedValue);
      this.filterForm.controls.categories.reset();
    }
  }

  isSelectedCategory(id: string): boolean {
    if (this.selectedCategoryArray.findIndex(entity => entity.id === id) >= 0) {
      return true;
    }
  }

  removeSelectedCategory(id: string): void {
    this.selectedCategoryArray = this.selectedCategoryArray.filter(author => author.id !== id);
  }

}
