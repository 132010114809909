import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import Constants from '@constants';
import { IChapter } from '@content/content.interface';
import { IRunTime } from '@sharedInterfaces';
import { UtilService } from '@util/util.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-edit-chapter',
  templateUrl: './add-edit-chapter.component.html',
  styleUrls: ['./add-edit-chapter.component.scss']
})
export class AddEditChapterComponent implements OnInit {

  chapterForm: FormGroup;
  approvalButtonText = 'Add Chapter';
  chapterTitle = '';
  chapterURL = '';
  chapterRunTimeMinutes = 0;
  chapterRunTimeSeconds = 0;
  chapterIndex;
  isCreation = true;
  chapterInfo = [];
  runTimeMinutesMandatory = true;
  chapterListenFreeStart = '';
  chapterListenFreeEnd = '';
  defaultDateFormat = Constants.dateDefaultFormat;
  constructor(
    private formBuilder: FormBuilder,
    public addEditChapterComponent: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) data: {existingChapterInfo: IChapter, chapter_index: number, chapter_info: IChapter[]},
    private toastr: ToastrService,
    private utilService: UtilService,
    private toasterService: ToastrService
  ) {
    if (data && data.existingChapterInfo) {
      this.chapterTitle = data.existingChapterInfo.chapter_title;
      this.chapterURL = data.existingChapterInfo.chapter_url;
      const runTime: IRunTime = this.utilService.milliSecondsToMinutesSeconds(data.existingChapterInfo.chapter_run_time);
      this.chapterRunTimeMinutes = runTime.minutes;
      this.chapterRunTimeSeconds = runTime.seconds;
      if (this.chapterRunTimeSeconds) {
        this.runTimeMinutesMandatory = false;
      }
      this.chapterIndex = data.existingChapterInfo.chapter_index;
      this.chapterListenFreeStart = this.utilService.formatDatetimeRequest(data.existingChapterInfo.chapter_listen_free_start);
      this.chapterListenFreeEnd =  this.utilService.formatDatetimeRequest(data.existingChapterInfo.chapter_listen_free_end);
      this.approvalButtonText = 'Update Chapter';
      this.isCreation = false;
    }
    if (data && data.chapter_info) {
      this.chapterInfo = data.chapter_info;
    }
    if (data && data.chapter_index && !data.existingChapterInfo) {
      this.chapterIndex = data.chapter_index;
    }
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.chapterForm = this.formBuilder.group({
      chapter_title: [this.chapterTitle],
      chapter_url: [this.chapterURL],
      chapter_run_time_minutes: [this.chapterRunTimeMinutes, Validators.min(this.runTimeMinutesMandatory ? 1 : 0)],
      chapter_run_time_seconds: [this.chapterRunTimeSeconds, [Validators.min(0), Validators.max(59)]],
      chapter_index: [{value : this.chapterIndex, disabled: true}],
      listen_free_start: [this.chapterListenFreeStart],
      listen_free_end: [this.chapterListenFreeEnd]
    });
  }

  addChapter(): void {
    if (this.chapterForm.valid) {
      this.utilService.cleanForm(this.chapterForm);
      const duplicateChapterURL = this.chapterInfo
        .findIndex(chapter => this.chapterForm.value.chapter_url && chapter.chapter_url === this.chapterForm.value.chapter_url);
      if (duplicateChapterURL >= 0) {
        this.toastr.error(
          `Duplicate chapter URL with index ${this.chapterInfo[duplicateChapterURL].chapter_index}. Please check and try again.`,
          'Error : '
        );
      } else {
        const runTime = this.utilService
          .runTimeToMilliSeconds(this.chapterForm.value.chapter_run_time_minutes, this.chapterForm.value.chapter_run_time_seconds);
        this.chapterInfo.push({...this.chapterForm.getRawValue(), chapter_run_time: runTime});
        this.addEditChapterComponent.close(this.chapterInfo);
      }
    } else {
      this.toasterService.error(Constants.error.INVALID_FORM);
    }
  }

  editChapter(): void {
    if (this.chapterForm.valid) {
      this.utilService.cleanForm(this.chapterForm);
      const duplicateChapterURL = this.chapterInfo
        .findIndex(chapter => this.chapterForm.value.chapter_url && chapter.chapter_url === this.chapterForm.value.chapter_url
          && chapter.chapter_index !== this.chapterForm.getRawValue().chapter_index);
      if (duplicateChapterURL >= 0) {
        this.toastr.error(
          `Duplicate chapter URL with index ${this.chapterInfo[duplicateChapterURL].chapter_index}. Please check and try again.`,
          'Error : '
        );
      } else {
        const index = this.chapterInfo.findIndex(chapter => chapter.chapter_index === this.chapterForm.getRawValue().chapter_index);
        if (index >= 0) {
          const runTime = this.utilService
            .runTimeToMilliSeconds(this.chapterForm.value.chapter_run_time_minutes, this.chapterForm.value.chapter_run_time_seconds);
          this.chapterInfo[index] = {...this.chapterForm.getRawValue(), chapter_run_time: runTime};
        }
        this.addEditChapterComponent.close(this.chapterInfo);
      }
    } else {
      this.toasterService.error(Constants.error.INVALID_FORM);
    }
  }

  handleRunTime(value: any): void {
    const seconds = parseInt(value.value || 0, 10);
    if (seconds) {
      this.chapterForm.controls.chapter_run_time_minutes.clearValidators();
      this.chapterForm.controls.chapter_run_time_minutes.setValidators([Validators.min(0)]);
      this.runTimeMinutesMandatory = false;
    } else {
      this.chapterForm.controls.chapter_run_time_minutes.clearValidators();
      this.chapterForm.controls.chapter_run_time_minutes.setValidators([Validators.min(1)]);
      this.runTimeMinutesMandatory = true;
    }
    this.chapterForm.controls.chapter_run_time_minutes.updateValueAndValidity();
  }
}
