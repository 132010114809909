import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatBottomSheet } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AddEditAuthorComponent } from '@author/add-edit-author/add-edit-author.component';
import { IAuthor } from '@author/authors.interface';
import { AuthorService } from '@author/authors.service';
import { FilterAuthorsComponent } from '@author/filter-authors/filter-authors.component';
import Constants from '@constants';
import { IKlevuResult } from '@klevu/klevu.interface';
import { KlevuService } from '@klevu/klevu.service';
import {
  IDialogData,
  IDialogResult,
  IFilterDialogData, IMultiSelectTableRow,
  IPagination,
  IRouteQueryParameters,
  ITableDisplayedColumns
} from '@sharedInterfaces';
import { ConfirmationComponent } from '@util/confirmation/confirmation.component';
import { DownloadErrorComponent } from '@util/download-error/download-error.component';
import { IDownloadErrorDialogData } from '@util/download-error/download-error.interface';
import { UploadDialogComponent } from '@util/upload-dialog/upload-dialog.component';
import * as httpStatus from 'http-status';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-authors',
  templateUrl: './authors.component.html',
  styleUrls: ['./authors.component.scss']
})
export class AuthorsComponent implements OnInit {

  authors: IAuthor[] = [];
  searchBoxField = new FormControl();
  klevuResult: IKlevuResult = {moreResults: false, result: [], totalResults: 0};
  nextPageKey;
  selectedAuthors: IMultiSelectTableRow[] = [];
  showBulkDeleteButton: Subject<boolean> = new Subject<boolean>();
  currentPageNo: number;
  currentPageKey: string;
  showFilters = true;
  searchTerm = '';
  noKlevuResult: Subject<boolean> = new Subject<boolean>();
  isFilterApplied: Subject<boolean> = new Subject<boolean>();
  displayedColumns: ITableDisplayedColumns[] = [
    { key: 'name', field: 'Author Name' },
    { key: 'id', field: 'Author ID' },
    { key: 'show_on_quiz', field: 'Show On Quiz', sort: true },
    { key: 'quiz_index', field: 'Quiz Index', sort: true },
    { key: 'img_url', field: 'Image URL' },
    { key: 'info', field: 'Description' }
  ];

  constructor(
    private authorService: AuthorService,
    private dialog: MatDialog,
    private klevuService: KlevuService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private bottomSheet: MatBottomSheet
  ) {
    this.searchBoxField
      .valueChanges
      .pipe(debounceTime(Constants.klevuDeBounceTime))
      .subscribe(term => {
        this.noKlevuResult.next(false);
        this.searchTerm = term;
        this.fillSearchBox(term);
      });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.currentPageNo = Number(queryParams.pageNo || 1);
      this.currentPageKey = queryParams.pageKey || '';
      this.getAuthors();
    });
  }

  fillSearchBox(term: string): void {
    if (term) {
      this.klevuService
        .searchKlevu(term, 'author')
        .subscribe(
          klevuResult => {
            this.klevuResult = klevuResult;
            if (!this.klevuResult || !this.klevuResult.result || !this.klevuResult.result.length) {
              this.noKlevuResult.next(true);
            }
          },
          error => console.error('Klevu author error => ', error),
          () => this.noKlevuResult.next(true)
        );
    } else {
      this.resetSearchBox();
    }
  }

  openUploadDialog(): void {
    const data: IDialogData = {titleText: 'Upload Bulk Authors'};
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      ...Constants.UploadDialogConfig,
      data
    });
    dialogRef.afterClosed().subscribe(file => {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        this.authorService
          .postBulkAuthors(formData)
          .subscribe(
            () => {
              this.refreshPage();
            },
            error => {
              if (error && error.value && error.value.error && error.value.status === httpStatus.EXPECTATION_FAILED) {
                const downloadError: IDownloadErrorDialogData = {
                  error: error.value.error || [],
                  fileName: file.name.split('.csv')[0]
                };
                this.bottomSheet.open(DownloadErrorComponent, {disableClose: true, data: downloadError});
              }
            });
      }
    });
  }

  openAuthorForm(author?: IAuthor): void {
    const authorDialogRef = this.dialog.open(AddEditAuthorComponent, {
      ...Constants.AddEditAuthorDialogConfig,
      data: {
        author
      }
    });
    authorDialogRef.afterClosed().subscribe((data: IDialogResult) => {
      if (data) {
        if (this.currentPageNo === 1 || !data.isCreation) {
          this.getAuthors();
        } else {
          this.openPage({}, 1);
        }
      }
    });
  }

  getAuthors(): void {
    this.authorService.getAuthors(this.currentPageKey).subscribe((authors: {
      results: IAuthor[], next_page_key: string
    }) => {
      this.authors = authors.results;
      this.nextPageKey = authors.next_page_key;
      this.showBulkDeleteButton.next(false);
    });
  }

  openPage(pageData?: IPagination, forcePageNumber?: number): void {
    const queryParams: IRouteQueryParameters = {
      pageNo: pageData.isPrevious ? this.currentPageNo - 1 : Number(this.currentPageNo) + 1,
      pageKey: pageData.pageKey || ''
    };
    if (forcePageNumber) {
      queryParams.pageNo = forcePageNumber;
    }
    this.router
      .navigate(
        [], {
          relativeTo: this.activatedRoute,
          queryParams,
          queryParamsHandling: 'merge'
        }
      );
  }

  openDeleteAuthorDialog(authorInfo: IAuthor): void {
    const config = {
      ...Constants.confirmationDialogConfig,
      data: Constants.AuthorSingleDeleteDialogData
    };
    const deleteDialog = this.dialog.open(ConfirmationComponent, config);
    deleteDialog.afterClosed().subscribe(approval => {
      if (approval) {
        this.authorService.deleteAuthor(authorInfo.id).subscribe(() => {
          this.refreshPage();
        });
      }
    });
  }

  navigateToViewAuthor(author: MatAutocompleteSelectedEvent): void {
    if (author.option.value && author.option.value.id) {
      const authorId = author.option.value.id;
      this.showBulkDeleteButton.next(false);
      this.router.navigate([`/viewAuthor`], {
        queryParams: {
          id: authorId,
          returnQueryParameters: JSON.stringify({pageNo: this.currentPageNo, pageKey: this.currentPageKey})
        }
      });
    }
    this.resetSearchBox();
  }

  showBulkOption(data: IMultiSelectTableRow[]): void {
    if (data && data.length) {
      this.selectedAuthors = data;
      this.showBulkDeleteButton.next(true);
    } else {
      this.selectedAuthors = [];
      this.showBulkDeleteButton.next(false);
    }
  }

  bulkDelete(): void {
    if (this.selectedAuthors && this.selectedAuthors.length) {
      const data: IDialogData = {
        titleText: 'Are you sure you want to delete multiple authors?',
        approvalButtonText: 'Delete',
        disApprovalButtonText: 'Cancel'
      };
      const bulkDeleteConfirmation = this.dialog.open(ConfirmationComponent, {
        ...Constants.confirmationDialogConfig,
        data
      });
      bulkDeleteConfirmation.afterClosed().subscribe(approval => {
        if (approval) {
          this.authorService
            .bulkDelete(this.selectedAuthors.map(author => author.id))
            .subscribe(() => {
              this.refreshPage();
              this.showBulkDeleteButton.next(false);
            });
        }
      });
    }
  }

  openFilterDialog(): void {
    const data: IFilterDialogData = {isFilterApplied: this.isFilterApplied};
    const filterDialog = this.dialog.open(FilterAuthorsComponent, {
      data
    });
    filterDialog.afterClosed().subscribe((isRefresh = false) => {
      if (isRefresh) {
        if (localStorage.getItem(Constants.localStorageKeys.authorsFilters) !== null) {
          this.isFilterApplied.next(true);
        } else {
          this.isFilterApplied.next(false);
        }
        this.refreshPage();
      }
    });
  }

  openKlevuSearchList(): void {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        search: this.searchTerm,
        pageNo: 1,
        entity: 'author'
      }
    };
    this.router.navigate(['/searchList'], navigationExtras);
  }

  resetSearchBox(): void {
    this.klevuResult = {moreResults: false, result: [], totalResults: 0};
  }

  refreshPage(): void {
    if (this.currentPageNo === 1) {
      this.getAuthors();
    } else {
      this.openPage({}, 1);
    }
    this.showBulkDeleteButton.next(false);
  }
}
