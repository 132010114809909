import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import Constants from '@constants';
import { TDialogType } from '@sharedTypes';
import { AddEditTopicComponent } from '@topic/add-edit-topic/add-edit-topic.component';
import { ITopic } from '@topic/topics.interface';
import { TopicService } from '@topic/topics.service';
import { ConfirmationComponent } from '@util/confirmation/confirmation.component';
import { UtilService } from '@util/util.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-view-topic',
  templateUrl: './view-topic.component.html',
  styleUrls: ['./view-topic.component.scss']
})
export class ViewTopicComponent implements OnInit {

  topicInfo: ITopic;
  topicId: string;
  returnQueryParameters: {};
  topicInfoFetch: Promise<boolean>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private topicService: TopicService,
    private utilService: UtilService,
    private dialog: MatDialog,
  ) {
    this.topicInfo = this.router.getCurrentNavigation().extras.state as ITopic;
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.topicId = queryParams.id;
      this.returnQueryParameters = JSON.parse(queryParams.returnQueryParameters || JSON.stringify({}));
      if (!this.topicInfo) {
        if (!this.topicId) {
          this.router.navigate(['/topics'], {queryParams: {...this.returnQueryParameters}});
        } else {
          this.getTopicById()
            .subscribe(result => this.topicInfoFetch = Promise.resolve(result));
        }
      } else {
        this.formatData();
        this.topicInfoFetch = Promise.resolve(true);
      }
    });
  }

  getTopicById(): Observable<any> {
    return new Observable<any>(observer => {
      this.topicService.getTopicById(this.topicId).subscribe(
        topicInfo => {
          this.topicInfo = topicInfo;
          this.formatData();

          return observer.next(true);
        },
        error => {
          // if there no topic then redirect view topic screen to previous list screen
          if (error) {
            this.router.navigate(['/topics'], {queryParams: {...this.returnQueryParameters}});
          }
        });
    });
  }

  formatData(): void {
    // URL
    this.topicInfo.img_url_view = this.topicInfo.img_url;
    this.topicInfo.img_url = this.utilService.getLatestImage(this.topicInfo.img_url);
    this.topicInfo.quiz_img_url_view = this.topicInfo.quiz_img_url;
    this.topicInfo.quiz_img_url = this.utilService.getLatestImage(this.topicInfo.quiz_img_url);

    // URL VALIDATION
    this.topicInfo.img_url_valid = this.utilService.isValidURL(this.topicInfo.img_url);
    this.topicInfo.quiz_img_url_valid = this.utilService.isValidURL(this.topicInfo.quiz_img_url);
  }

  openDialog(type: TDialogType): void {
    if (type === 'edit') {
      const topicDialogRef = this.dialog.open(AddEditTopicComponent, {
        ...Constants.EditTopicDialogConfig,
        data: {
          topic: JSON.parse(JSON.stringify(this.topicInfo))
        }
      });
      topicDialogRef.afterClosed().subscribe(isRefresh => {
        if (isRefresh) {
          this.getTopicById().subscribe(() => {
          });
        }
      });
    } else {
      const config = Constants.confirmationDialogConfig;
      const deleteDialogRef = this.dialog.open(ConfirmationComponent, {
        ...config,
        data: Constants.TopicSingleDeleteDialogData
      });
      deleteDialogRef.afterClosed().subscribe(approval => {
        if (approval) {
          this.topicService.deleteTopic(this.topicInfo.id).subscribe(() => this.router.navigate([`/topics`]));
        }
      });
    }
  }

}
