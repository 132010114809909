import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material';
import { IDownloadErrorDialogData } from '@util/download-error/download-error.interface';

@Component({
  selector: 'app-download-error',
  templateUrl: './download-error.component.html',
  styleUrls: ['./download-error.component.scss']
})
export class DownloadErrorComponent implements OnInit {

  error = 'Upload Errors';
  fileName = 'Bulk Upload';
  fileNameSuffix = ` - errors - ${new Date()}.csv`;
  bottomSheetMessage = 'Upload file contain errors.';
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: IDownloadErrorDialogData,
    private downloadErrorSheet: MatBottomSheetRef
  ) {
    if (data && Object.keys(data).length) {
      if (data.columnHeader) {
        this.error = data.columnHeader;
      }
      this.bottomSheetMessage = data.bottomSheetMessage || this.bottomSheetMessage;
      this.error += `\r\n ${data.error.join('\r\n')}`;
      this.fileName = data.fileName;
    }
    this.fileName += this.fileNameSuffix;
  }

  ngOnInit(): void {
  }

  downloadError(): void {
    const blob = new Blob(['\ufeff' + this.error], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const dlAnchorElem = document.getElementById('downloadError');
    dlAnchorElem.setAttribute('href', url);
    dlAnchorElem.setAttribute('download', `${this.fileName}`);
    this.downloadErrorSheet.dismiss();
  }

  closeSheet(): void {
    this.downloadErrorSheet.dismiss();
  }

}
