import { FormGroup } from '@angular/forms';
import { IRunTime } from '@sharedInterfaces';
import imageExtensions from 'image-extensions/image-extensions.json';

export class UtilService {

  isValidURL(url: any): boolean {
    if (!url) {
      return false;
    }
    try {
      // tslint:disable-next-line:no-unused-expression
      new URL(url);

      return true;
    } catch (exception) {
      return false;
    }
  }

  getRunTimeString(milliSeconds: any): string { // runtime is in milliseconds
    if (milliSeconds <= 0) {
      return '00:00:00';
    }
    const milliSecondsInInteger: number = parseInt(milliSeconds, 10);
    let seconds: any = Math.floor((milliSecondsInInteger / 1000) % 60);
    let minutes: any = Math.floor((milliSecondsInInteger / (1000 * 60)));

    if (minutes < 10) { minutes = '0' + minutes; }
    if (seconds < 10) { seconds = '0' + seconds; }

    return minutes + ':' + seconds;
  }

  formatDate(date: string): string {
    // @ts-ignore
    if (!date || new Date(date) === 'Invalid Date') {
      return '-';
    }
    const dateString = date.split('T')[0] || '';
    const dateArray = dateString.split('-');
    if (dateArray && dateArray.length === 3) {
      return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
    }

    return '-';
  }
  formatPrice(price: any, isEdit: boolean = false): string {
    if (!price || price === '-') {
      price = 0;
    }
    if (typeof price === 'string') {
      price = price.replace(/[^\.0-9]/g, '');
    }
    price = parseFloat(price);

    if (price === 0) {
      return isEdit ? '' : '-';
    }

    return '$' + price.toFixed(2);
  }

  formatPriceRequest(price: string): number {
    if (!price) {
      return null;
    }

    return parseFloat(parseFloat(price.replace(/[^\.0-9)]/, '')).toFixed(2));
  }

  formatDatetime(datetime: string): string {
    // @ts-ignore
    if (!datetime || new Date(datetime) === 'Invalid Date') {
      return '-';
    }

    const dateTimeArray = datetime.split('T');
    const dateArray = dateTimeArray[0].split('-');
    const timeArray = dateTimeArray[1].split(':');
    const meridian = parseInt(timeArray[0], 0) > 11 ? 'PM' : 'AM';
    const meridianHrs = (parseInt(timeArray[0], 0) > 12) ? parseInt(timeArray[0], 0) - 12 : timeArray[0];

    return dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0] + ' ' + meridianHrs + ':' + timeArray[1] + ' ' + meridian;
  }

  formatDateRequest(date: string): string {
    // @ts-ignore
    if (!date || new Date(date) === 'Invalid Date') {
      return '';
    }
    const dateString = date.split('T')[0] || '';
    const dateArray = dateString.split('-');
    if (dateArray && dateArray.length === 3) {
      return `${dateArray[0]}-${dateArray[1]}-${dateArray[2]}`;
    }

    return '';
  }

  formatDatetimeRequest(datetime: string): string {
    // @ts-ignore
    if (!datetime || new Date(datetime) === 'Invalid Date') {
      return '';
    }

    const dateString = datetime.split('T');
    const dateArray = dateString[0].split('-') || '';
    const timeArray = dateString[1].split(':') || '';
    if (dateArray && dateArray.length === 3 && timeArray && timeArray.length === 3) {
      return `${dateArray[0]}-${dateArray[1]}-${dateArray[2]}T${timeArray[0]}:${timeArray[1]}:${timeArray[2].substring(0, 2)}`;
    }

    return '';
  }

  runTimeToMilliSeconds(minutes: number | string = 0, seconds: number | string = 0): number {
    if (typeof minutes === 'string') {
      minutes = parseInt(minutes, 10);
    }
    if (typeof seconds === 'string') {
      seconds = parseInt(seconds, 10);
    }
    if (minutes <= 0) {
      minutes = 0;
    }
    if (seconds <= 0) {
      seconds = 0;
    }

    return (minutes * 60000) + (seconds * 1000);
  }

  milliSecondsToMinutesSeconds(milliSeconds: number | string): IRunTime {
    const result: IRunTime = { minutes: 0, seconds: 0};
    if (typeof milliSeconds === 'string') {
      milliSeconds = parseInt(milliSeconds, 10);
    }
    if (milliSeconds <= 0) {
      return result;
    }
    result.minutes = parseInt(String(milliSeconds / 60000), 10);
    result.seconds = parseInt(String((milliSeconds % 60000) / 1000), 10);

    return result;
  }

  cleanForm(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((key) => {
      if (formGroup.value[key] && typeof formGroup.value[key] === 'string') {
        formGroup.get(key).setValue(formGroup.value[key].trim());
      }
    });
  }

  compare(a: any, b: any, isAsc: boolean): number {
    if (a === b) {
      return 0;
    }
    if ([null, undefined, ''].indexOf(a) >= 0) {
      return 1;
    }
    if ([null, undefined, ''].indexOf(b) >= 0) {
      return -1;
    }
    if (isAsc) {
      return a < b ? -1 : 1;
    }
    {
      return a < b ? 1 : -1;
    }
  }

  objectToFormData(json: { }): FormData {
    const formData: FormData = new FormData();
    for (const key of Object.keys(json)) {
      if (Array.isArray(json[key])) {
        json[key] = JSON.stringify(json[key]);
      }
      formData.append(key, json[key]);
    }

    return formData;
  }

  universalImageFormats(): string[] {
    return imageExtensions;
  }

  getLatestImage(url: string = ''): string {
    // this will fetch new image from same url
    return url ? `${url}?${new Date().getTime()}` : url;
  }

  sortIntegerArray(arrayItems: any[] = []): number[] {
    if (!arrayItems.length) {
      return arrayItems;
    }
    const items: number[] = arrayItems.map(Number);
    items.sort((a, b) => a - b);

    return items;
  }

  sortArrayOfObjects(arrayItems: any[] = [], keyToSort: string): any[] {
    if (!arrayItems.length) {
      return arrayItems;
    }
    arrayItems.sort((a, b) => this.compare(a[keyToSort], b[keyToSort], true));

    return arrayItems;
  }

  getBaseURL(url: string = ''): string {
    try {
      const requestURL: string = JSON.parse(JSON.stringify(url));
      const actualURL: URL = new URL(requestURL);

      return actualURL.origin + actualURL.pathname;
    } catch (exception) {
      return '';
    }
  }
}
