import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth/auth.service';
import Constants from '@constants';
import { LoaderService } from '@util/loader/loader.service';
import * as httpStatus from 'http-status';
import { ToastrService } from 'ngx-toastr';
import { from, Observable, of } from 'rxjs';
import 'rxjs/add/operator/do';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class HayhouseInterceptor implements HttpInterceptor {

  constructor(
    public authService: AuthService,
    public toasterService: ToastrService,
    public loaderService: LoaderService,
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.authService.getCurrentUserAccessToken())
      .pipe(
        switchMap(accessToken => {
          this.loaderService.show();
          const interceptedReq = req.clone({
            headers: new HttpHeaders({
              authorization: `Bearer ${accessToken}`,
              timeout: `${600000}`,
              clientversion: '1.2.5',
              platform: 'cms'
            })
          });

          return next.handle(interceptedReq).pipe(
            tap(evt => {
              if (evt instanceof HttpResponse) {
                if ((interceptedReq.method === 'PUT' || interceptedReq.method === 'POST')
                  && Constants.successStatusCodes.includes(evt.status)
                  && evt.body) {
                  this.toasterService.success('Successfully entered data into CMS', 'Success!');
                }
                if (interceptedReq.method === 'DELETE'
                  && Constants.successStatusCodes.includes(evt.status)) {
                  this.toasterService.success('Data deleted successfully', 'Success!');
                }
              }
            }),
            catchError((err: any) => {
              if (err instanceof HttpErrorResponse) {
                try {
                  if (err.status === httpStatus.UNAUTHORIZED) {
                    this.authService.signOut();
                  }
                  this.toasterService.error(err.error.message || 'An error occurred. Please refresh and try again.', 'Error : ');
                } catch (e) {
                  this.toasterService.error('An error occurred', '');
                }
                console.log('Failed with', err.error.message);
              }
              throw of(err);
            }),
            finalize(() => this.loaderService.hide())
          );
        }));
  }
}
