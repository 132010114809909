import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Constants from '@constants';
import { IAPIQueryParameters } from '@sharedInterfaces';
import { ITopic } from '@topic/topics.interface';
import { Observable } from 'rxjs';

@Injectable({
              providedIn: 'root'
            })

export class TopicService {

  topics: ITopic[] = [];

  constructor(
    private http: HttpClient
  ) {
  }

  getTopics(allTopics: boolean = false, nextPageKey?: string, categoryIdArray: string[] = []): Observable<any> {
    let queryParameters: IAPIQueryParameters[] = [];
    if (categoryIdArray && categoryIdArray.length) {
      queryParameters.push({key: 'categories', value: categoryIdArray});
    }
    if (!allTopics) {
      queryParameters = [{key: 'limit', value: Constants.tableOnePgRecords}];
      const filterData = JSON.parse(localStorage.getItem(Constants.localStorageKeys.topicFilters) || JSON.stringify({}));
      if (filterData && Object.keys(filterData).length) {
        if (filterData.custom_topic && !filterData.quiz_topic) {
          queryParameters.push({key: 'is_custom', value: true});
        } else if (!filterData.custom_topic && filterData.quiz_topic) {
          queryParameters.push({key: 'is_custom', value: false});
        }
        if (filterData.categories && (!categoryIdArray || !categoryIdArray.length)) {
          queryParameters.push({key: 'categories', value: filterData.categories.map(category => category.id)});
        }
      }
    }
    if (nextPageKey) {
      queryParameters.push({key: 'next_page', value: nextPageKey});
    }

    return this.http.get(`${Constants.getAPIURL.topic(queryParameters)}`);
  }

  postTopic(topicData: FormData): Observable<any> {
    return this.http.post(`${Constants.getAPIURL.topic()}`, topicData);
  }

  updateTopic(topicData: FormData, topicId: string): Observable<any> {
    return this.http.put(`${Constants.getAPIURL.topic(null, topicId)}`, topicData);
  }

  deleteTopic(topicId: string): Observable<any> {
    return this.http.delete(`${Constants.getAPIURL.topic(null, topicId)}`);
  }

  getTopicById(topicId: string): Observable<any> {
    return this.http.get(`${Constants.getAPIURL.topic(null, topicId)}`);
  }

}
