import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import Constants from '@constants';
import { IDialogData } from '@sharedInterfaces';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent implements OnInit {
  attributes: IDialogData = {titleText: 'Upload', approvalButtonText: 'Submit', disApprovalButtonText: 'Cancel'};
  file: File;
  enableSubmit = false;
  csvMessage = '';
  fileFormat = ['csv'];

  constructor(
    public confirmDialog: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) data: IDialogData
  ) {
    if (data && Object.keys(data).length) {
      this.attributes = Object.assign(this.attributes, data);
    }
  }

  ngOnInit(): void {
  }

  onFileChange(uploadedFile: File): void {
    if (uploadedFile) {
      const fileSize: number = uploadedFile.size; // in bytes
      if (fileSize > Constants.maxCSVSize) {
        this.enableSubmit = false;
        this.csvMessage = `Error: File size should be less than ${parseInt(String(Constants.maxCSVSize / 1000000), 10)} MB.`;
      } else {
        this.csvMessage = `File size is under ${parseInt(String(Constants.maxCSVSize / 1000000), 10)} MB.`;
        this.enableSubmit = true;
        this.file = uploadedFile;
      }
    }
  }

}
