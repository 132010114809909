import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ContentType } from '@content/content.enum';

@Component({
  selector: 'app-content-type',
  templateUrl: './content-type.component.html',
  styleUrls: ['./content-type.component.scss']
})
export class ContentTypeComponent implements OnInit {

  contentTypeButtons: {text: string; value: ContentType}[] = [
    {text: 'Audio Book', value: ContentType['Audio Book With Chapters']},
    {text: 'Podcast', value: ContentType.Podcast},
    {text: 'Curated Collection', value: ContentType['Curated Collection']},
    {text: 'Daily Affirmation', value: ContentType['Daily Affirmation']}
  ];
  disableButtons: boolean;

  constructor(
    private contentTypeDialog: MatDialogRef<any>
  ) { }

  ngOnInit(): void {
  }

  selectContentType(contentType: ContentType): void {
    this.disableButtons = true;
    this.contentTypeDialog.close(contentType);
  }

}
