import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import Constants from '@constants';
import { IFilterDialogData, IFilterHTML } from '@sharedInterfaces';
import { UtilService } from '@util/util.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-filter-authors',
  templateUrl: './filter-authors.component.html',
  styleUrls: ['./filter-authors.component.scss']
})
export class FilterAuthorsComponent implements OnInit {

  filterForm: FormGroup;
  authorFilters: IFilterHTML[] = [
    {view: 'Show On Quiz', value: 'show_on_quiz'},
    {view: 'With Contents', value: 'with_contents'},
    {view: 'Without Contents', value: 'without_contents'}
  ];
  isFilterApplied: Subject<boolean> = new Subject<boolean>();
  constructor(
    private formBuilder: FormBuilder,
    public filtersDialog: MatDialogRef<any>,
    private utilService: UtilService,
    private toasterService: ToastrService,
    @Inject(MAT_DIALOG_DATA) data: IFilterDialogData
  ) {
    if (data) {
      this.isFilterApplied = data.isFilterApplied;
    }
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    const filterData = JSON.parse(localStorage.getItem(Constants.localStorageKeys.authorsFilters) || JSON.stringify({}));
    const filterGroup = {};
    this.authorFilters.forEach(filter => {
      filterGroup[filter.value] = [filterData[filter.value] || false];
    });
    this.filterForm = this.formBuilder.group(filterGroup);
  }

  resetFilters(): void {
    this.filterForm.reset();
  }

  setFilters(): void {
    if (this.filterForm.valid) {
      const filterData = {};
      Object.entries(this.filterForm.value).forEach(([key, value]) => {
        if (value) {
          filterData[key] = value;
        }
      });
      if (Object.keys(filterData).length) {
        localStorage.setItem(Constants.localStorageKeys.authorsFilters, JSON.stringify(filterData));
      } else {
        this.isFilterApplied.next(false);
        localStorage.removeItem(Constants.localStorageKeys.authorsFilters);
      }
      this.filtersDialog.close(true);
    } else {
      this.toasterService.error(Constants.error.INVALID_FORM);
    }
  }

}
