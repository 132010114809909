import { Injectable } from '@angular/core';
import Constants from '@constants';
import { IKlevuObject, IKlevuResult } from '@klevu/klevu.interface';
import { IAPIQueryParameters } from '@sharedInterfaces';
import { TEntityType } from '@sharedTypes';
import { LoaderService } from '@util/loader/loader.service';
import axios from 'axios';
import * as httpStatus from 'http-status';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class KlevuService {

  constructor(private loaderService: LoaderService) {}

  searchKlevu(term: string, category: TEntityType, paginationStartsFrom: number = 0, contentId: string = ''): Observable<IKlevuResult> {
    return new Observable<IKlevuResult>(observer => {
      if (typeof term !== 'string') {
        return observer.next();
      }
      this.loaderService.show();
      const klevuResults: IKlevuResult = {moreResults: false, result: [], totalResults: 0};
      const queryParameters: IAPIQueryParameters[] = [];
      let filters = `item_count:1`;
      if (category === 'content') {
        filters += `;;content_type:content`;
      } else if (category === 'episode' && contentId) {
        filters += `;;content_type:episode;;content_id:${contentId}`;
      } else {
        filters += `;;category:${category}`;
      }
      queryParameters.push(
        ...Constants.klevuDefaultParameters,
        {key: 'term', value: term},
        {key: 'applyFilters', value: filters},
        {key: 'paginationStartsFrom', value: paginationStartsFrom},
        {key: 'timestamp', value: new Date().getTime()}
      );
      const klevuBaseURL = `${Constants.getAPIURL.klevu(queryParameters)}`;
      axios
        .get(klevuBaseURL)
        .then((searchResult: any) => {
          if (
            !searchResult.status ||
            searchResult.status !== httpStatus.OK ||
            !searchResult.data ||
            !searchResult.data.meta ||
            !searchResult.data.meta.totalResultsFound ||
            !searchResult.data.meta.noOfResults ||
            !searchResult.data.result ||
            !searchResult.data.result.length ||
            !searchResult.data.meta ||
            !searchResult.data.meta.hasOwnProperty('noOfResults')
          ) {
            return observer.next(klevuResults);
          }
          const result = searchResult.data.result;
          result.forEach(entity => {
            const tempKlevu: IKlevuObject = {img_url: '', id: '', name: ''};
            tempKlevu.name = entity.name;
            tempKlevu.id = entity.entity_id;
            tempKlevu.img_url = entity.image;
            if (entity.type) {
              tempKlevu.type = +entity.type;
            }
            klevuResults.result.push(tempKlevu);
          });
          klevuResults.totalResults = searchResult.data.meta.totalResultsFound;
          klevuResults.moreResults = searchResult.data.meta.noOfResults < searchResult.data.meta.totalResultsFound;

          return observer.next(klevuResults);
        })
        .finally(() => this.loaderService.hide());
    });
  }

}
