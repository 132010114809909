import { Component, Injectable, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatBottomSheet } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import Constants from '@constants';
import { AddEditContentComponent } from '@content/add-edit-content/add-edit-content.component';
import { ContentTypeComponent } from '@content/content-type/content-type.component';
import { ContentType } from '@content/content.enum';
import { IContent } from '@content/content.interface';
import { ContentService } from '@content/content.service';
import { AddEditTrackComponent } from '@content/curated-collection-track/add-edit-track/add-edit-track.component';
import { ITrackDialogData } from '@content/curated-collection-track/curated-collection-track.interface';
import { FilterContentComponent } from '@content/filter-content/filter-content.component';
import { AddEditEpisodeComponent } from '@content/podcast-episode/add-edit-episode/add-edit-episode.component';
import { IEpisodeDialogData } from '@content/podcast-episode/podcast-episode.interface';
import { IKlevuResult } from '@klevu/klevu.interface';
import { KlevuService } from '@klevu/klevu.service';
import {
  IDialogData,
  IDialogResult,
  IFilterDialogData,
  IMultiSelectContent,
  IPagination,
  IRouteQueryParameters,
  ITableDisplayedColumns
} from '@sharedInterfaces';
import { ConfirmationComponent } from '@util/confirmation/confirmation.component';
import { DownloadErrorComponent } from '@util/download-error/download-error.component';
import { IDownloadErrorDialogData } from '@util/download-error/download-error.interface';
import { UploadDialogComponent } from '@util/upload-dialog/upload-dialog.component';
import { UtilService } from '@util/util.service';
import * as httpStatus from 'http-status';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

  searchBoxField = new FormControl();
  klevuResult: IKlevuResult = {moreResults: false, result: [], totalResults: 0};
  content: IContent[] = [];
  nextPageKey;
  showBulkDeleteButton: Subject<boolean> = new Subject<boolean>();
  currentPageNo: number;
  currentPageKey: string;
  selectedContents: IMultiSelectContent[];
  searchTerm = '';
  noKlevuResult: Subject<boolean> = new Subject<boolean>();
  isFilterApplied: Subject<boolean> = new Subject<boolean>();
  nameCheck = false; // this is for bulk upload feature to upload csv which contain names instead of ids
  displayedColumns: ITableDisplayedColumns[] = [
    {key: 'authors', field: 'Authors'},
    {key: 'title', field: 'Title'},
    {key: 'description', field: 'Description'},
    {key: 'topics', field: 'Topics'},
    {key: 'categories', field: 'Categories'},
    {key: 'content_type_string', field: 'Content Type'},
    {key: 'img_url', field: 'Image URL'}
  ];

  constructor(
    private contentService: ContentService,
    private dialog: MatDialog,
    private klevuService: KlevuService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private bottomSheet: MatBottomSheet,
    private utilService: UtilService
  ) {
    this.searchBoxField
      .valueChanges
      .pipe(debounceTime(Constants.klevuDeBounceTime))
      .subscribe(term => {
        this.noKlevuResult.next(false);
        this.searchTerm = term;
        this.fillSearchBox(term);
      });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.currentPageNo = Number(queryParams.pageNo || 1);
      this.currentPageKey = queryParams.pageKey || '';
      this.nameCheck = queryParams.nameCheck || false;
      this.getContents(this.currentPageKey);
      this.showBulkDeleteButton.next(false);
    });
  }

  fillSearchBox(term: string): void {
    if (term) {
      this.klevuService
        .searchKlevu(term, 'content')
        .subscribe(
          klevuResult => {
            this.klevuResult = klevuResult;
            if (!this.klevuResult || !this.klevuResult.result || !this.klevuResult.result.length) {
              this.noKlevuResult.next(true);
            }
        },
          error => console.error('Klevu content error => ', error),
          () => this.noKlevuResult.next(true)
        );
    } else {
      this.resetSearchBox();
    }
  }

  openUploadDialog(contentType: ContentType): void {
    const data: IDialogData = {titleText: `Upload Bulk `};
    if (contentType === ContentType.Podcast) {
      data.titleText += `Podcasts`;
    } else if (contentType === ContentType['Curated Collection']) {
      data.titleText += `Curated Collections`;
    } else if (contentType === ContentType['Daily Affirmation']) {
      data.titleText += `Daily Affirmations`;
    } else {
      data.titleText += `Audio Books`;
    }
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      ...Constants.UploadDialogConfig,
      data
    });
    dialogRef.afterClosed().subscribe(file => {
      if (file) {
        const request = {
          file,
          nameCheck: this.nameCheck,
          content_type: contentType
        };
        const formData = this.utilService.objectToFormData(request);
        this.contentService
          .postBulkContent(formData)
          .subscribe(
            () => {
              this.refreshPage();
            },
            (error) => {
              if (error && error.value && error.value.error && error.value.status === httpStatus.EXPECTATION_FAILED) {
                const downloadError: IDownloadErrorDialogData = {
                  error: error.value.error || [],
                  fileName: file.name.split('.csv')[0]
                };
                this.bottomSheet.open(DownloadErrorComponent, {disableClose: true, data: downloadError});
              }
            });
      }
    });
  }

  openContentTypeDialog(isUploadRequest: boolean = false): void {
    const contentTypeDialogRef = this.dialog.open(ContentTypeComponent, {
      width: '440px',
      height: 'auto'
    });
    contentTypeDialogRef.afterClosed().subscribe((contentType: ContentType) => {
      if (contentType) {
        if (isUploadRequest) {
          this.openUploadDialog(contentType);
        } else {
          this.openContentDialog(null, contentType);
        }
      }
    });
  }

  openContentDialog(content?: any, contentType?: ContentType): void {
    if (!contentType && content) {
      contentType = content.content_type;
    }
    const contentDialogRef = this.dialog.open(AddEditContentComponent, {
      ...Constants.AddEditContentDialogConfig,
      data: {
        content,
        contentType
      }
    });
    contentDialogRef.afterClosed().subscribe((result: IDialogResult) => {
      if (result) {
        if (contentType === ContentType.Podcast
          && result.result
          && Object.keys(result.result).length
          && result.isCreation) {
          this.getContents(this.currentPageKey);
          const data: IEpisodeDialogData = {
            disApprovalButtonText: 'I’ll do it later',
            approvalButtonText: 'Add Episode',
            podcastId: result.result.id
          };
          const addEpisodeDialogRef = this.dialog.open(AddEditEpisodeComponent, {
            ...Constants.AddEditEpisodeDialogConfig,
            data
          });
          addEpisodeDialogRef.afterClosed().subscribe((episodeResult: IDialogResult) => {
            if (episodeResult) {
              const navigationExtras: NavigationExtras = {
                queryParams: {id: result.result.id}
              };
              this.router.navigate(['/episodeList'], navigationExtras);
            }
          });
        } else if (contentType === ContentType['Curated Collection']
          && result.result
          && Object.keys(result.result).length
          && result.isCreation) {
          this.getContents(this.currentPageKey);
          const data: ITrackDialogData = {
            disApprovalButtonText: 'I’ll do it later',
            approvalButtonText: 'Add Track',
            curatedCollectionId: result.result.id
          };
          const addTrackDialogRef = this.dialog.open(AddEditTrackComponent, {
            ...Constants.AddEditTrackDialogConfig,
            data
          });
          addTrackDialogRef.afterClosed().subscribe((trackResult: IDialogResult) => {
            if (trackResult) {
              const navigationExtras: NavigationExtras = {
                queryParams: {id: result.result.id}
              };
              this.router.navigate(['/trackList'], navigationExtras);
            }
          });
        } else if (this.currentPageNo === 1 || !result.isCreation) {
          this.getContents(this.currentPageKey);
        } else {
          this.openPage({}, 1);
        }
      }
    });
  }

  getContents(currentPageKey: string): void {
    this.contentService.getContents(currentPageKey).subscribe((content: {
      results: IContent[], next_page_key: string
    }) => {
      this.content = content.results.map(item =>
        ({
          ...item,
          categories: item.category_info && item.category_info.length ? item.category_info.filter(el => el).map(x => x.name) : [],
          topics: item.topic_info && item.topic_info.length ? item.topic_info.filter(el => el).map(x => x.name) : [],
          authors: item.author_info && item.author_info.length ? item.author_info.filter(el => el).map(x => x.name) : [],
          content_type_string: ContentType[item.content_type]
        }));
      this.showBulkDeleteButton.next(false);
      this.nextPageKey = content.next_page_key;
    });
  }

  openPage(pageData?: IPagination, forcePageNumber?: number): void {
    const queryParams: IRouteQueryParameters = {
      pageNo: pageData.isPrevious ? this.currentPageNo - 1 : Number(this.currentPageNo) + 1,
      pageKey: pageData.pageKey || ''
    };
    if (forcePageNumber) {
      queryParams.pageNo = forcePageNumber;
    }
    this.router
      .navigate(
        [], {
          relativeTo: this.activatedRoute,
          queryParams,
          queryParamsHandling: 'merge'
        }
      );
  }

  openDeleteDialog(contentInfo: { id: string, content_type: ContentType }): void {
    const config = {
      ...Constants.confirmationDialogConfig,
      data: Constants.ContentSingleDeleteDialogData(
        contentInfo.content_type === ContentType.Podcast,
        contentInfo.content_type === ContentType['Curated Collection']
      )
    };
    const deleteDialog = this.dialog.open(ConfirmationComponent, config);
    deleteDialog.afterClosed().subscribe((approval: boolean) => {
      if (approval) {
        this.contentService.deleteContent(contentInfo.id).subscribe(result => {
          this.refreshPage();
        });
      }
    });
  }

  navigateToViewContent(content: MatAutocompleteSelectedEvent): void {
    if (content.option.value && content.option.value.id) {
      const contentId = content.option.value.id;
      this.showBulkDeleteButton.next(false);
      this.router.navigate([`/viewContent`], {
        queryParams: {
          id: contentId,
          returnQueryParameters: JSON.stringify({pageNo: this.currentPageNo, pageKey: this.currentPageKey})
        }
      });
    }
    this.resetSearchBox();
  }

  showBulkOption(data: IMultiSelectContent[]): void {
    if (data && data.length) {
      this.selectedContents = data;
      this.showBulkDeleteButton.next(true);
    } else {
      this.showBulkDeleteButton.next(false);
    }
  }

  bulkDelete(): void {
    if (this.selectedContents && this.selectedContents.length) {
      const isPodcastInDelete = this.selectedContents.some(content => content.content_type === ContentType.Podcast);
      const isCuratedCollectionInDelete = this.selectedContents.some(content => content.content_type === ContentType['Curated Collection']);
      const data: IDialogData = {
        titleText: 'Are you sure you want to delete multiple contents?',
        approvalButtonText: 'Delete',
        disApprovalButtonText: 'Cancel'
      };
      const bulkDeleteConfirmation = this.dialog.open(ConfirmationComponent, {
        ...Constants.confirmationDialogConfig,
        data
      });
      bulkDeleteConfirmation.afterClosed().subscribe(approval => {
        if (approval) {
          if (isPodcastInDelete || isCuratedCollectionInDelete) {
            data.titleText = isPodcastInDelete ?
              'Selected contents also has podcasts. This will delete all its episodes too. Are you sure?'
            : 'Selected contents also has Curated Collections. This will delete all its tracks too. Are you sure?';
            const againBulkDeleteConfirmation = this.dialog.open(ConfirmationComponent, {
              ...Constants.confirmationDialogConfig,
              data
            });
            againBulkDeleteConfirmation.afterClosed().subscribe(secondApproval => {
              if (secondApproval) {
                this.contentService
                  .bulkDelete(this.selectedContents.map(content => content.id))
                  .subscribe(() => {
                    this.refreshPage();
                    this.showBulkDeleteButton.next(false);
                  });
              }
            });
          } else {
            this.contentService
              .bulkDelete(this.selectedContents.map(content => content.id))
              .subscribe(() => {
                this.refreshPage();
                this.showBulkDeleteButton.next(false);
              });
          }
        }
      });
    }
  }

  openFilterDialog(): void {
    const data: IFilterDialogData = {isFilterApplied: this.isFilterApplied};
    const filterDialog = this.dialog.open(FilterContentComponent, {
      data,
      autoFocus: false
    });
    filterDialog.afterClosed().subscribe((isRefresh = false) => {
      if (isRefresh) {
        if (localStorage.getItem(Constants.localStorageKeys.contentFilters) !== null) {
          this.isFilterApplied.next(true);
        } else {
          this.isFilterApplied.next(false);
        }
        this.refreshPage();
      }
    });
  }

  openKlevuSearchList(): void {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        search: this.searchTerm,
        pageNo: 1,
        entity: 'content'
      }
    };
    this.router.navigate(['/searchList'], navigationExtras);
  }

  refreshPage(): void {
    if (this.currentPageNo === 1) {
      this.getContents(this.currentPageKey);
    } else {
      this.openPage({}, 1);
    }
    this.showBulkDeleteButton.next(false);
  }

  resetSearchBox(): void {
    this.klevuResult = {moreResults: false, result: [], totalResults: 0};
  }

}
