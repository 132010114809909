import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return Auth.currentSession().then((user: CognitoUserSession) => {
        const isAdmin: boolean = this.authService.checkAdmin(user);
        if (isAdmin) {
          return true;
        }
        this.authService.handleLogout();

        return false;
      }
    ).catch(() => {
      this.authService.handleLogout();

      return false;
    });
  }
}
