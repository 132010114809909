import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_RADIO_DEFAULT_OPTIONS, MatBottomSheet } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthComponent } from '@auth/auth.component';
import { AddEditAuthorComponent } from '@author/add-edit-author/add-edit-author.component';
import { AuthorsComponent } from '@author/authors.component';
import { FilterAuthorsComponent } from '@author/filter-authors/filter-authors.component';
import { ViewAuthorComponent } from '@author/view-author/view-author.component';
import { CategoriesComponent } from '@category/categories.component';
import { AddEditChapterComponent } from '@content/add-edit-chapter/add-edit-chapter.component';
import { AddEditContentComponent } from '@content/add-edit-content/add-edit-content.component';
import { ContentTypeComponent } from '@content/content-type/content-type.component';
import { ContentComponent } from '@content/content.component';
import { AddEditTrackComponent } from '@content/curated-collection-track/add-edit-track/add-edit-track.component';
import { TracksListComponent } from '@content/curated-collection-track/tracks-list/tracks-list.component';
import { FilterContentComponent } from '@content/filter-content/filter-content.component';
import { AddEditEpisodeComponent } from '@content/podcast-episode/add-edit-episode/add-edit-episode.component';
import { EpisodesListComponent } from '@content/podcast-episode/episodes-list/episodes-list.component';
import { ViewContentComponent } from '@content/view-content/view-content.component';
import { KlevuComponent } from '@klevu/klevu.component';
import { AddEditTopicComponent } from '@topic/add-edit-topic/add-edit-topic.component';
import { FilterTopicsComponent } from '@topic/filter-topics/filter-topics.component';
import { TopicsComponent } from '@topic/topics.component';
import { ViewTopicComponent } from '@topic/view-topic/view-topic.component';
import { ConfirmationComponent } from '@util/confirmation/confirmation.component';
import { DownloadErrorComponent } from '@util/download-error/download-error.component';
import { LoaderComponent } from '@util/loader/loader.component';
import { LoaderService } from '@util/loader/loader.service';
import { TableViewComponent } from '@util/table-view/table-view.component';
import { UploadButtonComponent } from '@util/upload-button/upload-button.component';
import { UploadDialogComponent } from '@util/upload-dialog/upload-dialog.component';
import { UtilService } from '@util/util.service';
import { AmplifyAngularModule } from 'aws-amplify-angular';
import { ClipboardModule } from 'ngx-clipboard';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HayhouseInterceptor } from './interceptors/hayhouse.interceptor';
import { MaterialModule } from './material/material.module';

@NgModule({
  declarations: [
    AppComponent,
    ContentComponent,
    AuthorsComponent,
    UploadButtonComponent,
    AuthComponent,
    UploadDialogComponent,
    TableViewComponent,
    TopicsComponent,
    CategoriesComponent,
    AddEditAuthorComponent,
    LoaderComponent,
    AddEditContentComponent,
    AddEditChapterComponent,
    ConfirmationComponent,
    ViewContentComponent,
    FilterContentComponent,
    FilterAuthorsComponent,
    DownloadErrorComponent,
    ContentTypeComponent,
    EpisodesListComponent,
    AddEditEpisodeComponent,
    TracksListComponent,
    AddEditTrackComponent,
    KlevuComponent,
    ViewAuthorComponent,
    AddEditTopicComponent,
    FilterTopicsComponent,
    ViewTopicComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    AmplifyAngularModule,
    HttpClientModule,
    ClipboardModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
  ],
  entryComponents: [
    UploadDialogComponent,
    AddEditAuthorComponent,
    AddEditContentComponent,
    AddEditChapterComponent,
    ConfirmationComponent,
    FilterContentComponent,
    FilterAuthorsComponent,
    DownloadErrorComponent,
    ContentTypeComponent,
    AddEditEpisodeComponent,
    AddEditTrackComponent,
    AddEditTopicComponent,
    FilterTopicsComponent
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HayhouseInterceptor, multi: true},
    {provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' }},
    MatBottomSheet,
    LoaderService,
    UtilService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
