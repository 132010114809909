import { environment } from '@env/environment';
import { IAPIQueryParameters, IDialogConfig, IDialogData } from '@sharedInterfaces';
import * as httpStatus from 'http-status';

export default class Constants {
  static readonly dateDefaultFormat = 'DD/MM/YYYY';
  static readonly requestDateDefaultFormat = 'YYYY-MM-DD';

  static readonly confirmationDialogConfig: IDialogConfig = {
    width: '500px',
    height: 'auto',
    disableClose: true
  };

  static readonly AddEditEpisodeDialogConfig: IDialogConfig = {
    width: '550px',
    height: 'auto'
  };

  static readonly AddEditTrackDialogConfig: IDialogConfig = {
    width: '550px',
    height: 'auto'
  };

  static readonly UploadDialogConfig: IDialogConfig = {
    width: '350px',
    height: 'auto'
  };

  static readonly AddEditContentDialogConfig: IDialogConfig = {
    width: '1240px',
    height: 'auto'
  };

  static readonly AddEditAuthorDialogConfig: IDialogConfig = {
    width: '750px',
    height: 'auto'
  };

  static readonly AddTopicDialogConfig: IDialogConfig = {
    width: '600px',
    height: 'auto'
  };

  static readonly EditTopicDialogConfig: IDialogConfig = {
    width: '750px',
    height: 'auto'
  };

  static readonly AuthorSingleDeleteDialogData: IDialogData = {
    titleText: 'Are you sure you want to delete author?',
    approvalButtonText: 'Delete',
    disApprovalButtonText: 'Cancel'
  };

  static readonly TopicSingleDeleteDialogData: IDialogData = {
    titleText: 'Are you sure you want to delete custom topic?',
    approvalButtonText: 'Delete',
    disApprovalButtonText: 'Cancel'
  };

  static readonly EpisodeSingleDeleteDialogData: IDialogData = {
    titleText: 'Are you sure you want to delete episode?',
    approvalButtonText: 'Delete',
    disApprovalButtonText: 'Cancel'
  };

  static readonly TrackSingleDeleteDialogData: IDialogData = {
    titleText: 'Are you sure you want to delete track?',
    approvalButtonText: 'Delete',
    disApprovalButtonText: 'Cancel'
  };

  static readonly tableOnePgRecords = 20; // CHANGE THIS VALUE TO CHANGE IN BOTH API REQUEST AND PAGINATION LOGIC

  static readonly localStorageKeys = {
    contentFilters: 'content-filter',
    authorsFilters: 'author-filter',
    topicFilters: 'topic-filter',
    authorPagination: 'author-pagination',
    contentPagination: 'content-pagination'
  };

  static readonly maxCSVSize = 5000000; // size in bytes which equal to 5MB
  static readonly maxImageSize = 600000; // size in bytes which equal to 600KB

  static readonly successStatusCodes = [
    httpStatus.OK,
    httpStatus.NO_CONTENT,
    httpStatus.CREATED,
    httpStatus.ACCEPTED
  ];

  static readonly topicCJTags = [
    {
      view: 'CJ Personal Growth',
      value: 'cj_personal_growth'
    },
    {
      view: 'CJ Metaphysical',
      value: 'cj_metaphysical'
    },
    {
      view: 'CJ Health Wellness',
      value: 'cj_health_wellness'
    },
    {
      view: 'CJ Business Writing',
      value: 'cj_business_writing'
    }
  ];

  static readonly quizTopicCategories = [
    'fd503b39-c9ee-47a2-8eeb-5fe4281c01ae', // Podcasts
    '5f1d2eb1-a559-47c5-8da4-75037572b3bc', // Hay House Radio
    '52cb7839-fa49-4909-9a01-71484ecc82c7', // Immersive Learning
    '1ed75acb-ec52-6d7e-bb5a-46bce548bec0', // Curated Collections
    '3d0f0aec-4b33-49fe-94ab-ddd8dd6f4a2f', // Meditations
    '8a0a76db-e9df-47db-a563-917f537e104c', // Audiobooks
  ];

  static readonly lessDescriptionCharacterLimit = 400;
  static readonly descriptionMoreButtonText = 'Read More';
  static readonly descriptionLessButtonText = 'Read Less';

  static readonly error = {
    INVALID_FORM: 'Invalid Request to submit. Please check fields and try again.',
    NO_AUTHOR_SELECT_FOR_FILTER: 'Kindly choose author to apply filter.'
  };

  static readonly klevuDeBounceTime = 1000;

  static readonly klevuDefaultParameters: IAPIQueryParameters[] = [
    {key: 'ticket', value: environment.klevuTicketId},
    {key: 'noOfResults', value: Constants.tableOnePgRecords},
    {key: 'responseType', value: 'json'},
    {key: 'showOutOfStockProducts', value: true}
  ];

  static readonly S3 = {
    bucketNamePrefix: 'hayhouse-images-',
    folders: {
      author: 'author',
      content: 'content',
      topic: 'topic',
      category: 'category',
    },
    default: {
      author(): string {
        return `https://${Constants.S3.bucketNamePrefix}${environment.backendEnvironment}.s3.amazonaws.com/default/author.png`;
      },
      content(): string {
        return `https://${Constants.S3.bucketNamePrefix}${environment.backendEnvironment}.s3.amazonaws.com/default/content.png`;
      },
      topic(): string {
        return `https://${Constants.S3.bucketNamePrefix}${environment.backendEnvironment}.s3.amazonaws.com/default/topic.png`;
      },
      category(): string {
        return `https://${Constants.S3.bucketNamePrefix}${environment.backendEnvironment}.s3.amazonaws.com/default/category.png`;
      },
    },
  };

  static readonly validTillTime = {
    hour: 23,
    minute: 59,
    second: 59
  };

  static readonly validFromTime = {
    hour: 0,
    minute: 0,
    second: 0
  };

  static readonly validPodcastCategoryNames = ['meditations', 'podcasts'];
  static readonly mandatoryPodcastCategoryName = 'podcasts';
  static readonly validCuratedCollectionCategoryNames = ['curated collections'];
  static readonly mandatoryCuratedCollectionCategoryName = 'curated collections';

  static readonly apiEndPoints = {
    topic: '/topics',
    author: '/authors',
    category: '/categories',
    content: '/content',
    newRelease: '/new-releases',
    quickListen: '/quick-listens',
    trending: '/trending',
    topPodcast: '/top-podcast',
    episode: '/podcast-episodes',
    featuredCuratedCollection: '/featured-curated-collection',
    track: '/curated-collection-tracks',
    clearCache: '/helpers/cache/invalidate'
  };

  static readonly getAPIURL = {
    topic(queryParameters?: IAPIQueryParameters[], params: string = ''): string {
      return Constants.appendQueryParameters(`${environment.hayhouseBaseUrl}${Constants.apiEndPoints.topic}/${params}`, queryParameters);
    },
    author(queryParameters?: IAPIQueryParameters[], params: string = ''): string {
      return Constants.appendQueryParameters(`${environment.hayhouseBaseUrl}${Constants.apiEndPoints.author}/${params}`, queryParameters);
    },
    content(queryParameters?: IAPIQueryParameters[], params: string = ''): string {
      return Constants.appendQueryParameters(`${environment.hayhouseBaseUrl}${Constants.apiEndPoints.content}/${params}`, queryParameters);
    },
    category(queryParameters?: IAPIQueryParameters[]): string {
      return Constants.appendQueryParameters(`${environment.hayhouseBaseUrl}${Constants.apiEndPoints.category}`, queryParameters);
    },
    newRelease(queryParameters?: IAPIQueryParameters[]): string {
      return Constants.appendQueryParameters(`${environment.hayhouseBaseUrl}${Constants.apiEndPoints.newRelease}`, queryParameters);
    },
    quickListen(queryParameters?: IAPIQueryParameters[]): string {
      return Constants.appendQueryParameters(`${environment.hayhouseBaseUrl}${Constants.apiEndPoints.quickListen}`, queryParameters);
    },
    trending(queryParameters?: IAPIQueryParameters[]): string {
      return Constants.appendQueryParameters(`${environment.hayhouseBaseUrl}${Constants.apiEndPoints.trending}`, queryParameters);
    },
    topPodcast(queryParameters?: IAPIQueryParameters[]): string {
      return Constants.appendQueryParameters(`${environment.hayhouseBaseUrl}${Constants.apiEndPoints.topPodcast}`, queryParameters);
    },
    featuredCuratedCollection(queryParameters?: IAPIQueryParameters[]): string {
      return Constants.appendQueryParameters(
        `${environment.hayhouseBaseUrl}${Constants.apiEndPoints.featuredCuratedCollection}`,
        queryParameters
      );
    },
    episode(queryParameters?: IAPIQueryParameters[], params: string = ''): string {
      return Constants.appendQueryParameters(`${environment.hayhouseBaseUrl}${Constants.apiEndPoints.episode}/${params}`, queryParameters);
    },
    track(queryParameters?: IAPIQueryParameters[], params: string = ''): string {
      return Constants.appendQueryParameters(`${environment.hayhouseBaseUrl}${Constants.apiEndPoints.track}/${params}`, queryParameters);
    },
    klevu(queryParameters?: IAPIQueryParameters[]): string {
      return Constants.appendQueryParameters(`${environment.klevuBaseUrl}`, queryParameters);
    },
    clearCache(queryParameters?: IAPIQueryParameters[]): string {
      return Constants.appendQueryParameters(`${environment.hayhouseBaseUrl}${Constants.apiEndPoints.clearCache}`, queryParameters);
    }
  };

  static appendQueryParameters(url: string, queryParameters: IAPIQueryParameters[] = []): string {
    if (!queryParameters || !queryParameters.length) {
      return url;
    }
    const queryArray = [];
    queryParameters.forEach(parameter => {
      if (Array.isArray(parameter.value)) {
        let queryParam = `${parameter.key}=[`;
        parameter.value.forEach((value, index) => {
          queryParam += `"${value}"`;
          if (index !== parameter.value.length - 1) {
            queryParam += `,`;
          }
        });
        queryParam += `]`;
        queryArray.push(queryParam);
      } else {
        queryArray.push(`${parameter.key}=${parameter.value.toString()}`);
      }
    });
    if (queryArray.length) {
      url += `?${queryArray.join('&')}`;
    }

    return url;
  }

  static ContentSingleDeleteDialogData(isPodcast: boolean, isCuratedCollection: boolean): IDialogData {
    return {
      titleText: isPodcast
        ? 'Are you sure you want to delete this podcast all the episodes associated with the podcast will also be deleted?'
        : isCuratedCollection
          ? 'Are you sure you want to delete this Curated Collection and all the tracks associated with it?'
          : 'Are you sure you want to delete content?',
      approvalButtonText: 'Delete',
      disApprovalButtonText: 'Cancel'
    };
  }

}
