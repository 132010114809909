import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import Constants from '@constants';
import { IDialogResult, IFilterDialogData, IPagination, IRouteQueryParameters, ITableDisplayedColumns } from '@sharedInterfaces';
import { AddEditTopicComponent } from '@topic/add-edit-topic/add-edit-topic.component';
import { FilterTopicsComponent } from '@topic/filter-topics/filter-topics.component';
import { ITopic } from '@topic/topics.interface';
import { TopicService } from '@topic/topics.service';
import { ConfirmationComponent } from '@util/confirmation/confirmation.component';
import { UtilService } from '@util/util.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss']
})
export class TopicsComponent implements OnInit {

  displayedColumns: ITableDisplayedColumns[] = [
    { key: 'name', field: 'Topic Name' },
    { key: 'id', field: 'Topic ID' },
    { key: 'index', field: 'Index' },
    { key: 'img_url', field: 'Image URL' },
    { key: 'quiz_title', field: 'Quiz Title' },
    { key: 'quiz_index', field: 'Quiz Index', sort: true },
    { key: 'quiz_img_url', field: 'Quiz Image URL' },
    { key: 'cj_tag', field: 'CJ-Tag' }];
  topics: ITopic[] = [];
  currentPageKey: string;
  currentPageNo: number;
  nextPageKey: string;
  isFilterApplied: Subject<boolean> = new Subject<boolean>();

  constructor(
    public topicService: TopicService,
    public utilService: UtilService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.currentPageNo = Number(queryParams.pageNo || 1);
      this.currentPageKey = queryParams.pageKey || '';
      this.getTopics();
    });
  }

  getTopics(): void {
    this.topicService.getTopics(false, this.currentPageKey).subscribe((topics: {
      results: ITopic[], next_page_key: string
    }) => {
      this.topics = topics.results;
      this.nextPageKey = topics.next_page_key;
    });
  }

  openPage(pageData?: IPagination, forcePageNumber?: number): void {
    const queryParams: IRouteQueryParameters = {
      pageNo: pageData.isPrevious ? this.currentPageNo - 1 : Number(this.currentPageNo) + 1,
      pageKey: pageData.pageKey || ''
    };
    if (forcePageNumber) {
      queryParams.pageNo = forcePageNumber;
    }
    this.router
      .navigate(
        [], {
          relativeTo: this.activatedRoute,
          queryParams,
          queryParamsHandling: 'merge'
        }
      );
  }

  openTopicForm(topic?: ITopic): void {
    const topicDialogRef = this.dialog.open(AddEditTopicComponent, {
      ...(topic && Constants.EditTopicDialogConfig),
      ...(!topic && Constants.AddTopicDialogConfig),
      data: { topic }
    });
    topicDialogRef.afterClosed().subscribe((data: IDialogResult) => {
      if (data) {
        if (this.currentPageNo === 1 || !data.isCreation) {
          this.getTopics();
        } else {
          this.openPage({}, 1);
        }
      }
    });
  }

  openDeleteTopicDialog(topicInfo: ITopic): void {
    const config = {
      ...Constants.confirmationDialogConfig,
      data: Constants.TopicSingleDeleteDialogData
    };
    const deleteDialog = this.dialog.open(ConfirmationComponent, config);
    deleteDialog.afterClosed().subscribe(approval => {
      if (approval) {
        this.topicService.deleteTopic(topicInfo.id).subscribe(() => {
          this.refreshPage();
        });
      }
    });
  }

  openFilterDialog(): void {
    const data: IFilterDialogData = {isFilterApplied: this.isFilterApplied};
    const filterDialog = this.dialog.open(FilterTopicsComponent, {
      data
    });
    filterDialog.afterClosed().subscribe((isRefresh = false) => {
      if (isRefresh) {
        if (localStorage.getItem(Constants.localStorageKeys.topicFilters) !== null) {
          this.isFilterApplied.next(true);
        } else {
          this.isFilterApplied.next(false);
        }
        this.refreshPage();
      }
    });
  }

  refreshPage(): void {
    if (this.currentPageNo === 1) {
      this.getTopics();
    } else {
      this.openPage({}, 1);
    }
  }

}
