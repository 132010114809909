import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AddEditAuthorComponent } from '@author/add-edit-author/add-edit-author.component';
import { IAuthor } from '@author/authors.interface';
import { AuthorService } from '@author/authors.service';
import Constants from '@constants';
import { TDialogType } from '@sharedTypes';
import { ConfirmationComponent } from '@util/confirmation/confirmation.component';
import { UtilService } from '@util/util.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-view-author',
  templateUrl: './view-author.component.html',
  styleUrls: ['./view-author.component.scss']
})
export class ViewAuthorComponent implements OnInit {

  authorInfo: IAuthor;
  authorId: string;
  returnQueryParameters: {};
  authorInfoFetch: Promise<boolean>;
  noDescription = false;
  description: string;
  showDescriptionButton = false;
  descriptionButtonText = '';
  fullDescription;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authorService: AuthorService,
    private utilService: UtilService,
    private dialog: MatDialog,
  ) {
    this.authorInfo = this.router.getCurrentNavigation().extras.state as IAuthor;
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.authorId = queryParams.id;
      this.returnQueryParameters = JSON.parse(queryParams.returnQueryParameters || JSON.stringify({}));
      if (!this.authorInfo) {
        if (!this.authorId) {
          this.router.navigate(['/authors'], {queryParams: {...this.returnQueryParameters}});
        } else {
          this.getAuthorById()
            .subscribe(result => this.authorInfoFetch = Promise.resolve(result));
        }
      } else {
        this.formatData();
        this.authorInfoFetch = Promise.resolve(true);
      }
    });
  }

  getAuthorById(): Observable<any> {
    return new Observable<any>(observer => {
      this.authorService.getAuthorById(this.authorId).subscribe(
        authorInfo => {
        this.authorInfo = authorInfo;
        this.formatData();

        return observer.next(true);
      },
        error => {
        // if there no author then redirect view author screen to previous list screen
        if (error) {
          this.router.navigate(['/authors'], {queryParams: {...this.returnQueryParameters}});
        }
      });
    });
  }

  formatData(): void {
    // URL
    this.authorInfo.img_url_view = this.authorInfo.img_url;
    this.authorInfo.img_url = this.utilService.getLatestImage(this.authorInfo.img_url);

    // URL VALIDATION
    this.authorInfo.img_url_valid = this.utilService.isValidURL(this.authorInfo.img_url);

    // DESCRIPTION
    this.noDescription = !this.authorInfo.info;
    if (this.authorInfo.info) {
      this.authorInfo.info = this.authorInfo.info.replace(/[\r\n]/g, '<br>');
    }
    this.description = (this.authorInfo.info || '*** No Description ***')
      .substr(0, Constants.lessDescriptionCharacterLimit);
    if (this.description !== this.authorInfo.info && this.authorInfo.info) {
      this.description += '<b>...</b>';
      this.showDescriptionButton = true;
      this.descriptionButtonText = Constants.descriptionMoreButtonText;
      this.fullDescription = false;
    } else {
      this.showDescriptionButton = false;
    }
  }

  toggleDescription(): void {
    if (this.fullDescription) {
      this.description = this.authorInfo.info.substr(0, Constants.lessDescriptionCharacterLimit);
      this.description += '<b>...</b>';
      this.descriptionButtonText = Constants.descriptionMoreButtonText;
      this.fullDescription = false;
    } else {
      this.description = this.authorInfo.info;
      this.descriptionButtonText = Constants.descriptionLessButtonText;
      this.fullDescription = true;
    }
  }

  openDialog(type: TDialogType): void {
    if (type === 'edit') {
      const authorDialogRef = this.dialog.open(AddEditAuthorComponent, {
        ...Constants.AddEditAuthorDialogConfig,
        data: {
          author: JSON.parse(JSON.stringify(this.authorInfo))
        }
      });
      authorDialogRef.afterClosed().subscribe(isRefresh => {
        if (isRefresh) {
          this.getAuthorById().subscribe(() => {
          });
        }
      });
    } else {
      const config = Constants.confirmationDialogConfig;
      const deleteDialogRef = this.dialog.open(ConfirmationComponent, {
        ...config,
        data: Constants.AuthorSingleDeleteDialogData
      });
      deleteDialogRef.afterClosed().subscribe(approval => {
        if (approval) {
          this.authorService.deleteAuthor(this.authorInfo.id).subscribe(() => this.router.navigate([`/authors`]));
        }
      });
    }
  }

}
