import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatDividerModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';

const modules = [
  CommonModule,
  MatButtonModule,
  MatToolbarModule,
  MatIconModule,
  MatDialogModule,
  FormsModule,
  MatFormFieldModule,
  MatInputModule,
  MatCardModule,
  MatGridListModule,
  MatSelectModule,
  MatRadioModule,
  MatSidenavModule,
  MatAutocompleteModule,
  MatTooltipModule,
  MatCheckboxModule,
  MatSnackBarModule,
  MatDividerModule,
  MatListModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatMenuModule,
  MatChipsModule,
  MatPaginatorModule,
  MatSortModule,
  MatBottomSheetModule
];

@NgModule({
  imports: [...modules],
  exports: [...modules]
})

export class MaterialModule { }
